import styled, { css } from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";
import {
  sectionHeaderStyle,
  subTitleStyle,
} from "../../GlobalStyle/GlobalStyle.style";

export const Container = styled.div<{
  position: string;
}>`
  cursor: pointer;
  position: ${(props) => props.position};
  ${(props) =>
    props.position === "fixed" &&
    css`
      left: var(--space-l);
      bottom: var(--space-xl);
    `}
  display: flex;
  align-items: flex-start;
  background-color: var(--white);
  border-radius: var(--border-radius-default);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
  width: 430px;
  border: 1px solid var(--grey400);
  transition: background-color 250ms ease;
  overflow: hidden;

  :hover {
    background-color: var(--grey100);
  }

  @media ${maxLayout.md} {
    min-width: unset;
    max-width: 90vw;
  }
`;

export const BorderLeft = styled.div<{
  borderColor: "success" | "warning" | "danger";
}>`
  display: flex;
  align-items: flex-start;
  background-color: var(--white);
  width: 430px;
  padding: var(--space-base);
  ${(props) => {
    switch (props.borderColor) {
      case "success":
        return css`
          border-left: 6px solid var(--green);
        `;
      case "warning":
        return css`
          border-left: 6px solid var(--yellow);
        `;
      case "danger":
        return css`
          border-left: 6px solid var(--red);
        `;
      default:
        return css`
          border-left: 6px solid var(--green);
        `;
    }
  }}
`;

export const IconWrapper = styled.div<{
  iconColor: "success" | "warning" | "danger";
}>`
  color: var(--green);
  margin-right: var(--space-base);
  margin-top: -2px;
  font-size: 24px;
  height: 24px;

  ${(props) => {
    switch (props.iconColor) {
      case "success":
        return css`
          color: var(--green);
        `;
      case "warning":
        return css`
          color: var(--yellow);
        `;
      case "danger":
        return css`
          color: var(--red);
        `;
      default:
        return css`
          color: var(--green);
        `;
    }
  }}

  @media ${maxLayout.md} {
    font-size: 16px;
    height: 16px;
    margin-right: var(--space-s);
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  ${sectionHeaderStyle}
  color: var(--grey900);
  @media ${maxLayout.md} {
    font-size: 14px;
  }
`;


export const Desciption = styled.div`
  ${subTitleStyle}
  color: var(--grey700);
  margin-top: var(--space-xs);
  margin-right: 5%;
  overflow-wrap: anywhere;

  * {
    overflow-wrap: anywhere;
  }
  @media ${maxLayout.md} {
    display: none;
  }
`;
