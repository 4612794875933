import { Container, ToastWrapper } from "./StackToast.style";
import { Toast } from "../../atoms/toast/Toast";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { removeToast } from "../../../store/reducers/toasts";

export const StackToast = () => {
  const dispatch = useAppDispatch();
  const { toasts } = useAppSelector((state) => state.toasts);

  return (
    <Container>
      {Boolean(toasts.length) &&
        toasts.map((toast) => (
          <ToastWrapper key={toast.id}>
            <Toast
              title={toast.title}
              description={toast.description}
              iconColor={toast.status === "error" ? "danger" : "success"}
              icon={toast.status === "error" ? "times-circle" : "check-circle"}
              onClick={() => toast.id && dispatch(removeToast(toast.id))}
              position="relative"
              borderColor={toast.status === "error" ? "danger" : "success"}
            />
          </ToastWrapper>
        ))}
    </Container>
  );
};
