import styled from "styled-components";
import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--lightBlue);
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: var(--space-m);

  @media ${maxLayout.md} {
    flex-direction: column;
  }
`;