import { BucketHistoryHeader } from "../../components/organisms/bucketHistory/bucketHistoryHeader/BucketHistoryHeader";
import { SectionBucketUsageHistory } from "../../components/organisms/bucketHistory/sectionBucketUsageHistory/SectionBucketUsageHistory";
import { RouteComponentProps } from "react-router";
import { useEffect } from "react";
import {
  setBucketName,
  setCurrentFilePath,
  setRequestUrl,
} from "../../store/reducers/files";
import { useAppDispatch } from "../../store/hooks";
import { useLocation } from "react-router-dom";
import { Container } from "../../components/GlobalStyle/GlobalStyle.style";
import { PageContainer } from "./BucketHistory.style";
import { PopupUploadProgress } from "../../components/organisms/fileList/popupUploadProgress/PopupUploadProgress";

interface MatchParams {
  teamname?: string;
  spaceKey: string;
  bucketName: string;
}

interface BucketSettingsProps extends RouteComponentProps<MatchParams> {}

export const BucketHistory = (props: BucketSettingsProps) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const bucketName = props.match.params.bucketName;
  const spaceKey = props.match.params.spaceKey;
  const currentPath = new URLSearchParams(search).get("path") || "";

  useEffect(() => {
    document.title = "Usage History - ByteArk Storage";

    dispatch(setBucketName(bucketName));
    dispatch(setRequestUrl(currentPath));
    dispatch(setCurrentFilePath(""));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucketName, currentPath]);

  return (
    <PageContainer>
      <BucketHistoryHeader bucketName={bucketName} spaceKey={spaceKey} />
      <Container>
        <SectionBucketUsageHistory spaceKey={spaceKey} />
        <PopupUploadProgress />
      </Container>
    </PageContainer>
  );
};
