import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  DropdownWrapper,
  DropdownOverlay,
  DropdownOptionsContainer,
  DropdownButton,
  TextWrapper,
  YearContainer,
  MonthContainer,
  Text,
} from "./SelectMonthYearDropdown.style";
import { getToday } from "../../../../utils/dateTime";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Select } from "../../..";
import { ButtonStyle } from "../../../atoms/button/Button";
import {
  OptionProps,
  SelectRefObject,
} from "../../../atoms/select/Select";

interface SelectMonthYearDropdownProps {
  /**
   * Set dropdown to disabled style
   */
  disabled?: boolean;
  /**
   * Set dropdown to have full width
   */
  fullWidth?: boolean;
  /**
   * Set position of popover
   * @default bottom
   */
  popoverPosition?: "top" | "bottom";
  buttonStyle: ButtonStyle;
  /**
   * Default selected year value
   * @default first option's value
   */
  defaultYearValue: any;
  /**
   * Default selected month value
   * @default first option's value
   */
  defaultMonthValue: any;
  /**
   * space created date
   */
  createdDate: string;
  /**
   * select montch function
   */
  onSelectMonth: (selectedValue: any) => void;
  /**
   * select year function
   */
  onSelectYear: (selectedValue: any) => void;
  /**
   * change month value
   */
  onChangeMonth: (newMonthValue: any) => void;
}

export const SelectMonthYearDropdown = (
  props: SelectMonthYearDropdownProps
) => {
  const {
    popoverPosition,
    onSelectMonth,
    onSelectYear,
    defaultMonthValue,
    defaultYearValue,
    createdDate,
    onChangeMonth,
    ...SelectMonthYearDropdownProps
  } = props;

  const monthOption: Array<OptionProps> = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const [visible, setVisible] = useState(false);
  const monthRef = useRef<SelectRefObject>(null);
  const yearRef = useRef<SelectRefObject>(null);

  const [dropdownYearOption, setDropdownYearOption] = useState<
    Array<OptionProps>
  >([
    {
      label: `${new Date(createdDate).getFullYear()}`,
      value: `${new Date(createdDate).getFullYear()}`,
    },
  ]);
  const [dropdownMonthOption, setDropdownMonthOption] = useState<
    Array<OptionProps>
  >(monthOption);

  useEffect(() => {
    const getListOfMonth = () => {
      const dateObject = new Date(createdDate);
      const createdMonth = dateObject.getMonth();
      const createdYear = dateObject.getFullYear();
      const todayYear = getToday()[2];
      const todayMonth = getToday()[1];
      if (
        todayYear === +defaultYearValue &&
        createdYear === +defaultYearValue
      ) {
        const filtedMonth = monthOption.slice(createdMonth, +todayMonth);
        onChangeMonth(`${filtedMonth[filtedMonth.length - 1].value}`);
        return filtedMonth;
      } else if (todayYear === +defaultYearValue) {
        const filtedMonth = monthOption.slice(0, +todayMonth);
        onChangeMonth(`${filtedMonth[filtedMonth.length - 1].value}`);
        return filtedMonth;
      } else if (createdYear === +defaultYearValue) {
        const filtedMonth = monthOption.slice(createdMonth);
        onChangeMonth(`${filtedMonth[0].value}`);
        return filtedMonth;
      } else {
        onChangeMonth(defaultMonthValue);
        return monthOption;
      }
    };

    const getListOfYear = () => {
      const dateObject = new Date(createdDate);
      const createdYear = dateObject.getFullYear();
      const todayYear = getToday()[2];
      let listYear = [];
      if (typeof todayYear === "number") {
        const yearDiff = todayYear - createdYear;
        for (let i: number = 0; i < yearDiff + 1; i++) {
          listYear.push({
            label: `${todayYear - i}`,
            value: `${todayYear - i}`,
          });
        }
        if (listYear.length) {
          return listYear;
        }
      }
      return dropdownYearOption;
    };

    setDropdownMonthOption(getListOfMonth());
    setDropdownYearOption(getListOfYear());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdDate, defaultYearValue]);

  return (
    <DropdownWrapper>
      <DropdownButton
        onClick={() => setVisible(true)}
        isActive={visible}
        {...SelectMonthYearDropdownProps}
      >
        <TextWrapper>
          {_.find(monthOption, ["value", defaultMonthValue])?.label}{" "}
          {defaultYearValue}
        </TextWrapper>
        <FontAwesomeIcon size={"sm"} icon={["far", "calendar-alt"]} />
      </DropdownButton>
      <DropdownOverlay
        isActive={visible}
        onClick={() => {
          setVisible(false);
          if (monthRef.current) {
            monthRef.current.onVisible();
          }
          if (yearRef.current) {
            yearRef.current.onVisible();
          }
        }}
      />
      <DropdownOptionsContainer
        isActive={visible}
        popoverPosition={popoverPosition}
      >
        <MonthContainer>
          <Text>Month:</Text>
          <Select
            options={dropdownMonthOption}
            onSelect={onSelectMonth}
            defaultValue={defaultMonthValue}
            ref={monthRef}
            isScrolling
          />
        </MonthContainer>
        <YearContainer>
          <Text>Year:</Text>
          <Select
            options={dropdownYearOption}
            onSelect={onSelectYear}
            defaultValue={defaultYearValue}
            ref={yearRef}
            isScrolling
          />
        </YearContainer>
      </DropdownOptionsContainer>
    </DropdownWrapper>
  );
};
