import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { checkSpaceActivation, activateSpace } from "../../services/apis";

type Sizes = {
  height: number;
  width: number;
  url: string;
};

type ImageResponse = {
  sizes: {
    default: Sizes;
    large: Sizes;
    medium: Sizes;
    small: Sizes;
  };
};

type Team = {
  id: number;
  name: string;
  namespace: string;
  owner_id: number;
};

export type UserResponse = {
  displayImage: ImageResponse;
  email: string;
  id: number;
  lang: "th" | "en";
  name: string;
  role: string;
  roleNames: string[];
  teamId: number;
  team: Team;
};

interface UserReducerState {
  user?: UserResponse;
  isDefaultSpaceActivated: boolean;
  error: string | null | undefined;
}

const initialState: UserReducerState = {
  user: undefined,
  isDefaultSpaceActivated: false,
  error: null,
};

interface ActivationResponse {
  isDefaultSpaceActivated: boolean;
}

const fetchSpaceActivated = createAsyncThunk(
  "user/fetchSpaceActivated",
  async () => {
    try {
      const response = await checkSpaceActivation();
      return response.data as ActivationResponse;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return error.response.data;
    }
  }
);

const setActivateSpace = createAsyncThunk("user/setActivateSpace", async () => {
  try {
    const response = await activateSpace();
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return error.response.data;
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserResponse>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = undefined;
    },
    resetErrorState: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSpaceActivated.fulfilled,
      (state, action: PayloadAction<ActivationResponse | any>) => {
        if (!action.payload.errorCode) {
          state.isDefaultSpaceActivated =
            action.payload.isDefaultSpaceActivated;
        } else {
          state.error = action.payload.errorCode;
        }
      }
    );
    builder.addCase(
      setActivateSpace.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (!action.payload.errorCode) {
          state.isDefaultSpaceActivated = true;
        } else {
          state.error = action.payload.errorCode;
        }
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, resetErrorState } = userSlice.actions;
export { fetchSpaceActivated, setActivateSpace };

export default userSlice.reducer;
