/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useHistory } from "react-router";
import { fetchSpaceActivated } from "../../store/reducers/user";

import { ActivateHeader } from "../../components/organisms/activateHeader/ActivateHeader";
import { PageContainer } from "../bucketList/BucketList.style";

export const CheckRedirect = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const history = useHistory();

  useEffect(() => {
    const loadData = async () => {
      if (user) {
        const response = await dispatch(fetchSpaceActivated());

        if (fetchSpaceActivated.fulfilled.match(response)) {
          if (!response.payload.errorCode) {
            const { isDefaultSpaceActivated } = response.payload;

            if (!isDefaultSpaceActivated) {
              history.push(`/${user.team.namespace}/activate`);
            } else {
              history.push(`/${user.team.namespace}/spaces`);
            }
          } else {
            if (process.env && process.env.NODE_ENV === "development") {
              // For Testing on localhost
              history.push(`/spaces`);
            } else {
              history.push(`/${user.team.namespace}/spaces`);
            }
          }
        }
      } else {
        // For Testing on localhost
        if (process.env && process.env.NODE_ENV === "development") {
          history.push(`/spaces`);
        }
      }
    };

    loadData();
  }, [user]);

  return (
    <PageContainer>
      <ActivateHeader />
    </PageContainer>
  );
};
