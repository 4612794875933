import styled, { css, createGlobalStyle } from "styled-components";
import Variables from "./variables/Variables.style";
import { maxLayout } from "../../utils/breakpoint/breakpoint";

function setup() {
  return css`
    ${Variables}

    * {
      margin: 0;
      font-family: "Roboto", "Noto Sans Thai", sans-serif;
      line-height: 1.4;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    a {
      text-decoration: none;
    }

    body {
      background-color: var(--lightBlue);
    }
  `;
}

export const Container = styled.div`
  padding-left: var(--space-base);
  padding-right: var(--space-base);

  @media screen and (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    width: 1100px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--navy);
  width: 100%;
  padding-left: var(--space-base);
  padding-right: var(--space-base);

  @media screen and (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const pageHeaderStyle = css`
  font-size: 32px;
  font-weight: 700;
  color: var(--white);

  @media ${maxLayout.md} {
    font-size: 24px;
  }
`;

export const sectionHeaderStyle = css`
  font-size: 18px;
  font-weight: 700;
`;

export const titleStyle = css`
  font-size: 16px;
  font-weight: 700;
`;

export const subTitleStyle = css`
  font-size: 14px;
  color: var(--grey700);
`;

export const bodyTextStyle = css`
  font-size: 16px;
`;

export const smallTextStyle = css`
  font-size: 12px;
  color: var(--grey700);
`;

const BaseStyle = createGlobalStyle`
	${setup()}
`;

export default BaseStyle;
