import {
  ActionButtonWrapper,
  Description,
  HeaderContainer,
  MobileButtonWrapper,
  MobileTitle,
  OriginContainer,
  SectionBody,
  SectionCorsConfigContainer,
  SectionHeader,
  Title,
  TitleContainer,
  UnderlinedText,
  BaseTableWrapper,
} from "./SectionCorsConfig.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../..";
import { BaseTable } from "../../../molecules/baseTable/BaseTable";
import { ColumnProps } from "../../../atoms/tableHeader/TableHeader";
import { useEffect, useState } from "react";
import { loadBucketCorsPolicy, rulesetPayload } from "../../../../services/apis";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setIsCorsModalShowing } from "../../../../store/reducers/settings";
import _ from "lodash";
import { Tooltip } from "../../../atoms/tooltip/Tooltip";
import { ModalCorsConfigNew } from "../modalCorsConfigNew/ModalCorsConfigNew";
import { AxiosError } from "axios";

interface SectionCorsConfigProps {
  spaceKey: string;
}

export const SectionCorsConfig = (props: SectionCorsConfigProps) => {
  const { spaceKey } = props;
  const { bucketName } = useAppSelector((state) => state.files);

  const [data, setData] = useState<Array<rulesetPayload>>([]);
  const [tableRows, setTableRows] = useState<Array<any>>([]);

  const dispatch = useAppDispatch();

  const columns: Array<ColumnProps> = [
    {
      title: "Origins",
      size: "md",
    },
    {
      title: "Allowed Methods",
      size: "md",
    },
    {
      title: "Allowed Headers",
      size: "md",
    },
    {
      title: "Access Control Max Age",
      size: "md",
    },
  ];

  const mapTableRows = (filtered?: Array<rulesetPayload>) => {
    const baseArray = filtered ? filtered : data;
    const rows = baseArray.map((item, index) => {
      const { origins, methods, headers } = item.allow;

      let headersText = "-";

      if (headers) {
        headersText = headers.join(", ");
      }

      return {
        columns: [
          <OriginContainer>
            <MobileTitle>{columns[0].title}</MobileTitle>
            {origins.map((origin, index) => {
              if (origin.length > 30) {
                return (
                  <Tooltip text={origin} key={`origin${index}`}>
                    <p>{origin}</p>
                  </Tooltip>
                );
              }
              return <p key={`origin${index}`}>{origin}</p>;
            })}
          </OriginContainer>,
          <>
            <MobileTitle>{columns[1].title}</MobileTitle>
            {methods.map((method, index) => (
              <span key={`method${index}`}>
                {method}
                {index + 1 < methods.length && ", "}
              </span>
            ))}
          </>,
          <HeaderContainer>
            <MobileTitle>{columns[2].title}</MobileTitle>
            {headers.length > 3 ? (
              <Tooltip position={"bottom"} text={headersText}>
                <UnderlinedText>
                  {headers.length} allowed headers
                </UnderlinedText>
              </Tooltip>
            ) : (
              <span>{headersText}</span>
            )}
          </HeaderContainer>,
          <>
            <MobileTitle>{columns[3].title}</MobileTitle>
            {item.maxAgeInSeconds} seconds
          </>,
        ],
      };
    });
    setTableRows(rows);
  };

  const loadData = async () => {
    try {
      const response = await loadBucketCorsPolicy(spaceKey, bucketName);
      const dataCors: rulesetPayload[] = _.get(response, "data.ruleset");
      setData(dataCors);
      mapTableRows(dataCors);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.response?.data.errorCode);
      setData([]);
      mapTableRows([]);
    }
  };

  useEffect(() => {
    if (bucketName && bucketName.length) {
      loadData();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [bucketName]);

  return (
    <SectionCorsConfigContainer>
      <ModalCorsConfigNew onSuccess={() => loadData()} />
      <SectionHeader>
        <TitleContainer>
          <Title>CORS Configurations</Title>
          <ActionButtonWrapper>
            <Button
              onClick={() => dispatch(setIsCorsModalShowing(true))}
              isIconButton={true}
              buttonStyle={"secondary-a"}
            >
              <FontAwesomeIcon size={"sm"} icon={["fas", "edit"]} />
              Manage Rules
            </Button>
          </ActionButtonWrapper>
        </TitleContainer>
        <Description>
          Cross-origin resource sharing (CORS) allows client web applications
          that are loaded in one do main to interact with resources in another
          domain.
        </Description>
      </SectionHeader>
      <SectionBody>
        <BaseTableWrapper>
          <BaseTable columns={columns} rows={tableRows} />
        </BaseTableWrapper>
      </SectionBody>
      <MobileButtonWrapper>
        <Button
          onClick={() => dispatch(setIsCorsModalShowing(true))}
          isIconButton={true}
          buttonStyle={"secondary-a"}
        >
          <FontAwesomeIcon size={"sm"} icon={["fas", "edit"]} />
          Manage Rules
        </Button>
      </MobileButtonWrapper>
    </SectionCorsConfigContainer>
  );
};
