import {
  IconContainer,
  TitleText,
  TextAndIconContainer,
  TextContainer,
} from "./TextAndIcon.style";
import { Link } from "../../atoms/link/Link";

interface TextWithIconProps {
  children?: JSX.Element | string,
  /**
   * Text's color in HEX
   * @default "#343A40" */
  color?: string;
  /**
   * Text's font size in px
   * @default 14 */
  fontSize?: number;
  /**
   * Text's font weight
   * @default 400 */
  fontWeight?: 400 | 500 | 700;
  /** React Fontawesome element */
  icon: JSX.Element;
  /** If this component can be clicked, enter the destination url here */
  redirectUrl?: string;
  /** Text to be displayed on the right of the icon */
  text: string;
  /**
   * Vertical alignment when text's height is higher than icon's
   * @default "center" */
  verticalAlign?: "top" | "center" | "bottom";
}

export const TextWithIcon = (props: TextWithIconProps) => {
  const { children, color, fontSize = 14, fontWeight = 400, icon, redirectUrl, text, verticalAlign = "center"} = props;

  const getComponent = () => {
    return (
      <TextAndIconContainer
        verticalAlign={verticalAlign}>
        <IconContainer>
          { icon }
        </IconContainer>
        <TextContainer>
          <TitleText
            color={color}
            fontWeight={fontWeight}
            fontSize={fontSize}>
            { text }
          </TitleText>
          { children }
        </TextContainer>
      </TextAndIconContainer>
    );
  };

  if (redirectUrl) {
    return (
      <Link to={redirectUrl}>
        {getComponent()}
      </Link>
    );
  }

  return getComponent();
};
