import { Modal } from "../../atoms/modal/Modal";
import { InputCopy } from "../..";
import { SecretCopy } from "../../molecules/secretCopy/SecretCopy";

import {
  Title,
  Text,
  InputWraper,
  Wrapper,
  ModalCardContainer,
  ContentContainer,
} from "./AccessKeyDetailsModal.style";

interface AccessKeyDetailsModalProps {
  spaceKey: string;
  keyValue: string;
  permission: string;
  accessKeyID: string;
  isOpen: boolean;
  onClose: () => void;
}

export const AccessKeyDetailsModal = (props: AccessKeyDetailsModalProps) => {
  const {
    spaceKey,
    keyValue,
    permission,
    accessKeyID,
    isOpen,
    onClose,
  } = props;

  return (
    <Modal title="Access Key Details" isOpen={isOpen} onClose={onClose}>
      <ModalCardContainer>
        <ContentContainer>
          <Wrapper>
            <Title>Name</Title>
            <Text>{keyValue}</Text>
          </Wrapper>
          <Wrapper>
            <Title>Permission</Title>
            <Text>{permission}</Text>
          </Wrapper>
          <Wrapper>
            <Title>Access Key ID</Title>
            <InputWraper>
              <InputCopy value={accessKeyID} />
            </InputWraper>
          </Wrapper>
          <Title>Secret Access Key</Title>
          <InputWraper>
            <SecretCopy spaceKey={spaceKey} subUserId={keyValue} />
          </InputWraper>
        </ContentContainer>
      </ModalCardContainer>
    </Modal>
  );
};
