import styled, { keyframes } from "styled-components";
import { maxLayout } from "../../utils/breakpoint/breakpoint";
import { ButtonWrapper } from "../../components/atoms/button/Button.style";
import { TableRow } from "../../components/atoms/tableBody/TableBody.style";
import { TitleText } from "../../components/molecules/textWithIcon/TextAndIcon.style";
import { PopoverArrow } from "../../components/atoms/popover/Popover.style";

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--lightBlue);
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: var(--space-m);

  @media ${maxLayout.md} {
    flex-direction: column;
  }
`;

export const TableWrapper = styled.div`
  width: calc(100% - 330px);

  @media ${maxLayout.md} {
    width: 100%;
  }
`;

export const TableContainer = styled.div`
  min-height: calc(100vh - 300px);
  background-color: var(--white);
  border-radius: var(--border-radius-default);

  ${TableRow} {
    animation: ${FadeIn} 120ms ease;
  }

  .column1 {
    width: 60px;
    margin: auto;
  }

  .column2 {
    padding-left: var(--space-s);

    ${TitleText} {
      width: 25vmax;
      max-width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media ${maxLayout.lg} {
        max-width: 20vw;
      }
      @media ${maxLayout.md} {
        width: 40vmax;
        max-width: 45vw;
      }
    }
  }

  .column3 {
    width: 310px;
  }

  .column4 {
    text-align: right;
    width: 158px;
    white-space: nowrap;
  }

  .column5 {
    width: 60px;
    padding: 0;
  }

  @media ${maxLayout.lg} {
    .column2 {
      width: -webkit-fill-available;
    }
    .column3 {
      display: none;
      /* padding-right: 0;
      min-width: 100px; */
    }
    .column4 {
      min-width: 92px;
    }
    .column5 {
      width: 36px;
      min-width: 36px;
    }
  }

  /* @media ${maxLayout.sm} {
    .column3 {
      display: none;
    }
  } */

  @media ${maxLayout.xs} {
    .column4 {
      display: none;
    }
  }
`;

export const SearchBoxWrapper = styled.div`
  width: 462px;

  @media ${maxLayout.md} {
    width: 100%;
    order: 2;
  }
`;

export const ToolsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-m);
  margin-bottom: var(--space-base);

  ${ButtonWrapper} {
    position: relative;
    height: 36px;
    padding: var(--space-s) var(--space-m);
    margin-left: var(--space-base);

    svg {
      margin-right: 6px;
    }

    :first-child {
      svg {
        position: relative;
        top: 1px;
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }

  .popover-container {
    left: -25px;
  }

  @media screen and (max-width: 1260px) {
    ${PopoverArrow} {
      left: 0;
      right: unset;
      transform: translate3d(89px, 0px, 0px);
    }
  }

  @media ${maxLayout.md} {
    width: 100%;
    flex-wrap: wrap;
    order: 1;
    margin-top: var(--space-base);

    ${ButtonWrapper} {
      margin-left: 0;
      padding: var(--space-s) var(--space-s);
      width: 35%;

      :first-child {
        width: 26%;
      }
    }

    .popover-container {
      left: 0;
      right: 0;
    }

    ${PopoverArrow} {
      left: 20px;
      right: 0;
      transform: unset;
    }
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;

  > button {
    width: 145px;
  }

  @media ${maxLayout.md} {
    width: 100%;
    justify-content: space-between;
    margin-bottom: var(--space-base);
  }
`;

export const LoadMoreButtonContainer = styled.div`
  margin-top: var(--space-s);
  width: 100%;

  svg {
    margin-left: var(--space-s);
    margin-right: 0;
  }
`;

export const TableActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  z-index: 2;

  width: 100%;
  height: 100%;
`;

export const ActionWrapper = styled.div`
  position: relative;
`;
