import styled from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SecretCopyContainer = styled.div`
  position: relative;
  flex: 1 0 auto;

  @media ${maxLayout.lg} {
    flex: unset;
  }

  @media ${maxLayout.sm} {
    flex: 1 0 auto;
  } 
`;

export const HiddenButton = styled.button`
  cursor: pointer;
  flex: 0 0 54px;
  margin-left: var(--space-base);
  padding: 0;
  font-size: 14px;
  text-align: left;
  color: var(--primary);
  border: none;
  background-color: transparent;
`;
