import {
  BaseErrorMessageContainer,
  ErrorDescription,
  ErrorTitle,
  ButtonContainer,
  ButtonText,
} from "./BaseErrorMessage.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../atoms/button/Button";

export interface ErrorMessageButton {
  text: string | JSX.Element;
  onClick: () => void;
}

interface BaseErrorMessageProps {
  description?: string;
  icon: "spinner" | "inbox" | "exclamation-triangle" | "search" | "key";
  title: String;
  button?: ErrorMessageButton;
}

export const BaseErrorMessage = (props: BaseErrorMessageProps) => {
  const {
    description,
    button,
    icon,
    title,
  } = props;
  const iconType = icon === "spinner" ? "fad" : "fas";

  return (
    <BaseErrorMessageContainer>
      <FontAwesomeIcon
        color={"#6c757d"}
        size={"3x"}
        pulse={icon === "spinner"}
        icon={[iconType, icon]}
      />
      <ErrorTitle>{title}</ErrorTitle>
      {description && <ErrorDescription> {description} </ErrorDescription>}
      {button && (
        <ButtonContainer>
          <Button fullWidth onClick={button.onClick}>
            <ButtonText>{button.text}</ButtonText>
          </Button>
        </ButtonContainer>
      )}
    </BaseErrorMessageContainer>
  );
};
