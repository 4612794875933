import { BucketAccessesHeader } from "../../components/organisms/bucketAccesses/bucketAccessesHeader/BucketAccessesHeader";
import { RouteComponentProps } from "react-router";
import { useEffect } from "react";
import { setBucketName, setCurrentFilePath, setRequestUrl } from "../../store/reducers/files";
import { useAppDispatch } from "../../store/hooks";
import { useLocation } from "react-router-dom";
import { Container } from "../../components/GlobalStyle/GlobalStyle.style";
import { PageContainer } from "./BucketAccesses.style";

interface MatchParams {
  teamname?: string;
  spaceKey: string;
  bucketName: string;
}

interface BucketSettingsProps extends RouteComponentProps<MatchParams> {}

export const BucketAccesses = (props: BucketSettingsProps) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const bucketName = props.match.params.bucketName;
  const spaceKey = props.match.params.spaceKey;
  const currentPath = new URLSearchParams(search).get("path") || "";

  useEffect(() => {
    document.title = `ByteArk Storage - ${bucketName}`;

    dispatch(setBucketName(bucketName));
    dispatch(setRequestUrl(currentPath));
    dispatch(setCurrentFilePath(""));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucketName, currentPath]);

  return (
    <PageContainer>
      <BucketAccessesHeader bucketName={bucketName} spaceKey={spaceKey} />
      <Container>

      </Container>
    </PageContainer>
  );
};
