import styled from "styled-components";
import { Input } from "../../atoms/textInput/TextInput.style";

export const InputCopyContainer = styled.div`
  position: relative;

  ${Input} {
    padding-right: 76px;
  }
`;

export const CopyButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: var(--grey700);

  height: 100%;
  width: 76px;
  
  svg {
    margin-right: var(--space-xs);
  }
`;
