import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CloseButton,
  CollapseButton,
  HeaderActions,
  HeaderTitle,
  ListGroupContainer,
  PopupHeader,
  PopupUploadProgressContainer,
} from "./PopupUploadProgress.style";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { UploadProgressItem } from "../../../molecules/fileList/uploadProgressItem/UploadProgressItem";
import { setIsUploadPopupShowing } from "../../../../store/reducers/files";
import { resetUploadState } from "../../../../store/reducers/upload";

export const PopupUploadProgress = () => {
  const dispatch = useAppDispatch();
  const [isCollapsing, setIsCollapsing] = useState<boolean>(false);
  const { isUploadPopupShowing } = useAppSelector((state) => state.files);
  const {
    cancelCount,
    errorCount,
    successCount,
    uploadStatus,
  } = useAppSelector((state) => state.upload);

  const onTogglePopup = () => {
    setIsCollapsing(!isCollapsing);
  };

  const onClosePopup = () => {
    dispatch(setIsUploadPopupShowing(false));
    dispatch(resetUploadState());
  };

  const getTitle = () => {
    const doneCount = successCount + cancelCount;
    if (errorCount > 0) {
      return "There was an error while uploading";
    }

    if (doneCount === uploadStatus.length) {
      return `${successCount} upload${successCount > 1 ? 's': ''} completed`;
    }

    return `Uploading ${successCount} / ${ uploadStatus.length } file${ uploadStatus.length > 1 ? 's' : '' }...`;
  };

  if (!isUploadPopupShowing) {
    return (<></>);
  }

  return (
    <PopupUploadProgressContainer>
      <PopupHeader>
        <HeaderTitle
          onClick={onTogglePopup}>
          { getTitle() }
        </HeaderTitle>
        <HeaderActions>
          <CollapseButton
            onClick={onTogglePopup}>
            {
              Boolean(isCollapsing) ? (
                <FontAwesomeIcon icon={["fas", "chevron-up"]} />
              ) : (
                <FontAwesomeIcon icon={["fas", "chevron-down"]} />
              )
            }
          </CollapseButton>
          <CloseButton
            onClick={onClosePopup}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </CloseButton>
        </HeaderActions>
      </PopupHeader>
      <ListGroupContainer
        isCollapsing={isCollapsing}>
        {
          uploadStatus.map((item, key) => (
            <UploadProgressItem
              key={`upload-progress-item-${key}`}
              uploadStatus={item} />
          ))
        }
      </ListGroupContainer>
    </PopupUploadProgressContainer>
  );
};
