// @ts-ignore
import byteSize from "byte-size/index.js";

export const formatNumber = (number: number) => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatBytes = (bytes: number) => {
  const { value, unit } = byteSize(bytes);
  if (isNaN(value)) {
    return `0 B`;
  }
  return `${value} ${unit.toUpperCase()}`;
};

export const formatBytes3Precision = (bytes: number) => {
  const { value, unit } = byteSize(bytes, {precision: 3});
  if (isNaN(value) || value === "0") {
    return `0.000 B`;
  }
  return `${value} ${unit.toUpperCase()}`;
};