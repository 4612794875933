import { ChangeEvent, KeyboardEvent } from "react";
import {
  Container,
  Input,
  Unit,
  WarningMessage,
  Label,
} from "./NumberInput.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NumberInputProps {
  /**
   * input value.
   */
  value: number;
  /**
   * placeholder of this text input.
   */
  placeHolder: string;
  /**
   * hint text or error message.
   */
  label?: string;
  /**
   * unit of value input.
   */
  unit?: string;
  /**
   * function that triggle when user keydown.
   */
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  /**
   * function that triggle while user typing.
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const NumberInput = (props: NumberInputProps) => {
  const {
    value,
    placeHolder,
    label,
    unit,
    onChange,
    onKeyDown,
  } = props;

  const validateMaxAge = (maxAge: number): boolean => {
    if (isNaN(maxAge)) {
      return true;
    }
    const regExp = new RegExp(/^[0-9]+$/);
    return regExp.test(maxAge.toString());
  };

  return (
    <>
      <Container>
        <Input
          value={value ? value : ""}
          type="number"
          min="0"
          placeholder={placeHolder}
          error={!validateMaxAge(value)}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onWheel={(e) => e.currentTarget.blur()}
        />
        <Unit>{unit}</Unit>
      </Container>
      <WarningMessage>
        {!validateMaxAge(value) && (
          <Label error={!validateMaxAge(value)}>
            <span>
              <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />{" "}
            </span>
            {label}
          </Label>
        )}
      </WarningMessage>
    </>
  );
};
