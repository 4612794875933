import styled from "styled-components";
import { smallTextStyle, subTitleStyle } from "../../GlobalStyle/GlobalStyle.style";
import { Link } from "react-router-dom";

export const CardContainer = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--space-base);
  width: 100%;
  height: 174px;

  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: var(--border-radius-large);
  transition: box-shadow 200ms ease;
  
  :hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  }
`;

export const CardLabel = styled.p`
  ${smallTextStyle};
  margin-bottom: var(--space-xs);
`;

export const CardTitle = styled.h4`
  font-size: 18px;
  font-weight: 500;
  color: var(--navy);
  word-break: break-all;
`;

export const CardSubtitle = styled.p`
  ${subTitleStyle};
  margin-top: var(--space-xs);
`;

export const CardFooter = styled.div`
  position: absolute;
  bottom: var(--space-base);
`;

export const CardMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  
  .popover-container {
    right: -79px;
    top: 51px;
  }
  
  @media (max-width: 1260px) {
    .popover-container {
      right: -10px;
    }
  }
`;

export const CardMenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  color: var(--grey700);
  padding: var(--space-base);

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  z-index: 2;
`;
