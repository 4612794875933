import styled from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const ContainerBar = styled.div<{
  backgroundColor: string;
  borderColor: string;
  isShowing: Boolean;
}>`
  display: ${(props) => (props.isShowing ? "flex" : "none")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-xs);
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: var(--border-radius-large);
  width: 100%;
  @media ${maxLayout.md} {
    flex-direction: column;
    padding: var(--space-s);
  }
`;

export const TitleWarpper = styled.div`
  padding-left: var(--space-base);
  @media ${maxLayout.md} {
    padding-left: unset;
    margin-bottom: var(--space-xs);
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const DiscardButton = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--red);
  padding-right: var(--space-m);
  :hover {
    text-decoration: underline;
  }
`;
export const SaveButton = styled.div`
  width: 120px;
`;
