import { MouseEvent } from "react";
import {
  ChoiceContainer,
  ChoiceDescription,
  ChoiceTitle,
  Radio,
  RadioChecked,
  TextContainer,
} from "./Choice.style";

interface ChoiceProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  isSelected: boolean;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  withMarginBottom?: boolean;
  testId?: string;
}

export const Choice = (props: ChoiceProps) => {
  const {
    title,
    description,
    isSelected,
    onClick,
    withMarginBottom = true,
    testId = "choice",
  } = props;
  return (
    <ChoiceContainer
      withMarginBottom={withMarginBottom}
      onClick={onClick}
      data-cy={testId}
    >
      {isSelected ? <RadioChecked /> : <Radio />}
      <TextContainer>
        <ChoiceTitle>{title}</ChoiceTitle>
        <ChoiceDescription>{description}</ChoiceDescription>
      </TextContainer>
    </ChoiceContainer>
  );
};
