import { ReactChild } from "react";
import { Button, Modal } from "../../../components";
import { useAppSelector } from "../../../store/hooks";
import { Text, ButtonContainer } from "./DeleteModal.style";

interface DeleteModalProps {
  /**
   * Title of modal.
   */
  title: string;
  /**
   * Alert message.
   */
  description?: string;
  /**
   * Anything that add between description and button.
   */
  children?: ReactChild;
  /**
   * Display Modal if true
   * @default false
   */
  isOpen: boolean;
  /**
   * Is button was disabled.
   */
  disable?: boolean;
  /**
   * function that triggers on click modal background, cancel or close icon.
   */
  onClose?: () => void;
  /**
   * function that triggers on confirm delete.
   */
  onDelete: () => void;
  /**
   * Id for testing selector.
   */
  testId?: string;
}

export const DeleteModal = (props: DeleteModalProps) => {
  const {
    title,
    description,
    children = <></>,
    isOpen,
    disable,
    onClose = () => {},
    onDelete,
    testId = "delete-button",
  } = props;
  const { loadingStatus } = useAppSelector((state) => state.loading);

  return (
    <Modal title={title} isOpen={isOpen} onClose={onClose}>
      {Boolean(description) ? <Text>{description}</Text> : ""}
      {children}
      <ButtonContainer>
        <Button
          onClick={onClose}
          buttonStyle={"secondary-a"}
          disable={loadingStatus === "loading"}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={onDelete}
          buttonStyle={"danger"}
          disable={disable || loadingStatus === "loading"}
          data-cy={testId}
        >
          Delete
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
