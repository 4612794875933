import {
  axiosStorageAPI,
  axiosAuthAPI,
  axiosUploadAPI,
  axiosAccessKeyAPI,
} from ".";

const baseURL = process.env.REACT_APP_ORIGIN || window.location.origin;
const token = process.env.REACT_APP_AUTH_TOKEN;
const team = process.env.REACT_APP_AUTH_TEAM;

const configAxiosForWebConsole = (teamName: string) => {
  // setup axios for upload api
  axiosUploadAPI.defaults.withCredentials = true;
  axiosUploadAPI.defaults.headers.common = { "X-ByteArk-Team": teamName };

  axiosUploadAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        window.location.href = `${baseURL}/accounts/byteark`;
      }
      return Promise.reject(error);
    }
  );

  // setup axios for app api
  axiosStorageAPI.defaults.withCredentials = true;
  axiosStorageAPI.defaults.headers.common = { "X-ByteArk-Team": teamName };

  axiosStorageAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        window.location.href = `${baseURL}/accounts/byteark`;
      }
      return Promise.reject(error);
    }
  );

  // setup axios for auth service api
  axiosAuthAPI.defaults.withCredentials = true;
  axiosAuthAPI.defaults.headers.common = { "X-ByteArk-Team": teamName };

  axiosAuthAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        window.location.href = `${baseURL}/accounts/byteark`;
      }

      return Promise.reject(error);
    }
  );

  // setup axios for access key provider service api
  axiosAccessKeyAPI.defaults.withCredentials = true;
  axiosAccessKeyAPI.defaults.headers.common = { "X-ByteArk-Team": teamName };

  axiosAccessKeyAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        window.location.href = `${baseURL}/accounts/byteark`;
      }

      return Promise.reject(error);
    }
  );
};

const configAxiosForDevelopment = () => {
  if (process.env && process.env.NODE_ENV === "development") {
    const headers = {
      "X-ByteArk-Team": team,
      Authorization: token,
    };
    axiosUploadAPI.defaults.headers.common = headers;
    axiosStorageAPI.defaults.headers.common = headers;
    axiosAccessKeyAPI.defaults.headers.common = headers;
  }
};

export { configAxiosForWebConsole, configAxiosForDevelopment };
