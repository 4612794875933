import {
  EmailText,
  ErrorAdditionalInfo,
  ErrorContainer,
  ErrorDescription,
  ErrorImage,
  ErrorTitle,
} from "./ErrorStyles.style";
import image from "../assets/images/notfound.svg";
import { Container } from "../components/GlobalStyle/GlobalStyle.style";
import { PageContainer } from "../pages/fileList/FileList.style";
import { ErrorHeader } from "./ErrorHeader";
import { useAppSelector } from "../store/hooks";
import { useEffect } from "react";

export const PageNotFound = () => {
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    document.title = "ByteArk Storage";
  }, []);

  return (
    <PageContainer>
      <ErrorHeader />
      <Container>
        <ErrorContainer>
          <ErrorImage
            width={320}
            height={127}
            src={image} />
          <ErrorTitle>
            Page Not found
          </ErrorTitle>
          <ErrorDescription>
            This page doesn't exist. Please verify the link or try again later.
          </ErrorDescription>
          <ErrorAdditionalInfo>
            You’re signed in as <EmailText>{user ? user.email : "user@inox.co.th"}</EmailText>
          </ErrorAdditionalInfo>
        </ErrorContainer>
      </Container>
    </PageContainer>
  );
};
