import styled from "styled-components";
import { BaseErrorMessageContainer } from "../../components/molecules/baseErrorMessage/BaseErrorMessage.style";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--lightBlue);
`;

export const Container = styled.div`
  padding-left: var(--space-base);
  padding-right: var(--space-base);
  min-height: 400px;

  @media screen and (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    width: 1100px;
  }
`;

export const ContentContainer = styled.div`
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  padding: var(--space-base);
  margin-top: var(--space-m);
  margin-bottom: var(--space-m);
  
  ${BaseErrorMessageContainer} {
    border: 1.5px solid var(--grey300);
    border-radius: var(--border-radius-large);
  }
`;

export const LoadingContainer = styled.div`
  border-radius: var(--border-radius-large);
  margin-top: var(--space-m);
  margin-bottom: var(--space-m);
`;
