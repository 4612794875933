import styled, { css } from "styled-components";

import { Link } from "../../atoms/link/Link";
import { maxLayout, minLayout } from "../../../utils/breakpoint/breakpoint";
import { sectionHeaderStyle } from "../../GlobalStyle/GlobalStyle.style";

export const NavBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  width: 100%;
  box-sizing: content-box;

  @media ${minLayout.md} {
    justify-content: space-between;
  }

  @media screen and (min-width: 1200px) {
    padding: 12px var(--space-base);
    width: calc(100% - 32px);
  }
`;

export const StorageLogoContainer = styled(Link)`
  ${sectionHeaderStyle};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;
  margin-bottom: 10px;

  > div {
    margin-top: var(--space-s);
  }

  :hover {
    cursor: pointer;
  }
`;

export const StorageLogo = styled.img`
  width: 27px;
  margin-right: var(--space-s);
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${maxLayout.md} {
    display: none;
  }
`;

export const ProfileImage = styled.div`
  margin-right: var(--space-s);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 50%;
  background: var(--grey500);
  color: var(--navy);
`;

export const ProfileLabel = styled.span`
  font-size: 14px;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--grey400);
  padding-right: var(--space-s);

  @media ${maxLayout.md} {
    font-size: 16px;
    width: 200px;
  }
`;

export const ProfileDropdownWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

  svg {
    margin-right: 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  color: var(--white);
  position: absolute;
  left: var(--space-base);
  margin-bottom: var(--space-xs);

  @media ${minLayout.md} {
    display: none;
  }
`;

export const CloseIconWrapper = styled.div`
  margin: auto 0;
`;

export const HiddenSideBar = styled.div<{ isActive?: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 13;
  transform: translateX(${(props) => (props.isActive ? 0 : "-360px")});
  transition: transform 250ms ease-in-out;
  height: 100%;
  max-height: 100%;
  background: var(--navy);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template-rows: 75px 90px 8fr 1fr;
  grid-template-columns: 300px;
`;

export const SidebarButton = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: var(--grey500);
  text-decoration: none;
  > :first-child {
    margin-bottom: var(--space-s);
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--space-base);
  color: var(--white);
  padding: var(--space-base) var(--space-base) 12px var(--space-base);
  align-items: stretch;
`;

export const SidebarButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SidebarServiceLabel = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: var(--white);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;

export const SidebarServiceUrl = styled.span`
  font-size: 14px;
  font-style: italic;
  margin-top: var(--space-s);
  color: var(--grey700);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: pre;
`;

export const SidebarServiceContainer = styled.div<{ isServiceUrl?: boolean }>`
  display: flex;
  flex-direction: column;
  background: var(--navyDarken);
  padding: var(--space-base);

  ${(props) =>
    props.isServiceUrl &&
    css`
      justify-content: center;
    `}
`;

export const SidebarTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--grey900);
  background: var(--navyDarken);
  overflow-y: scroll;
  height: 100%;
`;

export const HiddenContainer = styled.div<{ isActive?: boolean }>`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  flex-direction: column;
  transition: all 2s linear;
`;

export const TabContainer = styled(Link)<{
  type: "main" | "side" | "sub";
  isActive: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: var(--space-base);
  border-left: var(--space-xs) solid transparent;
  padding-left: ${(props) =>
    props.type === "main"
      ? "var(--space-base)"
      : props.type === "side"
      ? "var(--space-xl)"
      : "80px"};
  font-size: ${(props) =>
    props.type === "sub" ? "var(--space-base)" : "18px"};
  color: var(--grey600);
  text-decoration: none;
  white-space: nowrap;

  ${(props) => {
    if (props.type === "main" && props.isActive) {
      return css`
        color: var(--lightBlue);
        background: var(--overlay);
      `;
    } else if (props.type === "side" && props.isActive) {
      return css`
        color: var(--lightBlue);
        background: var(--primaryOverlay);
        border-color: var(--primary);
      `;
    } else if (props.type === "sub" && props.isActive) {
      return css`
        color: var(--lightBlue);
        background: var(--primaryOverlay);
        border-color: var(--primary);
      `;
    }
  }}
`;

export const SidebarOverLay = styled.div<{ isActive: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--navy);
  opacity: 0.5;
  z-index: ${(props) => (props.isActive ? 12 : -10)};

  ${(props) => {
    if (props.isActive) {
      return css`
        .body {
          position: fixed;
          top: 0px;
        }
      `;
    }
  }}
`;

export const SideTabProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-base);
  font-size: 16px;
  z-index: 14;
  color: var(--grey400);
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.2);

  svg {
    margin-right: 0;
  }
`;

export const SideTabProfileWWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SidebarProfileTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px var(--space-base);
  color: var(--grey600);
  :first-child {
    border-top: 1px solid var(--grey900);
  }
  :nth-child(2) {
    border-bottom: 1px solid var(--grey900);
  }

  > svg {
    margin-right: var(--space-s);
  }
`;
