import styled, { css } from "styled-components";
import { Link } from "../..";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  > :not(:last-child) {
    margin-right: var(--space-xs);
  }
  @media ${maxLayout.md} {
    display: none;
  }
`;

export const Tab = styled(Link)<{ isActive?: boolean }>`
  padding: var(--space-s) var(--space-base);
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  cursor: pointer;
  text-decoration: none;
  color: var(--grey300);
  background: var(--overlay);
  :hover {
    background: var(--overlayHover);
  }
  ${(props) => {
    if (props.isActive) {
      return css`
        color: var(--navy);
        background: var(--lightBlue);
        :hover {
          background: var(--lightBlue);
        }
      `;
    }
  }}
`;
