import styled, { css } from "styled-components";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";
import { TextAndIconContainer, TitleText } from "../../textWithIcon/TextAndIcon.style";

export const UploadProgressItemContainer = styled.div`
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  display: flex;
  align-items: center;
  color: var(--grey600);
  border-color: var(--grey200);
`;

export const UploadProgressDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid var(--grey400);
  padding-top: 14px;
  padding-right: 10px;
  padding-bottom: 12px;
  padding-left: var(--space-base);
  width: 100%;

  @media ${maxLayout.sm} {
    flex: 1 0 auto;
  }
`;

export const FileTitleContainer = styled.div`
  flex: 1;
  color: var(--grey900);

  ${TextAndIconContainer} {
    align-items: flex-start;
  };

  ${TitleText} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-right: 10px;
    @media ${maxLayout.md} {
      max-width: 70vw;
    }
  };

  svg {
    display: block;
    font-size: 18px;
  }
`;

export const UploadCanceledText = styled.span`
  position: relative;
  font-size: 12px;
  top: 1px;
  color: var(--grey700);
  margin-right: var(--space-s);
`;

export const FileProgressContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  flex: 0 0 auto;

  .CircularProgressbar {
    display: flex;
    width: 20px;
    height: 20px;
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #A7ABAF;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: var(--navy);
  }
`;

export const CircleStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 13px;
`;

export const CircleRetry = styled.div`
  ${CircleStyles};
  cursor: pointer;
  font-size: 18px;

  .fa-undo-alt {
    position: relative;
    left: -1px;
    transform: scaleX(-1);
  }
`;

export const CircleCompleted = styled.div`
  ${CircleStyles};
  background-color: var(--green);
`;

export const CircleError = styled.div`
  ${CircleStyles};
  background-color: var(--red);
`;

export const CircleCancel = styled.div`
  ${CircleStyles};
  display: none;
  background-color: var(--grey800);
  cursor: pointer;
`;

export const CircularContainer = styled.div`
  ${CircleRetry} {
    display: none;
  }

  :hover {
    .CircularProgressbar {
      display: none;
    }
    
    ${CircleCancel} {
      display: flex;
    }
  }

  :hover {
    ${CircleError} {
      display: none;
    }

    ${CircleRetry} {
      display: flex;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: var(--red);
  font-size: 10px;
  margin-bottom: 0;
`;
