import { Button } from "../../atoms/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  NotificationContainer,
  NotificationTextWarpper,
  Title,
  Description,
  NotificationButtonWarpper,
  ConfirmButton,
  CancelButton,
} from "./NotificationDelete.style";

interface NotificationDeleteProps {
  /**
   * Title of notification.
   */
  title?: string;
  /**
   * Description which describe about this notification.
   */
  description?: string;
  /**
   * A showing state of the notification.
   */
  isShowing: boolean;
  /**
   * A function for confirm button.
   */
  onConfirm?: () => void;
  /**
   * A function for cancel button.
   */
  onCancel?: () => void;
}

export const NotificationDelete = (props: NotificationDeleteProps) => {
  const { title, description, isShowing, onConfirm, onCancel } = props;

  if (!isShowing) {
    return <></>;
  }

  return (
    <NotificationContainer>
      <NotificationTextWarpper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </NotificationTextWarpper>
      <NotificationButtonWarpper>
        <ConfirmButton >
          <Button buttonStyle="danger" isIconButton={false} fullWidth onClick={onConfirm}>
            <FontAwesomeIcon icon={["fas", "check"]} />
          </Button>
        </ConfirmButton>
        <CancelButton >
          <Button buttonStyle="secondary-a" isIconButton={false} fullWidth onClick={onCancel}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </Button>
        </CancelButton>
      </NotificationButtonWarpper>
    </NotificationContainer>
  );
};
