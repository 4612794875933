import styled, { keyframes } from "styled-components";
import { sectionHeaderStyle } from "../../../GlobalStyle/GlobalStyle.style";
import { BaseErrorMessageContainer } from "../../../molecules/baseErrorMessage/BaseErrorMessage.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";
import { TitleText } from "../../../molecules/textWithIcon/TextAndIcon.style";

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const FileListSidebarContainer = styled.div<{ hide: boolean }>`
  position: sticky;
  top: 20px;
  width: 315px;
  background-color: var(--white);
  padding: var(--space-base);
  border-radius: var(--border-radius-default);
  min-height: calc(100vh - 300px);

  ${BaseErrorMessageContainer} {
    background-color: transparent;
  }

  @media ${maxLayout.md} {
    position: fixed;
    width: 75vw;
    min-height: 100vh;
    border-radius: 0;
    padding: var(--space-l);
    top: 0;
    right: 0;
    z-index: 10;

    transform: ${(props) =>
      props.hide ? "translateX(75vw)" : "translateX(0)"};
    transition: transform 350ms ease;
  }

  @media ${maxLayout.sm} {
    padding: var(--space-l) var(--space-base);
  }
`;

export const GuideContainer = styled.div`
  h4 {
    ${sectionHeaderStyle};
    margin-bottom: var(--space-base);
    color: var(--grey900);
  }
  p {
    font-size: 14px;
    margin-bottom: 12px;
    color: var(--grey900);
  }
  a {
    color: var(--primaryDarken);
    :hover {
      text-decoration: underline;
    }
  }
`;

export const FileInfoContainer = styled.div``;

const maxCharPerLine = 25;
const fileNameLineHeight = 23.5;
const buttonHeight = 36;

export const OverflowContainer = styled.div<{ fileNameLength: number }>`
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(
    100vh -
      ${(props) =>
        Math.ceil(props.fileNameLength / maxCharPerLine) * fileNameLineHeight +
        buttonHeight +
        360}px
  );
  margin-top: var(--space-base);

  @media ${maxLayout.md} {
    max-height: 84vh;
  }
`;

export const SidebarTitle = styled.div`
  ${TitleText} {
    ${sectionHeaderStyle};
    line-height: 130%;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  svg {
    font-size: 0.9em;
  }
`;

export const SidebarOverlay = styled.div<{ hide: boolean }>`
  display: none;
  position: fixed;
  background-color: rgba(33, 45, 55, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;

  @media ${maxLayout.md} {
    display: ${(props) => (props.hide ? "none" : "block")};
  }
`;

export const DownloadButtonContainer = styled.div`
  width: 100%;

  button {
    position: absolute;
    bottom: 16px;
    background-color: var(--white);
    width: calc(100% - 30px);

    .fa-download {
      margin-right: 6px;
    }

    @media ${maxLayout.md} {
      width: calc(100% - 64px);
    }

    @media ${maxLayout.sm} {
      width: calc(100% - 32px);
    }
  }
`;
