import { NavBar } from "../../..";
import {
  HeaderWrapper,
  ServiceLabel,
  ServiceLabelContainer,
} from "./BucketListHeader.style";
import { BreadcrumbHeader } from "../../../atoms/breadcrumbHeader/BreadcrumbHeader";
import { HeaderContainer } from "../../../GlobalStyle/GlobalStyle.style";
import { useAppSelector } from "../../../../store/hooks";

interface BucketListHeaderProps {
  spaceKey: string;
  bucketName: string;
}

export const BucketListHeader = (props: BucketListHeaderProps) => {
  const { user } = useAppSelector((state) => state.user);
  const { spaceKey } = props;
  const breadcrumbItems = [
    {
      title: spaceKey,
      redirectUrl: `/${user?.team.namespace}/spaces/${spaceKey}/bucket`,
    }
  ];

  return (
    <HeaderContainer>
      {user ? (
        <NavBar
          userProfile={user.displayImage.sizes.small.url}
          serviceLabel={spaceKey}
        />
      ) : (
        <NavBar serviceLabel={spaceKey} />
      )}
      <HeaderWrapper>
        <BreadcrumbHeader path={breadcrumbItems} />
        <ServiceLabelContainer>
          <ServiceLabel>{spaceKey}</ServiceLabel>
        </ServiceLabelContainer>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
