import styled from "styled-components";
import { subTitleStyle } from "../../../GlobalStyle/GlobalStyle.style";
import { ButtonWrapper } from "../../../atoms/button/Button.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const SectionDeleteBucketContainer = styled.div`
  background-color: var(--white);
  border-radius: var(--border-radius-default);
  padding: var(--space-base);
  margin-top: var(--space-m);
  margin-bottom: var(--spcae-l);
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  flex: 0 0 70%;

  @media ${maxLayout.sm} {
    flex: 0 0 100%;
  }
`;

export const Title = styled.h2`
  color: var(--grey900);
  font-size: 24px;
  margin-bottom: var(--space-s);

  @media ${maxLayout.sm} {
    font-size: 18px;
  }
`;

export const Description = styled.p`
  ${subTitleStyle};
`;

export const ActionButtonWrapper = styled.div`
  ${ButtonWrapper} {
    width: 156px;
    padding: var(--space-s) 18px; 
  }

  @media ${maxLayout.sm} {
    display: none;
  }
`;

export const MobileButtonWrapper = styled.div`
  display: none;
  width: 100%;
  margin-top: var(--space-base);
  
  ${ButtonWrapper} {
    width: 100%;
  }

  @media ${maxLayout.sm} {
    display: block;
  }
`;

export const SectionBody = styled.div``;
