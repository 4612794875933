import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyButton, InputCopyContainer } from "./InputCopy.style";
import { Input } from "../../atoms/textInput/TextInput.style";

interface InputCopyProps {
  /**
   * Value to display and to copy.
   */
  value: string;
  /**
   * Is Value display.
   */
  isHidden?: boolean;
  /**
   * custom copy function
   */
  onClick?: () => void;
}

export const InputCopy = (props: InputCopyProps) => {
  const { value, isHidden = false, onClick } = props;
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const onCopy = () => {
    const hiddenElement = document.createElement("input");

    document.body.appendChild(hiddenElement);
    hiddenElement.value = value;
    hiddenElement.select();
    document.execCommand("copy");
    document.body.removeChild(hiddenElement);
    setIsCopied(true);

    const copyStateTimeout = setTimeout(() => {
      setIsCopied(false);
      clearTimeout(copyStateTimeout);
    }, 1200);
  };

  const onCustomCopy = () => {
    if(onClick){
      onClick()
      setIsCopied(true);
  
      const copyStateTimeout = setTimeout(() => {
        setIsCopied(false);
        clearTimeout(copyStateTimeout);
      }, 1200)
    }

  }

  return (
    <InputCopyContainer>
      <Input
        value={value}
        type={isHidden ? "password" : "text"}
        readOnly={true}
      />
      <CopyButton onClick={onClick ? onCustomCopy : onCopy}>
        {Boolean(isCopied) ? (
          <FontAwesomeIcon icon={["fas", "check"]} />
        ) : (
          <FontAwesomeIcon icon={["fas", "copy"]} />
        )}
        {isCopied ? "Copied" : "Copy"}
      </CopyButton>
    </InputCopyContainer>
  );
};
