import styled, { css } from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const Container = styled.div`
  border-right: 1px solid var(--grey400);
  position: relative;

  @media ${maxLayout.lg} {
    display: flex;
    background-color: var(--grey200);
    border-radius: var(--border-radius-large);
    padding: 2px;
    margin: 0 var(--space-m);
    margin-bottom: var(--space-base);
  }

  @media ${maxLayout.md} {
    margin: 0 var(--space-base);
    margin-bottom: var(--space-base);
  }
`;

export const Wrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 100%;
  justify-content: left;
  height: auto;
  padding: 16px;
  border-left: 4px solid transparent;
  cursor: pointer;
  color: var(--grey700);
  font-weight: 500;
  align-items: center;
  font-size: 14px;

  &:hover {
    color: var(--primary);
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: rgba(0, 166, 237, 0.05);
      border-left: 4px solid var(--primary);
      color: var(--primary);
    `}

  @media ${maxLayout.lg} {
    border-left: 0;
    justify-content: center;
    padding: 0px;
    padding: 4px 10px 5px;
    font-size: 12px;

    ${(props) =>
      props.isActive &&
      css`
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
        background-color: var(--white);
        border-radius: var(--border-radius-large);
        color: var(--primary);
      `}
  }
`;
