import styled from "styled-components";
import {maxLayout} from "../../../../utils/breakpoint/breakpoint";
import {pageHeaderStyle} from "../../../GlobalStyle/GlobalStyle.style";

export const HeaderWrapper = styled.div`
  position: relative;
  max-width: 1100px;
  width: 100%;
  @media ${maxLayout.xl} {
    max-width: unset;
  }
`;

export const ServiceLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: var(--space-base) 0 var(--space-m);
  overflow: hidden;


  @media ${maxLayout.sm} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: var(--space-base);
  }
`;

export const ServiceLabel = styled.span`
  ${pageHeaderStyle};
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 20px;
  white-space: nowrap;
  
  @media ${maxLayout.sm} {
    width: 100%;
    font-size: 24px;
    padding-bottom: 6px;
  }
`;

export const BackButtonContainer = styled.div`
@media ${maxLayout.xl} {
    display: none;
  }
  position: absolute;
  top: 28px;
  left: -48px;
  cursor: pointer;

  .fa-chevron-left {
    font-size: 1.45em;
  }
`;

