import { Container, Wrapper } from "./SideTab.style";

export interface sideTabItemProp {
  title: string;
  info?: string;
  tableHeader:string;
}

export interface SideTabProp {
  sideTabItem: sideTabItemProp[];
  activeTab: number;
  onClick: (sideTabIndex: number) => void;
}

export const SideTab = (props: SideTabProp) => {
  const { sideTabItem, activeTab, onClick } = props;

  return (
    <Container>
      {sideTabItem.map((item, index) => (
        <Wrapper
          isActive={Boolean(activeTab === index)}
          key={`side-tab-item-${index}`}
          onClick={() => onClick(index)}
        >
          {item.title}
        </Wrapper>
      ))}
    </Container>
  );
};
