import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SettingsReducerState {
  deletedBucket: string;
  corsErrorMessage: string;
  isCorsSaved: boolean;
  isCorsModalShowing: boolean;
  isDeleteBucketModalShowing: boolean;
}

const initialState: SettingsReducerState = {
  deletedBucket: "",
  corsErrorMessage: "",
  isCorsModalShowing: false,
  isDeleteBucketModalShowing: false,
  isCorsSaved: false,
};

export const settingsSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setCorsErrorMessage: (state, action: PayloadAction<string>) => {
      state.corsErrorMessage = action.payload;
    },
    setDeletedBucket: (state, action: PayloadAction<string>) => {
      state.deletedBucket = action.payload;
    },
    setIsCorsModalShowing: (state, action: PayloadAction<boolean>) => {
      state.isCorsModalShowing = action.payload;
    },
    setIsDeleteBucketModalShowing: (state, action: PayloadAction<boolean>) => {
      state.isDeleteBucketModalShowing = action.payload;
    },
    setIsCorsSaved: (state, action: PayloadAction<boolean>) => {
      state.isCorsSaved = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCorsErrorMessage,
  setDeletedBucket,
  setIsCorsModalShowing,
  setIsCorsSaved,
  setIsDeleteBucketModalShowing,
} = settingsSlice.actions;

export default settingsSlice.reducer;
