import styled from "styled-components";
import { ButtonWrapper } from "../../../atoms/button/Button.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const DeleteBucketContainer = styled.div`
  width: 420px;

  @media ${maxLayout.sm} {
    width: 80vw;
  }
`;

export const Description = styled.p`
  color: var(--grey900);
  font-size: 14px;
  margin-bottom: var(--space-base);

  * {
    font-weight: 500;
    overflow-wrap: anywhere;
  }

`;

export const HelpText = styled.p`
  color: var(--grey900);
  font-size: 14px;
  margin-bottom: var(--space-s);
`;

export const ErrorText = styled.p`
  color: var(--red);
  margin-top: var(--space-base);
  margin-bottom: var(--space-base);
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${ButtonWrapper} {
    width: 48%;
  }
`;
