import styled from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const SubTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey900);
  margin-bottom: var(--space-s);
`;

export const Description = styled.p`
  font-size: 12px;
  color: var(--grey700);
  margin-bottom: var(--space-s);
`;

export const InputWraper = styled.div`
  width: 462px;

  @media ${maxLayout.sm} {
    max-width: 80vw;
  }
`;

export const DropdownWraper = styled.div`
  width: 180px;

  @media ${maxLayout.sm} {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-top: var(--space-base);
  @media ${maxLayout.md} {
    width: 100%;
  }
`;