import { NavBar } from "../../..";
import {
  HeaderWrapper,
  ServiceLabel,
  ServiceLabelContainer,
} from "./SpaceListHeader.style";
import { HeaderContainer } from "../../../GlobalStyle/GlobalStyle.style";
import { useAppSelector } from "../../../../store/hooks";

export const SpaceListHeader = () => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <HeaderContainer>
      {user ? (
        <NavBar
          userProfile={user.displayImage.sizes.small.url}
          serviceLabel={"Spaces"}
        />
      ) : (
        <NavBar serviceLabel={"Spaces"} />
      )}
      <HeaderWrapper>
        <ServiceLabelContainer>
          <ServiceLabel>Spaces</ServiceLabel>
        </ServiceLabelContainer>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
