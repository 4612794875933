import styled from "styled-components";

export const TextAndIconContainer = styled.div<{
  verticalAlign: "top" | "center" | "bottom";
}>`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) =>
    props.verticalAlign === "top"
      ? "flex-start"
      : props.verticalAlign === "bottom"
      ? "flex-end"
      : "center"};
`;

export const IconContainer = styled.div`
  margin-right: var(--space-s);
  font-size: 1.5em;
`;

export const TextContainer = styled.div``;

interface TextProps {
  color?: string;
  fontSize?: number;
  fontWeight?: 400 | 500 | 700;
}

export const TitleText = styled.p<TextProps>`
  color: ${(props) => (props.color ? props.color : "var(--grey900)")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  margin-bottom: 0;
  word-break: break-all;
  width: 100%;
`;
