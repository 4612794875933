import styled from "styled-components";
import { FadeIn } from "../../../organisms/fileList/fileListSidebar/FileListSidebar.style";

export const SectionFilePropertiesContainer = styled.div``;

export const DataMetaContainer = styled.div`
`;

export const Title = styled.p`
  color: var(--grey800);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: var(--space-s);
`;

export const MetaGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-s);
`;

export const MetaTitle = styled.div`
  flex: 0 0 35%;
  color: var(--grey700);
  text-align: left;
  font-weight: 500;
  font-size: 14px;
`

export const MetaContent = styled.div`
  flex: 0 0 62%;
  color: var(--grey900);
  font-size: 14px;

  span {
    animation: ${FadeIn} 120ms ease;
  }

  .fa-icon {
    font-size: 1.2em;
    position: relative;
    top: 1px;
    margin-right: var(--space-s);

    path {
      fill: var(--grey900);
    }
  }
`;

export const InputGroup = styled.div`
  margin-bottom: var(--space-s);
  animation: ${FadeIn} 120ms ease;

  p {
    margin-bottom: var(--space-s);
    color: var(--grey700);
    font-weight: 500;
    font-size: 14px;
  }
`;
