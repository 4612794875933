import { useAppSelector } from "../store/hooks";
import { HeaderContainer } from "../components/GlobalStyle/GlobalStyle.style";
import { NavBar } from "../components";
import {
  HeaderWrapper,
  ServiceLabel,
  ServiceLabelContainer,
} from "../components/organisms/fileList/fileListHeader/FileListHeader.style";

export const ErrorHeader = () => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <HeaderContainer>
      <NavBar
        userProfile={user && user.displayImage.sizes.small.url}
        serviceLabel={"ByteArk Storage"}
      />
      <HeaderWrapper>
      <ServiceLabelContainer>
        <ServiceLabel>ByteArk Storage</ServiceLabel>
      </ServiceLabelContainer>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
