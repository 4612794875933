import { BaseErrorMessage, ErrorMessageButton } from "../../molecules/baseErrorMessage/BaseErrorMessage";

interface EmptyMessageProps {
  /**
   * The name of the item that is empty
   * @default 'data'
   * */
  item?: string,
  /**
   * Description
   * @default `You don't have any ${item}.`
   * */
  description?: string,
  /**
   * The button under the description text.
   */
  button?: ErrorMessageButton,
}

export const EmptyMessage = (props: EmptyMessageProps) => {
  let { description, item, button } = props;

  if (!item) {
    item = 'data';
  }

  if (!description) {
    description = `You don't have any ${item}.`;
  }

  return (
    <BaseErrorMessage
      icon={'inbox'}
      title={`No ${item}`}
      description={description}
      button={button}
    />
  );
};
