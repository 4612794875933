import styled, { css } from "styled-components";

import { Button } from "../../../atoms/button/Button";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const DropdownWrapper = styled.div`
  border-radius: var(--border-radius-large);
`;

export const DropdownButton = styled(Button)<{ isActive: boolean }>`
  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--grey800);
      color: var(--white);
    `}
`;

export const DropdownOverlay = styled.div<{ isActive?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: ${(props) => (props.isActive ? 12 : -10)};
`;

export const DropdownOptionsContainer = styled.div<{
  isActive?: boolean;
  popoverPosition?: "top" | "bottom";
}>`
  position: absolute;
  display: flex;
  z-index: 13;
  border-radius: var(--border-radius-default);
  padding: 1px;
  right: 0;
  bottom: ${(props) => (props.popoverPosition === "top" ? "44px" : "")};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  background: var(--white);
  border: 1px solid var(--grey300);
  padding: var(--space-base);

  ${(props) => {
    if (props.isActive) {
      return css`
        pointer-events: auto;
        opacity: 1;
        transform: translateY(4px);
      `;
    } else {
      return css`
        pointer-events: none;
        opacity: 0;
        transition: all 0.25s ease-out;
        transform: translateY(10px);
        visibility: hidden;
      `;
    }
  }}
`;

export const IconWrapper = styled.div`
  width: var(--space-base);
  margin-right: var(--space-s);
`;

export const TextWrapper = styled.div`
  min-width: 136px;
  width: 100%;
  text-align: left;
`;

export const YearContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 146px;
  margin-left: var(--space-base);

  @media ${maxLayout.sm} {
    min-width: max-content;
  }
`;

export const MonthContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 146px;
`;

export const Text = styled.div`
  margin-bottom: var(--space-s);
`;
