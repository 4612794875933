import styled from "styled-components";
import { maxLayout, minLayout } from "../../utils/breakpoint/breakpoint";
import { ButtonWrapper } from "../../components/atoms/button/Button.style";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--lightBlue);
`;

export const Content = styled.div`
  padding: var(--space-m) 0;

  @media ${maxLayout.md} {
    padding: var(--space-base) 0;
    flex-direction: column;
  }
`;

export const SearchBoxWrapper = styled.div`
  width: 462px;
  margin-right: var(--space-base);

  @media ${maxLayout.md} {
    width: 100%;
  }

  @media ${maxLayout.sm} {
    width: 100%;
  }
`;

export const SearchResultContainer = styled.div`
  font-size: 14px;
  line-height: 1.4;
  color: var(--grey800);
  margin-bottom: var(--space-s);
  word-break: break-all;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-base);

  ${ButtonWrapper} {
    height: 36px;
  }

  @media ${maxLayout.md} {
    flex-direction: column;

    ${ButtonWrapper} {
      order: 1;
      width: 100%;
      margin-bottom: var(--space-base);
    }

    ${SearchBoxWrapper} {
      order: 2;
    }
  }
`;

export const FlexContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-m);

  @media ${maxLayout.md} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FlexGroup = styled.div`
  .tabs-container {
    @media ${maxLayout.xxs} {
      flex-direction: column;
      align-items: flex-start;

      a:not(:first-child) {
        margin-top: var(--space-base);
      }
    }
  }
`;

export const IconWrapper = styled.div`
  margin-top: -1px;
  margin-right: var(--space-s);
`;

export const ButtonContainer = styled.div`
  display: flex;

  button {
    min-width: 150px;
  }

  @media ${maxLayout.md} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  @media ${minLayout.md} {
    button {
      :not(:first-child) {
        margin-left: var(--space-base);
      }
    }
  }
`;
