/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEvent, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardFooter,
  SectionSpaceCardsContainer,
} from "./SectionSpaceCards.style";
import { PopoverItemObject } from "../../../atoms/popover/Popover";
import {
  fetchSpaces,
  resetErrorState,
  resetSpaceState,
  setIsSpaceLoading,
  setIsSpacePermissionShowing,
  setSelectedSpace,
  setSpacePopoverIndex,
  SpaceProps,
} from "../../../../store/reducers/spaces";
import { Card } from "../../..";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ModalManageSpacePermission } from "../modalManageSpacePermission/ModalManageSpacePermission";
import { ModalCreateSpace } from "../../../organisms/spaceList/modalCreateSpace/ModalCreateSpace";
import { LoadingMessage } from "../../../organisms/messages/LoadingMessage";
import { useHistory } from "react-router-dom";
import { ErrorMessage } from "../../../organisms/messages/ErrorMessage";

export const SectionSpaceCards = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const {
    error,
    filteredSpaces,
    spacePopoverIndex,
    isSpaceLoading,
  } = useAppSelector((state) => state.spaces);

  const dismissPopover = () => {
    dispatch(setSpacePopoverIndex(-1));
  };

  const onClickMenu = (index: number) => {
    if (spacePopoverIndex === index) {
      dismissPopover();
    } else {
      dispatch(setSpacePopoverIndex(index));
    }
  };

  const getPopoverItems = (space: SpaceProps): Array<PopoverItemObject> => [
    {
      title: "Manage Permissions",
      onClick: (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setSelectedSpace(space));
        dispatch(setIsSpacePermissionShowing(true));
        dismissPopover();
      },
    },
    {
      title: "Manage Space",
      onClick: (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dismissPopover();
        history.push(`/${user?.team.namespace}/spaces/${space.key}/manage`);
      },
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      dispatch(resetErrorState());
      await dispatch(fetchSpaces());
      dispatch(setIsSpaceLoading(false));
    };

    loadData();

    return () => {
      dispatch(resetSpaceState());
    };
  }, []);

  if (isSpaceLoading && !error) {
    return <LoadingMessage />;
  }

  if (!isSpaceLoading && error) {
    return <ErrorMessage />;
  }

  return (
    <SectionSpaceCardsContainer>
      {filteredSpaces.map((data: SpaceProps, index: number) => (
        <Card
          key={`space-card-${index}`}
          title={data.key}
          label={"SPACE NAME"}
          redirectUrl={`/${user?.team.namespace}/spaces/${data.key}/bucket`}
          isPopoverShowing={spacePopoverIndex === index}
          onToggleMenu={() => onClickMenu(index)}
          popoverItems={
            user?.id === data.ownerBytearkAccountId
              ? getPopoverItems(data)
              : undefined
          }
          footer={
            <CardFooter>
              {data.defaultPermission === "owner-access" ? (
                <>
                  <FontAwesomeIcon icon={["fas", "lock-alt"]} />
                  Personal Access
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={["fas", "users"]} />
                  Team Access
                </>
              )}
            </CardFooter>
          }
        />
      ))}
      <ModalManageSpacePermission />
      <ModalCreateSpace />
    </SectionSpaceCardsContainer>
  );
};
