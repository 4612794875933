import axios from 'axios';

const baseURL = process.env.REACT_APP_ORIGIN || window.location.origin;

const axiosStorageAPI = axios.create({
  baseURL: `${baseURL}/api/storage/v1/`,
});

const axiosAuthAPI = axios.create({
  baseURL: `${baseURL}/api/auth/v1/`,
});

const axiosUploadAPI = axios.create({
  baseURL: `${baseURL}/api/upload/v1/`,
});

const axiosAccessKeyAPI = axios.create({
  baseURL: `${baseURL}/api/accesskey-provider/v1/`,
});

export {
  axiosStorageAPI,
  axiosAuthAPI,
  axiosUploadAPI,
  axiosAccessKeyAPI,
};
