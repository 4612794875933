/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import heroImg from "../../assets/images/verify.svg";
import { heroData, featuresData } from "./content";

import { Button } from "../../components";

import {
  ContentContainer,
  HeroContainer,
  HeroContent,
  GradientButton,
  Image,
  FeatureWrapper,
  FeatureContainer,
  Icon,
  Status,
  ButtonWrapper,
} from "./Activate.style";
import { Container } from "../../components/GlobalStyle/GlobalStyle.style";
import { ActivateHeader } from "../../components/organisms/activateHeader/ActivateHeader";
import { PageContainer } from "../bucketList/BucketList.style";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchSpaceActivated,
  resetErrorState,
  setActivateSpace,
} from "../../store/reducers/user";

import { LoadingMessage } from "../../components/organisms/messages/LoadingMessage";
import { useHistory } from "react-router";

export const Activate = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const { user, isDefaultSpaceActivated } = useAppSelector(
    (state) => state.user
  );

  const handleActivated = () => {
    const postData = async () => {
      dispatch(resetErrorState());
      const response = await dispatch(setActivateSpace());

      if (setActivateSpace.fulfilled.match(response)) {
        if (!response.payload.errorCode) {
          console.log("success");
        } else {
          console.log("fail");
        }
      }
    };
    postData();
  };

  useEffect(() => {
    const loadData = async () => {
      await dispatch(fetchSpaceActivated());
      setIsLoading(false);
    };
    loadData();
  }, [user]);

  return (
    <PageContainer>
      <ActivateHeader />
      <Container>
        <ContentContainer>
          {isLoading ? (
            <LoadingMessage />
          ) : isDefaultSpaceActivated ? (
            isDefaultSpaceActivated ? (
              <>
                <Icon size={"8x"} icon={["fas", "check-circle"]} />
                <Status>Your storage is ready !</Status>
                <ButtonWrapper>
                  <Button
                    isIconButton
                    fullWidth
                    onClick={() => {
                      if (user) {
                        history.push(`/${user.team.namespace}/spaces`);
                      }
                    }}
                  >
                    Go to Your First Team Space
                  </Button>
                </ButtonWrapper>
              </>
            ) : (
              <>
                <Icon size={"8x"} icon={["fas", "circle-notch"]} spin />
                <Status>
                  Activating your storage <br />
                  Please wait...
                </Status>
              </>
            )
          ) : (
            <>
              <HeroContainer>
                <HeroContent>
                  <h1>{heroData.feature}</h1>
                  <h3>{heroData.subTitle}</h3>
                  <p>{heroData.detail}</p>
                  <GradientButton type="button" onClick={handleActivated}>
                    Get Started
                  </GradientButton>
                </HeroContent>
                <Image src={heroImg} alt="create bucket" />
              </HeroContainer>
              <FeatureWrapper>
                {featuresData.map((item) => (
                  <FeatureContainer key={item.feature}>
                    <h2>{item.feature}</h2>
                    <p>{item.detail}</p>
                  </FeatureContainer>
                ))}
              </FeatureWrapper>
            </>
          )}
        </ContentContainer>
      </Container>
    </PageContainer>
  );
};
