import styled from "styled-components";
import { subTitleStyle } from "../../../GlobalStyle/GlobalStyle.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const ManageSpaceKeyContainer = styled.div``;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-base);
  margin-bottom: var(--space-s);

  button {
    padding: 6px var(--space-m);
  }

  @media ${maxLayout.md} {
    flex-direction: column;
    align-items: start;
    margin-bottom: var(--space-base);

    button {
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  color: var(--grey900);
  font-size: 24px;
  margin-bottom: var(--space-s);
  @media ${maxLayout.sm} {
    font-size: 18px;
  }
`;

export const SubTitle = styled.h2`
  color: var(--grey800);
  font-size: 16px;
  margin-bottom: var(--space-s);
`;

export const AccessTitle = styled.h2`
  color: var(--grey800);
  font-size: 16px;
  margin-bottom: 0;
  @media ${maxLayout.lg} {
    margin-bottom: var(--space-s);
  }
`;

export const Description = styled.p`
  color: var(--grey800);
  font-size: 14px;
  margin-bottom: var(--space-base);
`;

export const TableWrapper = styled.div`
  border-radius: var(--border-radius-default);

  .column1 {
    width: 280px;
  }

  .column2 {
    width: 120px;
    min-width: 120px;
  }

  .column3 {
    width: 310px;
  }

  .column4 {
    width: 330px;
  }

  .column5 {
  }

  @media ${maxLayout.sm} {
    .column3 {
      display: none;
    }

    .column4 {
      display: none;
    }
  }
`;

export const TableText = styled.div`
  /* display: flex;
  align-items: center; */
  color: var(--grey900);
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media ${maxLayout.md} {
    max-width: 136px;
  }
  @media ${maxLayout.sm} {
    max-width: 74px;
  }
`;

export const IconWrapper = styled.div<{
  iconStyle?: "grey800" | "grey900";
  isBeforeText?: boolean;
  isClickable?: boolean;
}>`
  cursor: ${(props) => (props.isClickable ? "pointer" : "default")};
  color: ${(props) =>
    props.iconStyle ? `var(--${props.iconStyle})` : "var(--grey800)"};
  margin-top: 2px;
  margin-right: ${(props) => (props.isBeforeText ? "var(--space-s)" : 0)};
`;

export const InputWrapper = styled.div`
  width: fit-content;
  min-width: 342px;

  @media ${maxLayout.sm} {
    width: 100%;
    min-width: unset;
  }
`;

export const ModalText = styled.div`
  ${subTitleStyle}
  color: var(--grey900);
  margin-bottom: var(--space-base);
  width: 396px;
  * {
    overflow-wrap: anywhere;
  }

  @media ${maxLayout.sm} {
    width: 80vw;
  }
`;

export const ActionWrapper = styled.div`
  position: relative;

  .popItem1 {
    display: none;
  }

  .popover-container {
    right: -85px;
    margin-top: -8px;

    @media screen and (max-width: 1260px) {
      right: -17px;
    }
  }

  @media ${maxLayout.sm} {
    .popItem1 {
      display: block;
    }
  }
`;

export const DocumentationLink = styled.a`
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid transparent;
  margin-left: var(--space-xs);

  :hover {
    color: var(--primaryDarken);
    border-bottom: 1px solid var(--primaryDarken);
  }
`;

export const LinkIconWrapper = styled.span`
  margin-top: -3px;
  margin-left: var(--space-xs);
  display: inline-block;
`;
