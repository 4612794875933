import { css } from "styled-components";

import Colors from "./Colors.style";
import Spaces from "./Spaces.style";

const Variables = css`
  :root {
    ${Colors}
    ${Spaces}
    
    --border-radius-default: 3px;
    --border-radius-large: 5px;
  }
`;

export default Variables;
