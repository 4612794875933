import styled from "styled-components";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const ContentContainer = styled.div`
  width: 582px;

  @media ${maxLayout.md} {
    width: 80vw;
  }
`;

export const DescriptionModal = styled.p`
  font-size: 14px;
  color: var(--grey800);
  margin-bottom: var(--space-base);

`;

export const FormGroup = styled.div`
  margin-bottom: var(--space-m);
`;

export const FormLabel = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: var(--grey900);
  margin-bottom: var(--space-s);
`;

export const FormSubLabel = styled.p`
  font-size: 14px;
  color: var(--grey800);
  margin-bottom: var(--space-s);
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  @media ${maxLayout.md} {
    width: 100%;
  }
`;

export const ChoiceContainer = styled.div`
  > div {
    flex-flow: row;
    > div {
      :first-child {
        flex: 0 0 12px;
      }
    }
  }
`;
