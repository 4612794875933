import { useEffect, useState } from "react";
import { BaseTable } from "../../../molecules/baseTable/BaseTable";
import { BucketListData } from "../../../../pages/bucketList/BucketList";
import { TableRowObject } from "../../../atoms/tableBody/TableBody";
import { TextWithIcon } from "../../../molecules/textWithIcon/TextWithIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DomainText, TableCellContainer } from "./BucketListTable.style";
import { useAppSelector } from "../../../../store/hooks";

interface BucketListTableProps {
  filterData: Array<BucketListData>;
  spaceKey: string;
}

export const BucketListTable = (props: BucketListTableProps) => {
  const { filterData, spaceKey } = props;
  const { user } = useAppSelector((state) => state.user);
  const [tableRows, setTableRows] = useState<Array<TableRowObject>>();
  const columns = [
    {
      title: "Bucket Name",
    },
  ];

  useEffect(() => {
    const rows = filterData?.map((d: BucketListData) => ({
      redirectUrl: `/${user?.team.namespace}/spaces/${spaceKey}/bucket/${d.name}`,
      columns: [
        <TableCellContainer key={d.name}>
          <TextWithIcon
            fontSize={16}
            icon={<FontAwesomeIcon color={"#B1B9C0"} icon={["fas", "cloud"]} />}
            text={d.name}
          />
          <DomainText>{d.domainName}</DomainText>
        </TableCellContainer>,
      ],
    }));
    setTableRows(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  if (!tableRows) {
    return <></>;
  }

  return (
    <div style={{ borderRadius: "3px", overflow: "hidden" }}>
      <BaseTable columns={columns} rows={tableRows} />
    </div>
  );
};
