import { NavBar } from "../..";
import {
  HeaderWrapper,
  ServiceLabel,
  ServiceLabelContainer,
} from "../bucketList/bucketListHeader/BucketListHeader.style";
import { HeaderContainer } from "../../GlobalStyle/GlobalStyle.style";
import { useAppSelector } from "../../../store/hooks";

export const ActivateHeader = () => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <HeaderContainer>
      {user ? (
        <NavBar
          userProfile={user.displayImage.sizes.small.url}
          serviceLabel={"ByteArk Storage"}
        />
      ) : (
        <NavBar serviceLabel={"ByteArk Storage"} />
      )}
      <HeaderWrapper>
        <ServiceLabelContainer>
          <ServiceLabel>ByteArk Storage</ServiceLabel>
        </ServiceLabelContainer>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
