import { ChangeEvent, KeyboardEvent } from "react";
import { Container, Input, Label } from "./TextInput.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TextInputProps {
  /**
   * @default true
   */
  autofocus?: boolean;
  /**
   * text value.
   */
  text: string;
  /**
   * placeholder of this text input.
   */
  placeHolder?: string;
  /**
   * hint text or error message.
   */
  label?: string;
  /**
   * display warning if true.
   * @default false
   */
  error?: boolean;
  /**
   * function that triggle when user keydown.
   */
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  /**
   * function that triggle while user typing.
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * @default false
   */
  readonly?: boolean;
  /**
   * Add margin-bottom or not?
   * @default true
   */
  withMargin?: boolean;
  /**
   * disabled this input or not?
   */
  disabled?: boolean;
  /**
   * Id for testing.
   */
  testId?: string;
}

export const TextInput = (props: TextInputProps) => {
  const {
    autofocus = true,
    text,
    placeHolder = "",
    label,
    error = false,
    onChange,
    onKeyDown,
    readonly = false,
    withMargin = true,
    disabled,
    testId = "text-input",
  } = props;
  return (
    <Container withMargin={withMargin}>
      <Input
        autoFocus={autofocus}
        value={text}
        type="text"
        placeholder={placeHolder}
        error={error}
        onChange={onChange}
        onKeyDown={onKeyDown}
        readOnly={readonly}
        disabled={disabled}
        data-cy={testId}
        ref={(input) => input && autofocus && input.focus()}
      />
      {label && (
        <Label error={error}>
          {error && (
            <span>
              <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />{" "}
            </span>
          )}
          {label}
        </Label>
      )}
    </Container>
  );
};
