import styled from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";
import { subTitleStyle } from "../../GlobalStyle/GlobalStyle.style";

export const Text = styled.p`
  ${subTitleStyle};
  color: var(--grey900);
  margin-bottom: var(--space-base);
  width: 396px;

  @media ${maxLayout.sm} {
    width: 80vw;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    flex: 0 0 48%;
  }
`;
