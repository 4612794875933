import {
  CardContainer,
  CardFooter,
  CardLabel,
  CardMenuButton, CardMenuContainer,
  CardSubtitle,
  CardTitle,
} from "./Card.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverItemObject } from "../popover/Popover";
import { MouseEvent } from "react";

export interface CardProps {
  /** A card title */
  title: string;
  /** A small text above the title */
  label?: string;
  /** A small text below the title */
  subtitle?: string;
  /** The most bottom area of the card */
  footer?: string | JSX.Element;
  /** A link to open when clicking the card */
  redirectUrl: string;
  /** An array of popover items */
  popoverItems?: Array<PopoverItemObject>;
  /** The last time of popover items if it should be separated from other popover items */
  popoverItemsBottom?: PopoverItemObject;
  /** Popover showing state */
  isPopoverShowing?: boolean;
  /** A function to execute when clicking the ... menu on the top-right */
  onToggleMenu?: () => void;
}

export const Card = (props: CardProps) => {
  const {
    title,
    subtitle,
    label,
    footer,
    redirectUrl,
    popoverItems,
    popoverItemsBottom,
    isPopoverShowing = false,
    onToggleMenu,
  } = props;

  const onClickMenu = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (onToggleMenu) {
      onToggleMenu();
    }
  };

  const onDismissPopover = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (onToggleMenu) {
      onToggleMenu();
    }
  }

  return (
    <CardContainer
      to={redirectUrl}>
      {
        label && (
          <CardLabel>{ label }</CardLabel>
        )
      }
      <CardTitle>{ title }</CardTitle>
      {
        popoverItems && (
          <CardMenuContainer>
            <CardMenuButton
              onClick={(e) => onClickMenu(e)}>
              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
            </CardMenuButton>
            <Popover
              isShowing={isPopoverShowing}
              items={popoverItems}
              bottomAction={popoverItemsBottom}
              onDismiss={(e) => onDismissPopover(e)} />
          </CardMenuContainer>
        )
      }
      {
        subtitle && (
          <CardSubtitle>{ subtitle }</CardSubtitle>
        )
      }
      {
        footer && (
          <CardFooter>{ footer }</CardFooter>
        )
      }
    </CardContainer>
  );
};
