import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableRowObject } from "../../components/atoms/tableBody/TableBody";
import { CancelTokenSource } from "axios";

export interface UploadStatusPayloadProps {
  cancelTokenSource?: CancelTokenSource | null,
  cancelTokenSourceList?: Array<CancelTokenSource>,
  currentChunk?: number,
  errorMessage?: string,
  uploadId?: string,
  status?: "success" | "uploading" | "error" | "cancel",
  uploadProgress?: number,
}

export interface UploadStatusProps {
  cancelTokenSource: CancelTokenSource | null,
  cancelTokenSourceList?: Array<CancelTokenSource>,
  currentChunk?: number,
  errorMessage?: string,
  file: File,
  originalFilename: string,
  uploadId?: string,
  status: "success" | "uploading" | "error" | "cancel",
  uploadPath: string,
  uploadProgress: number,
}

interface UploadReducerProps {
  cancelCount: number,
  errorCount: number,
  failedList: Array<File>,
  fileList: Array<File>,
  successCount: number,
  uploadStatus: Array<UploadStatusProps>,
  uploadTableRows: Array<TableRowObject> | null,
  uploadPermission: "PUBLIC" | "PRIVATE",
}

const initialState: UploadReducerProps = {
  cancelCount: 0,
  errorCount: 0,
  failedList: [],
  fileList: [],
  successCount: 0,
  uploadStatus: [],
  uploadTableRows: [],
  uploadPermission: "PRIVATE",
};

export const uploadSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFileList: (state, action: PayloadAction<Array<File>>) => {
      state.fileList = action.payload;
    },
    appendFileList: (state, action: PayloadAction<Array<File>>) => {
      action.payload.forEach((item) => {
        // Check if the file is already existed.
        const existingIndex = state.fileList.findIndex((f: File) => f.name === item.name);
        if (existingIndex === -1) {
          state.fileList.push(item);
        }
      });
    },
    setUploadTableRows: (state, action: PayloadAction<Array<TableRowObject>>) => {
      state.uploadTableRows = action.payload;
    },
    resetFileList: (state) => {
      state.fileList = [];
    },
    resetUploadState: (state) => {
      Object.assign(state, initialState);
    },
    appendFailedList: (state, action: PayloadAction<File>) => {
      state.failedList.push(action.payload);
    },
    appendUploadStatus: (state, action: PayloadAction<UploadStatusProps>) => {
      state.uploadStatus.push(action.payload);
    },
    updateUploadStatus: (state, action: PayloadAction<{filename: string, payload: UploadStatusPayloadProps}>) => {
      const { filename, payload } = action.payload;
      const existingIndex = state.uploadStatus.findIndex((o) => o.file.name === filename);
      if (existingIndex !== -1) {
        state.uploadStatus[existingIndex] = {
          ...state.uploadStatus[existingIndex],
          ...payload,
        };
      }
    },
    increaseUploadSuccessCount: (state) => {
      state.successCount += 1;
    },
    increaseUploadErrorCount: (state) => {
      state.errorCount += 1;
    },
    increaseCancelCount: (state) => {
      state.cancelCount += 1;
    },
    decreaseCancelCount: (state) => {
      state.cancelCount -= 1;
    },
    decreaseUploadErrorCount: (state) => {
      state.errorCount -= 1;
    },
    appendCancelTokenSourceList: (state, action: PayloadAction<{filename: string, payload: CancelTokenSource}>) => {
      const { filename, payload } = action.payload;
      const existingIndex = state.uploadStatus.findIndex((o) => o.file.name === filename);
      if (existingIndex !== -1) {
        const tl = state.uploadStatus[existingIndex].cancelTokenSourceList;
        if (!tl) {
          state.uploadStatus[existingIndex].cancelTokenSourceList = [payload];
        } else {
          state.uploadStatus[existingIndex].cancelTokenSourceList = [
            ...tl,
            payload,
          ];
        }
      }
    },
    setUploadPermission: (state, action: PayloadAction<"PUBLIC" | "PRIVATE">) => {
      state.uploadPermission = action.payload;
    },
  },
});

export const {
  appendFailedList,
  appendFileList,
  appendUploadStatus,
  appendCancelTokenSourceList,
  increaseCancelCount,
  increaseUploadErrorCount,
  increaseUploadSuccessCount,
  setFileList,
  setUploadTableRows,
  resetUploadState,
  updateUploadStatus,
  decreaseCancelCount,
  decreaseUploadErrorCount,
  setUploadPermission,
  resetFileList,
} = uploadSlice.actions;

export default uploadSlice.reducer;
