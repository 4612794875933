import styled, { css } from "styled-components";

import { Button } from "../button/Button";

export const DropdownWrapper = styled.div`
  border-radius: var(--border-radius-large);
  :hover {
    background: var(--overlay);
  }
`;

export const DropdownButton = styled(Button)`
  border-color: transparent;
  background: transparent;
  color: var(--grey600);
  height: fit-content;
  :hover {
    background: transparent;
    border-color: transparent;
  }
`;

export const DropdownOverlay = styled.div<{ isActive?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: ${(props) => (props.isActive ? 12 : -10)};
`;

export const DropdownOptionsContainer = styled.div<{
  isActive?: boolean;
  popoverPosition?: "top" | "bottom";
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 13;
  border-radius: var(--border-radius-default);
  padding: 1px;
  min-width: 150px;
  width: max-content;
  right: 0;
  bottom: ${(props) => (props.popoverPosition === "top" ? "44px" : "")};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  background: var(--white);
  border: 1px solid var(--grey300);

  ${(props) => {
    if (props.isActive) {
      return css`
        pointer-events: auto;
        opacity: 1;
        transform: translateY(4px);
      `;
    } else {
      return css`
        pointer-events: none;
        opacity: 0;
        transition: all 0.25s ease-out;
        transform: translateY(10px);
        visibility: hidden;
      `;
    }
  }}
`;

export const DropdownOption = styled.div<{ isSelected?: boolean }>`
  font-size: 14px;
  padding: var(--space-s) 10px;
  height: 30px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.isSelected ? "var(--grey900)" : "var(--grey800)")};
  background: ${(props) => (props.isSelected ? "var(--grey400)" : "var(--white)")};
  :hover {
    background: var(--grey200);
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div`
  width: var(--space-base);
  margin-right: var(--space-s);
`;
