import { ReactChild, useEffect } from "react";
import {
  Container,
  ChildrenWrapper,
  ChildrenContainer,
  Background,
  HeaderContainer,
  Title,
  IconWrapper,
} from "./Modal.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ModalProps {
  /**
   * Title of this card.
   */
  title: string;
  /**
   * React Component that was render on Modal.
   */
  children: ReactChild | ReactChild[];
  /**
   * Display Modal if true
   * @default false
   */
  isOpen: boolean;
  /**
   * function that triggle when click modal background.
   */
  onClose?: () => void;
}

export const Modal = (props: ModalProps) => {
  const { title, isOpen = false, children, onClose } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <Container isOpen={isOpen}>
      <Background onClick={onClose} />
      <ChildrenWrapper>
        <ChildrenContainer className="modal-card">
          <HeaderContainer>
            <Title>{title}</Title>
            <IconWrapper onClick={onClose}>
              <FontAwesomeIcon icon={["fas", "times"]} />
            </IconWrapper>
          </HeaderContainer>
          {children}
        </ChildrenContainer>
      </ChildrenWrapper>
    </Container>
  );
};
