import { MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import {
  MenuSeparator,
  PopoverArrow,
  PopoverContainer,
  PopoverItem,
  PopoverItemsWrapper, PopoverOverlay,
} from "./Popover.style";

export interface BottomActionObject extends PopoverItemObject {
  /**
   * Font color
   * @default Normal text color */
  color?: string,
}

export interface PopoverItemObject {
  title: string,
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void,
  redirectUrl?: string,
}

interface PopoverProps {
  /**
   * The last button of the popover, separated by a thin grey line.
   * If all items will not be separated, this bottomAction is not necessary.
   * */
  bottomAction?: BottomActionObject,
  /**
   * A showing state of the popover.
   */
  isShowing: boolean,
  /**
   * An array of popover items.
   */
  items: Array<PopoverItemObject>,
  /**
   * A function to execute when clicking outside the popover.
   */
  onDismiss: (e: MouseEvent<HTMLElement>) => void,
  /**
   * @default "bottom"
   */
  position?: "bottom" | "top",
}

export const Popover = (props: PopoverProps) => {
  const {
    bottomAction,
    isShowing,
    items,
    onDismiss,
    position = "bottom",
  } = props;
  const history = useHistory();

  const onClickItem = (e: MouseEvent<HTMLButtonElement>, item: PopoverItemObject) => {
    const { redirectUrl, onClick } = item;

    if (redirectUrl) {
      history.push(redirectUrl);
    }

    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  if (!isShowing) {
    return (<></>);
  }

  return (
    <PopoverContainer
      className={'popover-container'}
      position={position}
      isEmptyMenu={items.length === 0}>
      <PopoverArrow position={position} />
      <PopoverItemsWrapper
        withBottomAction={Boolean(bottomAction)}>
        {
          items.map((item, index) => (
            <PopoverItem
              key={`popoverItem${index}`}
              className={`popItem${index + 1}`}
              onClick={(e) => onClickItem(e, item)}>
              { item.title }
            </PopoverItem>
          ))
        }
        {
          bottomAction && (
            <>
              <MenuSeparator />
              <PopoverItem
                color={bottomAction.color}
                onClick={(e) => onClickItem(e, bottomAction)}>
                { bottomAction.title }
              </PopoverItem>
            </>
          )
        }
      </PopoverItemsWrapper>
      <PopoverOverlay
        onClick={onDismiss} />
    </PopoverContainer>
  );
};
