import { ReactNode, useState } from "react";

import {
  DropdownWrapper,
  DropdownButton,
  DropdownOverlay,
  DropdownOptionsContainer,
  DropdownOption,
  IconWrapper,
} from "./Dropdown.style";

export interface DropdownOptionProps {
  /**
   * Dropdown option's label
   */
  label: string;
  /**
   * Called when clicked a dropdown option
   */
  onClick: (value?: any) => void;
  /**
   * Icon that will show in front of the label
   */
  icon?: ReactNode;
}

interface DropdownProps {
  /**
   * Dropdown label
   */
  children: ReactNode;
  /**
   * Array of option
   */
  options: DropdownOptionProps[];
  /**
   * Set dropdown to disabled style
   */
  disabled?: boolean;
  /**
   * Set dropdown to have full width
   */
  fullWidth?: boolean;
  /**
   * Set position of popover
   * @default bottom
   */
  popoverPosition?: "top" | "bottom";
}

export const Dropdown = (props: DropdownProps) => {
  const { children, options, popoverPosition, ...DropdownProps } = props;
  const [visible, setVisible] = useState(false);

  return (
    <DropdownWrapper>
      <DropdownButton onClick={() => setVisible(true)} isIconButton {...DropdownProps}>
        {children}
      </DropdownButton>
      <DropdownOverlay isActive={visible} onClick={() => setVisible(false)} />
      <DropdownOptionsContainer isActive={visible} popoverPosition={popoverPosition}>
        {options.map((option, index) => (
          <DropdownOption
            key={`option-${index}`}
            onClick={() => {
              option.onClick();
              setVisible(false);
            }}
          >
            {option.icon && <IconWrapper>{option.icon}</IconWrapper>}
            {option.label}
          </DropdownOption>
        ))}
      </DropdownOptionsContainer>
    </DropdownWrapper>
  );
};
