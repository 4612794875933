import styled, { css } from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--white);
  box-sizing: border-box;
`;

export const Tags = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--white);
  border-radius: var(--border-radius-default);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: var(--space-xs) 0px;
  width: fit-content;
`;

export const TagInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WarningMessage = styled.div<{ error?: boolean }>``;

export const TagLabel = styled.input<{ error?: boolean }>`
  margin-right: var(--space-base);
  font-size: 14px;
  color: var(--grey900);
  width: 319px;
  overflow-wrap: break-word;
  border: 1px solid var(--grey400);
  border-radius: var(--border-radius-default);
  padding: var(--space-s);
  height: 36px;

  ${(props) =>
    props.error &&
    css`
      border-color: var(--red) !important;
      :focus {
        outline: none !important;
        border: 1px solid var(--red);
      }
    `}

  @media ${maxLayout.md} {
    max-width: 300px;
    width: 70vw;
  }
`;

export const IconWarpper = styled.div`
  color: var(--grey800);
  font-size: 12px;
  cursor: pointer;
  :hover {
    color: var(--red);
  }
`;

export const Label = styled.p<{ error?: boolean }>`
  color: var(--grey700);
  font-size: 12px;
  margin-top: var(--space-s);

  ${(props) =>
    props.error &&
    css`
      color: var(--red);
    `}
`;

export const AddingButton = styled.button`
  display: flex;
  flex-direction: row;
  color: var(--grey800);
  border: none;
  background: none;
  cursor: pointer;
  margin-top: var(--space-s);
  pointer-events: all;
  padding: 0;
`;

export const AddIconWarpper = styled.div`
  font-size: 12px;
  padding-right: var(--space-s);
`;

export const AddTextWarpper = styled.div`
  font-size: 14px;
`;
