import styled from "styled-components";

export const BaseErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;

  width: 100%;
  background-color: var(--white);
  text-align: center;
  border-radius: var(--border-radius-default);


  padding-top: var(--space-xxl);
  padding-right: var(--space-m);
  padding-bottom: var(--space-xxl);
  padding-left: var(--space-m);
`;

export const ErrorTitle = styled.h2`
  color: var(--grey800);
  text-transform: capitalize;
  font-size: 16px;
  margin-top: var(--space-m);
  margin-bottom: var(--space-s);
`;

export const ErrorDescription = styled.p`
  color: var(--grey800);
  font-size: 14px;
  margin-bottom: var(--space-l);
  white-space: break-spaces;
  word-break: break-all;
`;

export const ButtonContainer = styled.div`
  svg {
    margin-right: var(--space-xs);
  }
`;

export const ButtonText = styled.div`
  padding: var(--space-s);
`;
