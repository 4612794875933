import {
  AddRowContainer,
  DeleteButton,
  ErrorMessage,
  ModalFooter,
  ModalManageMetadataContainer,
  TableContainer,
  ButtonContainer,
} from "./ModalManageMetadata.style";
import {
  BaseTable,
  Button,
  Modal,
  TextInput,
  Toast,
} from "../../..";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  dismissPopover,
  setIsMetadataModalShowing,
} from "../../../../store/reducers/files";
import {
  ColumnProps,
  TableHeader,
} from "../../../atoms/tableHeader/TableHeader";
import { useEffect, useState, ChangeEvent, KeyboardEvent } from "react";
import {
  getFileMetadata,
  MetadataProps,
  updateFileMetadata,
} from "../../../../services/apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingPlaceholder } from "../../../atoms/loadingPlaceholder/LoadingPlaceholder";
import { AxiosError } from "axios";
import { v4 as uuid } from "uuid";
import { addToast, removeToast } from "../../../../store/reducers/toasts";
import {
  validateLowercase,
  validateMetaDataKey,
  validateStartAndEndWithChar,
} from "../../../../utils/validate";

export const ModalManageMetadata = () => {
  const dispatch = useAppDispatch();
  const { bucketName, deleteTarget, isMetadataModalShowing } = useAppSelector(
    (state) => state.files
  );
  const { currentSpace } = useAppSelector((state) => state.spaces);

  const path = deleteTarget?.path || "";

  const [userMetadata, setUserMetadata] = useState<Array<MetadataProps>>([
    { key: "", value: "" },
  ]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isReadyToSave, setIsReadyToSave] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<Array<any>>([]);

  const columns: Array<ColumnProps> = [
    {
      title: "Key",
      size: "md",
    },
    {
      title: "Value",
      size: "md",
    },
    {
      title: "",
      size: "xs",
    },
  ];

  const onChangeKey = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const target = event.target as HTMLInputElement;

    let preparedUserMetadata: Array<MetadataProps> = userMetadata;
    let currentItem = userMetadata[index];
    currentItem.key = target.value;
    setIsReadyToSave(
      Boolean(currentItem.value.length && currentItem.key.length)
    );
    preparedUserMetadata[index] = currentItem;

    setUserMetadata(preparedUserMetadata);
    mapTableRows();
  };

  const onChangeValue = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const target = event.target as HTMLInputElement;

    let preparedUserMetadata: Array<MetadataProps> = userMetadata;
    let currentItem = userMetadata[index];
    currentItem.value = target.value;
    setIsReadyToSave(
      Boolean(currentItem.value.length && currentItem.key.length)
    );
    preparedUserMetadata[index] = currentItem;

    setUserMetadata(preparedUserMetadata);
    mapTableRows();
  };

  const onKeydownValue = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSave();
    }
  };

  const onDeleteRow = (userMetadata: Array<MetadataProps>, index: number) => {
    const filtered = userMetadata.filter(
      (meta, metaIndex) => metaIndex !== index
    );
    setUserMetadata(filtered);
    setIsReadyToSave(Boolean(userMetadata.length));
    mapTableRows(filtered);
  };

  const mapTableRows = (filtered?: Array<MetadataProps>) => {
    const baseArray = filtered ? filtered : userMetadata;
    const rows = baseArray.map((item, index) => {
      let error =
        ((!validateStartAndEndWithChar(userMetadata[index].key) ||
          !validateMetaDataKey(userMetadata[index].key)) &&
          userMetadata[index].key.length > 1) ||
        (userMetadata[index].key.length === 1 &&
          !validateLowercase(userMetadata[index].key));
      if (error) {
        setIsReadyToSave(false);
      }
      return {
        columns: [
          <TextInput
            placeHolder={"Enter Key (e.g. metadata-key)"}
            text={item.key}
            onChange={(event) => onChangeKey(event, index)}
            autofocus={false}
            withMargin={false}
            error={error}
            label={
              userMetadata[index].key.length <= 1 &&
              validateLowercase(userMetadata[index].key)
                ? ""
                : !validateMetaDataKey(userMetadata[index].key)
                ? "Metadata key can consist only of lowercase letters, numbers, and hyphens (-)."
                : !validateStartAndEndWithChar(userMetadata[index].key)
                ? "Metadata key must start and end with lowercase letters."
                : ""
            }
          />,
          <TextInput
            placeHolder={"Enter Value"}
            text={item.value}
            onChange={(event) => onChangeValue(event, index)}
            onKeyDown={(event) => onKeydownValue(event)}
            autofocus={false}
            withMargin={false}
          />,
          <DeleteButton onClick={() => onDeleteRow(baseArray, index)}>
            <FontAwesomeIcon icon={["fas", "trash-alt"]} />
          </DeleteButton>,
        ],
      };
    });
    setTableRows(rows);
  };

  const onCloseModal = () => {
    dispatch(setIsMetadataModalShowing(false));
    setUserMetadata([{ key: "", value: "" }]);
  };

  const onAddRow = (userMetadata: Array<MetadataProps>) => {
    let preparedUserMetadata = userMetadata;
    preparedUserMetadata.push({ key: "", value: "" });
    setUserMetadata(preparedUserMetadata);
    mapTableRows();
  };

  const onSave = async () => {
    const payload = { spaceKey: currentSpace, bucketName, userMetadata };
    setIsReadyToSave(false);
    const id = uuid();

    try {
      const response = await updateFileMetadata(payload, { path });

      if (response.status === 204) {
        dispatch(setIsMetadataModalShowing(false));
        setUserMetadata([{ key: "", value: "" }]);
        setIsSaved(true);
        dispatch(
          addToast({
            id,
            status: "success",
            title: "Metadata Updated",
            description: "Metadata has been updated.",
          })
        );

        const timer = setTimeout(() => {
          setIsSaved(false);
          dispatch(removeToast(id));
          clearTimeout(timer);
        }, 5000);
      }
    } catch (error) {
      dispatch(
        addToast({
          id,
          status: "error",
          title: "Update Metadata Failed",
          description: "Metadata couldn’t be updated. Please try again.",
        })
      );

      const timer = setTimeout(() => {
        setIsSaved(false);
        dispatch(removeToast(id));
        clearTimeout(timer);
      }, 5000);
    }
  };

  useEffect(() => {
    const defaultArray = [{ key: "", value: "" }];
    setIsSaved(false);
    setIsReadyToSave(false);
    setIsLoading(true);
    setUserMetadata(defaultArray);
    setErrorMessage("");

    if (!deleteTarget) {
      return;
    }

    const loadData = async () => {
      try {
        const response = await getFileMetadata(currentSpace, bucketName, {
          path,
        });
        const { userMetadata } = response.data;
        if (userMetadata.length > 0) {
          setUserMetadata(userMetadata);
          mapTableRows(userMetadata);
        } else {
          setUserMetadata(defaultArray);
          mapTableRows(defaultArray);
        }
        dispatch(dismissPopover());
        setIsLoading(false);
      } catch (error) {
        const e = error as AxiosError;
        let errorMessage: string;

        switch (e.response?.data.errorCode) {
          case "invalidRequest":
            errorMessage = "Invalid Request. Please try again";
            break;
          case "noSuchObject":
            errorMessage = "File Not Found.";
            break;
          case "internalServerError":
            errorMessage =
              "There was an error while loading metadata. Please try again.";
            break;
          default:
            errorMessage =
              "There was an error while loading metadata. Please try again.";
        }
        setErrorMessage(errorMessage);
        setIsLoading(false);
      }
    };

    if (isMetadataModalShowing) {
      loadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTarget]);

  return (
    <>
      <Modal
        title={"Manage Metadata"}
        onClose={onCloseModal}
        isOpen={isMetadataModalShowing}
      >
        <ModalManageMetadataContainer>
          <TableContainer>
            {Boolean(isLoading) ? (
              <>
                <TableHeader columns={columns} roundedBorder={false} />
                <LoadingPlaceholder width={"100%"} height={"51px"} />
              </>
            ) : (
              <>
                <BaseTable
                  isAlwaysShowHeader={true}
                  columns={columns}
                  rows={tableRows}
                />
                <AddRowContainer onClick={() => onAddRow(userMetadata)}>
                  <FontAwesomeIcon icon={["fas", "plus"]} /> Add metadata
                </AddRowContainer>
              </>
            )}
          </TableContainer>
          <ModalFooter>
            {Boolean(errorMessage.length) && (
              <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
            <ButtonContainer>
              <Button
                disable={!isReadyToSave}
                onClick={onSave}
                buttonStyle={"primary"}
                fullWidth
              >
                Save
              </Button>
            </ButtonContainer>
          </ModalFooter>
        </ModalManageMetadataContainer>
      </Modal>
      {Boolean(isSaved) && !Boolean(errorMessage.length) && (
        <Toast
          title="Metadata Updated"
          description={"Metadata have been updated."}
          onClick={() => setIsSaved(false)}
        />
      )}
      {Boolean(isSaved) && Boolean(errorMessage.length) && (
        <Toast
          title="Update Metadata Failed"
          description={"Metadata couldn’t be updated. Please try again."}
          onClick={() => setIsSaved(false)}
        />
      )}
    </>
  );
};
