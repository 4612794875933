import styled from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: var(--space-s) 12px var(--space-m) 12px;
  @media ${maxLayout.md} {
    padding: 0 0 var(--space-m) 0;
    align-items: center;
  };
`;
export const NotificationTextWarpper = styled.div``;
export const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--grey900);
`;
export const Description = styled.div`
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--grey800);
`;
export const NotificationButtonWarpper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 var(--space-s);
`;
export const ConfirmButton = styled.div`
  width: 44px;
  padding-right: var(--space-s);
`;
export const CancelButton = styled.div`
  width: 36px;
`;
