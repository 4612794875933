import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactChild } from "react";
import { v4 as uuid } from "uuid";

export type ToastProps = {
  id?: string;
  status: "success" | "error";
  title: string;
  description?: string | ReactChild;
};

export interface ToastsReducerState {
  toasts: Array<ToastProps>;
}

const initialState: ToastsReducerState = {
  toasts: [],
};

export const toastsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastProps>) => {
      const data = action.payload.id
        ? action.payload
        : { ...action.payload, id: uuid() };
      state.toasts.push(data);
    },
    removeToast: (state, action: PayloadAction<string>) => {
      const newData = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );
      state.toasts = newData;
    },
  },
});

export const { addToast, removeToast } = toastsSlice.actions;

export default toastsSlice.reducer;
