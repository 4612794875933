import styled from "styled-components";
import { TableCellStyles } from "../../molecules/baseTable/BaseTable.style";

export const TableBodyContainer = styled.div``;

export const TableRow = styled.div<{ isRowActive: boolean }>`
  position: relative;
  background-color: ${(props) => props.isRowActive ? 'var(--grey200)' : 'var(--white)'};
  border-bottom: 1px solid var(--grey400);
  transition: background-color 250ms ease;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  :hover {
    background-color: var(--grey200);
  }

  :last-child {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
`;

export const TableCell = styled.div<{ withLink: boolean }>`
  ${TableCellStyles};
  cursor: ${(props) => props.withLink ? 'pointer' : 'auto'};
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
`;
