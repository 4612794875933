import styled, { css } from "styled-components";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const PopupUploadProgressContainer = styled.div`
  background-color: white;
  border-radius: var(--border-radius-default);
  width: 380px;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  
  @media ${maxLayout.sm} {
    width: auto;
    bottom: 1rem;
    right: 1rem;
  }
`;

export const PopupHeader = styled.div`
  background-color: var(--navy);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HeaderTitle = styled.div`
  padding: var(--space-base);
  flex: 1 0 auto;
`;

export const HeaderActions = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
`;

export const ListGroupContainer = styled.div<{ isCollapsing: boolean }>`
  max-height: ${(props) => props.isCollapsing ? '0' : '250px'};
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  transition: max-height 450ms cubic-bezier(0.41, 0.18, 0.12, 0.98);

  ::-webkit-scrollbar-track
  {
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar
  {
    width: 6px;
    height: 8px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: var(--grey600);
  }
`;

export const ButtonStyles = css`
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  height: 100%;
  padding: var(--space-base);
  transition: background-color 150ms ease;
`;

export const CollapseButton = styled.button`
  ${ButtonStyles};

  &:hover {
    background-color: var(--overlayHover);
  }
`;

export const CloseButton = styled.button`
  ${ButtonStyles};

  &:hover {
    background-color: var(--red);
    border-top-right-radius: var(--border-radius);
  }
`;
