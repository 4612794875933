import { ColumnProps, TableHeader } from "../../../atoms/tableHeader/TableHeader";
import { FakeTableRow, LoadingContainer } from "./FileListLoadingSection.style";
import { LoadingPlaceholder } from "../../../atoms/loadingPlaceholder/LoadingPlaceholder";

interface FileListLoadingSectionProps {
  headerColumns: Array<ColumnProps>,
}

export const FileListLoadingSection = (props: FileListLoadingSectionProps) => {
  const { headerColumns } = props;
  return (
    <LoadingContainer>
      <TableHeader
        columns={headerColumns}
        roundedBorder={false} />
      <FakeTableRow>
        <LoadingPlaceholder width={'70%'} height={27} />
      </FakeTableRow>
      <FakeTableRow>
        <LoadingPlaceholder width={'70%'} height={27} />
      </FakeTableRow>
      <FakeTableRow>
        <LoadingPlaceholder width={'70%'} height={27} />
      </FakeTableRow>
    </LoadingContainer>
  );
};
