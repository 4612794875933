import _ from "lodash";
import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { RouteComponentProps } from "react-router";

import { Button, Checkbox } from "../../components";
import { Container } from "../../components/GlobalStyle/GlobalStyle.style";
import {
  ButtonContainer,
  Content,
  PageContainer,
  SearchBoxWrapper,
  TableWrapper,
  TableContainer,
  ToolsContainer,
  LoadMoreButtonContainer,
} from "./FileList.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  loadObjects,
  deleteObject,
  deleteFolder,
  deleteManyFiles,
  getBucket,
} from "../../services/apis";
import { ErrorMessage } from "../../components/organisms/messages/ErrorMessage";
import { EmptyMessage } from "../../components/organisms/messages/EmptyMessage";
import { Breadcrumb } from "../../components/atoms/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
import { SearchBox } from "../../components/atoms/searchBox/SearchBox";
import { SearchResultContainer } from "../bucketList/BucketList.style";
import { NoResultMessage } from "../../components/organisms/messages/NoResultMessage";
import { DeleteModal } from "../../components/organisms/deleteModal/DeleteModal";
import { ColumnProps } from "../../components/atoms/tableHeader/TableHeader";
import { FileListSidebar } from "../../components/organisms/fileList/fileListSidebar/FileListSidebar";
import { FileListLoadingSection } from "../../components/organisms/fileList/fileListLoadingSection/FileListLoadingSection";
import { FileListHeader } from "../../components/organisms/fileList/fileListHeader/FileListHeader";
import { ModalNewFolder } from "../../components/organisms/fileList/modalNewFolder/ModalNewFolder";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import {
  dismissDeleteModal,
  resetFolderState,
  setContinuousToken,
  setCurrentFilePath,
  setRequestUrl,
  setTableState,
  setIsFolderModalShowing,
  setBucketName,
  setSearchValue,
  setData,
  setTableColumns,
  setIsUploadModalShowing,
  setDeleteTarget,
  showDeleteManyModal,
  dismissDeleteManyModal,
  selectAllFiles,
  clearSelectTarget,
  dismissPopoverAction,
  setDomainName,
  setIsBulkPopoverShowing,
  setIsMultiplePermissionModalShowing,
} from "../../store/reducers/files";
import { FileListTable } from "../../components/organisms/fileList/fileListTable/FileListTable";
import { UploadModal } from "../../components/organisms/fileList/modalUpload/ModalUpload";
import { PopupUploadProgress } from "../../components/organisms/fileList/popupUploadProgress/PopupUploadProgress";
import { ModalManageMetadata } from "../../components/organisms/fileList/modalManageMetadata/ModalManageMetadata";
import { ModalManagePermissions } from "../../components/organisms/fileList/modalManagePermissions/ModalManagePermissions";
import { PageNotFound } from "../../errors/PageNotFound";
import { PageNoPermission } from "../../errors/PageNoPermission";
import { Popover } from "../../components/atoms/popover/Popover";
import { ModalManagePermissionsMultiple } from "../../components/organisms/fileList/modalManagePermissions/ModalManagePermissionMultiple";
import { setCurrentSpace } from "../../store/reducers/spaces";
import {
  resetLoadingState,
  setLoadingState,
} from "../../store/reducers/loading";
import { AxiosError } from "axios";
import { v4 as uuid } from "uuid";
import { addToast, removeToast, ToastProps } from "../../store/reducers/toasts";

import { ModalText } from "../../components/atoms/modal/Modal.style";

interface FileListErrorResponse extends Response {
  data: {
    errorCode: string;
  };
}

interface FileListError extends Error {
  response: FileListErrorResponse;
}

interface MatchParams {
  teamname?: string;
  spaceKey: string;
  bucketName: string;
}

interface FileListProps extends RouteComponentProps<MatchParams> {}

export const FileList = (props: FileListProps) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const bucketName = props.match.params.bucketName;
  const spaceKey = props.match.params.spaceKey;
  const currentPath = new URLSearchParams(search).get("path") || "";

  const [headerItems, setHeaderItems] = useState<Array<ColumnProps>>();

  const onCancelNewFolder = () => {
    dispatch(resetFolderState());
  };

  const {
    continuousToken,
    data,
    deleteTarget,
    error,
    isContinuousLoading,
    isCreatingFolder,
    isDeleteModalShowing,
    isDeleteManyModalShowing,
    isLoading,
    requestUrl,
    searchValue,
    shouldShowLoadMore,
    tableRows,
    targetFile,
    selectTarget,
    domainName,
    isBulkPopoverShowing,
  } = useAppSelector((state) => state.files);

  const handleDelete = async () => {
    if (!deleteTarget) {
      return;
    }

    let response;
    const { type, path } = deleteTarget;

    dispatch(setLoadingState());

    const id = uuid();
    let toastData: ToastProps;

    try {
      if (type === "folder") {
        response = await deleteFolder(spaceKey, bucketName, { path });
      } else {
        response = await deleteObject(spaceKey, bucketName, { path });
      }

      if (response.status === 200) {
        await loadData();
        dispatch(dismissDeleteModal());
        dispatch(setDeleteTarget(null));
        dispatch(setCurrentFilePath(""));
      }
      const fileName = path.slice(path.lastIndexOf("/") + 1);
      toastData = {
        id,
        status: "success",
        title: type === "folder" ? "Folder Deleted" : "File Deleted",
        description:
          type === "folder"
            ? `Folder "${fileName}" has been deleted.`
            : `File "${fileName}" has been deleted.`,
      };
    } catch (err: any) {
      const e = err as AxiosError;
      const errorData = e.response?.data.errorCode;
      let errorMessage: string;

      switch (errorData) {
        case "authenticationRequired":
          errorMessage = "Please login before delete file.";
          break;
        case "forbidden":
          errorMessage = "File couldn’t be deleted due to a permission issue. Please try again.";
          break;
        case "resourceNotFound":
          errorMessage = "Bucket not found.";
          break;
        case "internalServerError":
          errorMessage =
            "There was an error while deleting file. Please try again.";
          break;
        default:
          errorMessage = "File couldn’t be deleted. Please try again.";
      }
      toastData = {
        id,
        status: "error",
        title: "Delete File Failed",
        description: errorMessage,
      };
    }
    dispatch(addToast(toastData));

    let timer = setTimeout(() => {
      dispatch(removeToast(id));
      clearTimeout(timer);
    }, 5000);

    dispatch(resetLoadingState());
  };

  const handleDeleteMany = async () => {
    if (!selectTarget) {
      return;
    }
    dispatch(setLoadingState());

    const id = uuid();
    let toastData: ToastProps;

    try {
      const data = selectTarget.map((item) => {
        return { path: item, type: "file" };
      });

      await deleteManyFiles(spaceKey, bucketName, data);

      await loadData();
      dispatch(dismissDeleteManyModal());
      dispatch(dismissPopoverAction());
      dispatch(clearSelectTarget());

      toastData = {
        id,
        status: "success",
        title: `${selectTarget.length} Files Deleted`,
        description: `${selectTarget.length} files have been deleted.`,
      };
    } catch (err: any) {
      toastData = {
        id,
        status: "error",
        title: `Failed To Delete ${selectTarget.length} Files`,
        description: `${selectTarget.length} files couldn't be deleted. Please try again.`,
      };
    }
    dispatch(setIsBulkPopoverShowing(false));
    dispatch(addToast(toastData));

    let timer = setTimeout(() => {
      dispatch(removeToast(id));
      clearTimeout(timer);
    }, 5000);

    dispatch(resetLoadingState());
  };

  const mapHeaderItems = () => {
    const columns: Array<ColumnProps> = [
      {
        title: (
          <Checkbox
            checked={isSelectedAllFiles()}
            onClick={(e: MouseEvent<HTMLElement>) => onClickAllCheckbox(e)}
          />
        ),
        size: "xs",
      },
      {
        title: "Name",
        size: "lg",
      },
      {
        title: "Last Modified",
        size: "md",
      },
      {
        title: "Size",
        size: "sm",
      },
      {
        title: "",
        size: "xs",
      },
    ];
    setHeaderItems(columns);
  };

  const isSelectedAllFiles = () => {
    const filesLength = _.countBy(data, "type");
    return selectTarget.length === filesLength.file;
  };

  const onClickAllCheckbox = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (isSelectedAllFiles()) {
      dispatch(clearSelectTarget());
    } else {
      dispatch(selectAllFiles());
    }
  };

  const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const targetUrl = `${currentPath}${searchValue}`;

    if (event.key === "Enter") {
      dispatch(setRequestUrl(targetUrl));
      dispatch(setCurrentFilePath(""));
      loadData(false, targetUrl);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchValue(event.target.value));

    if (event.target.value === "") {
      handleClearSearch();
    }
  };

  const handleClearSearch = () => {
    dispatch(setSearchValue(""));
    dispatch(setCurrentFilePath(""));
    dispatch(setRequestUrl(currentPath));
    loadData();
  };

  const onClickLoadMore = (): void => {
    loadData(true);
  };

  const loadData = async (continuous = false, requestUrl?: string) => {
    let tableState = {
      error: false,
      isContinuousLoading: false,
      isLoading: true,
      shouldShowLoadMore: false,
    };

    if (!continuous) {
      dispatch(setTableState(tableState));
    } else {
      tableState.isLoading = false;
      tableState.isContinuousLoading = true;
      tableState.shouldShowLoadMore = true;
      dispatch(setTableState(tableState));
    }

    try {
      if (!domainName) {
        const bucketResponse = await getBucket(spaceKey, bucketName);
        const { domainName } = bucketResponse.data;
        dispatch(setDomainName(domainName));
      }

      const response = await loadObjects(spaceKey, bucketName, {
        limit: 30,
        prefix: requestUrl ? requestUrl : currentPath,
        continuationToken: continuous ? continuousToken : null,
      });

      const loadedData = _.get(response, "data.data");
      const conToken = _.get(response, "data.continuationToken", "");

      dispatch(setContinuousToken(conToken));

      if (continuous) {
        dispatch(setData([...data, ...loadedData]));
      } else {
        dispatch(setData(loadedData));
      }

      dispatch(
        setTableState({
          error: null,
          isContinuousLoading: false,
          isLoading: false,
          shouldShowLoadMore: _.get(response, "data.isTruncated", false),
        })
      );
    } catch (error) {
      const e = error as FileListError;
      if (e.response) {
        const { errorCode } = e.response.data;
        dispatch(
          setTableState({
            error: errorCode,
            isContinuousLoading: false,
            isLoading: false,
            shouldShowLoadMore: false,
          })
        );
      }
    }
  };

  useEffect(() => {
    document.title = `${bucketName} - ByteArk Storage`;

    dispatch(setBucketName(bucketName));
    dispatch(setRequestUrl(currentPath));
    dispatch(setCurrentFilePath(""));
    dispatch(setCurrentSpace(spaceKey));
    setData([]);

    loadData();

    return () => {
      dispatch(setCurrentFilePath(""));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucketName, currentPath]);

  useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Escape") {
        dispatch(setCurrentFilePath(""));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mapHeaderItems();
    if (headerItems) {
      dispatch(setTableColumns(headerItems));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectTarget, tableRows]);

  const isEmpty = !isLoading && !error && data && !data.length;
  const isSearching = Boolean(searchValue.length) && requestUrl !== currentPath;
  const isNotFound = isEmpty && isSearching && !error;

  const onClickTableContainer = (e: MouseEvent<HTMLElement>) => {
    const targetElement = e.target as HTMLElement;
    const { classList } = targetElement;
    const isClickedOnTheBlankArea = classList.contains("table-container");
    if (isClickedOnTheBlankArea) {
      dispatch(setCurrentFilePath(""));
    }
  };

  if (error) {
    if (error === "noSuchBucket") {
      return <PageNotFound />;
    }

    if (error === "forbidden") {
      return <PageNoPermission />;
    }
  }

  const popoverItems = [
    {
      title: "Manage Permissions",
      onClick: () => dispatch(setIsMultiplePermissionModalShowing(true)),
    },
  ];

  const popoverBottomAction = {
    title: "Delete",
    color: "#EB5757",
    onClick: () => dispatch(dispatch(showDeleteManyModal())),
  };

  return (
    <PageContainer>
      <FileListHeader bucketName={bucketName} spaceKey={spaceKey} />
      <Container>
        <UploadModal onSuccess={() => loadData()} />
        <ToolsContainer>
          <SearchBoxWrapper>
            <SearchBox
              text={searchValue}
              placeholder="Search by prefix name and press Enter to filter..."
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
              onDelete={handleClearSearch}
            />
          </SearchBoxWrapper>
          <ButtonContainer>
            <Button
              buttonStyle={"secondary-c"}
              isIconButton
              onClick={() =>
                dispatch(setIsBulkPopoverShowing(!isBulkPopoverShowing))
              }
              disable={!Boolean(selectTarget.length)}
            >
              Actions
              <FontAwesomeIcon
                color={"#6C757D"}
                icon={["fas", "chevron-down"]}
              />
            </Button>
            <Popover
              isShowing={isBulkPopoverShowing}
              items={popoverItems}
              bottomAction={popoverBottomAction}
              onDismiss={() => dispatch(setIsBulkPopoverShowing(false))}
            />

            <Button
              buttonStyle={"secondary-c"}
              isIconButton
              onClick={() => dispatch(setIsFolderModalShowing(true))}
            >
              <FontAwesomeIcon icon={["fas", "folder-plus"]} />
              New Folder
            </Button>

            <Button
              isIconButton
              onClick={() => dispatch(setIsUploadModalShowing(true))}
            >
              <FontAwesomeIcon icon={["fas", "cloud-upload"]} />
              Upload
            </Button>
          </ButtonContainer>
        </ToolsContainer>
        {isSearching && (
          <SearchResultContainer>
            {data && data.length !== 0 ? `${data.length}+` : "No"} results for ‘
            {searchValue}’
          </SearchResultContainer>
        )}
        <Content>
          <TableWrapper>
            <TableContainer
              className={"table-container"}
              onClick={(e: MouseEvent<HTMLElement>) => onClickTableContainer(e)}
            >
              <Breadcrumb
                baseUrl={`spaces/${spaceKey}/bucket/${bucketName}`}
                path={currentPath}
              />
              {Boolean(isNotFound) && (
                <NoResultMessage
                  description={
                    "We couldn't find any files matching your search."
                  }
                />
              )}
              {!Boolean(isLoading) && !isEmpty && !error && (
                <FileListTable spaceKey={spaceKey} />
              )}
              {Boolean(isLoading) && headerItems && (
                <FileListLoadingSection headerColumns={headerItems} />
              )}
              {!Boolean(isLoading) && error && <ErrorMessage />}
              {Boolean(!isSearching) && isEmpty && (
                <EmptyMessage
                  item={"files"}
                  description={"This folder is empty."}
                  button={{
                    text: (
                      <>
                        <FontAwesomeIcon icon={["fas", "cloud-upload-alt"]} />{" "}
                        Upload
                      </>
                    ),
                    onClick: () => dispatch(setIsUploadModalShowing(true)),
                  }}
                />
              )}
            </TableContainer>

            {Boolean(shouldShowLoadMore) && !error && (
              <LoadMoreButtonContainer>
                <Button
                  onClick={onClickLoadMore}
                  fullWidth={true}
                  buttonStyle={"secondary-d"}
                  disable={isContinuousLoading}
                >
                  {isContinuousLoading ? "Loading..." : "Load More"}
                  <FontAwesomeIcon
                    color={"#FFF"}
                    icon={["far", "chevron-down"]}
                  />
                </Button>
              </LoadMoreButtonContainer>
            )}
          </TableWrapper>
          <FileListSidebar />
        </Content>
      </Container>
      <PopupUploadProgress />
      <ModalManagePermissionsMultiple />
      <ModalNewFolder
        bucketName={bucketName}
        currentPath={currentPath}
        isCreatingFolder={isCreatingFolder}
        onCancel={onCancelNewFolder}
      />
      {/* Delete One */}
      <DeleteModal
        isOpen={isDeleteModalShowing}
        title={`Delete ${deleteTarget?.type === "folder" ? "Folder" : "File"}`}
        onClose={() => dispatch(dismissDeleteModal())}
        onDelete={handleDelete}
      >
        <ModalText>
          Are you sure you want to delete <strong>"{targetFile}"</strong>?
        </ModalText>
      </DeleteModal>
      {/* Delete Many */}
      <DeleteModal
        isOpen={isDeleteManyModalShowing}
        title="Delete Files"
        description={`Are you sure you want to delete the selected ${selectTarget.length} files?`}
        onClose={() => {
          dispatch(setIsBulkPopoverShowing(false));
          dispatch(dismissDeleteManyModal());
        }}
        onDelete={handleDeleteMany}
      />

      <ModalManagePermissions />
      <ModalManageMetadata />
    </PageContainer>
  );
};
