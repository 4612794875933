import { configureStore } from "@reduxjs/toolkit";
import bucketsReducer from "./reducers/buckets";
import fileReducer from "./reducers/files";
import settingsReducer from "./reducers/settings";
import userReducer from "./reducers/user";
import uploadReducer from "./reducers/upload";
import toastsReducer from "./reducers/toasts";
import spacesReducer from "./reducers/spaces";
import loadingReducer from "./reducers/loading";

export const store = configureStore({
  reducer: {
    buckets: bucketsReducer,
    files: fileReducer,
    settings: settingsReducer,
    user: userReducer,
    upload: uploadReducer,
    toasts: toastsReducer,
    spaces: spacesReducer,
    loading: loadingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
