import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableRowObject } from "../../components/atoms/tableBody/TableBody";
import { ColumnProps } from "../../components/atoms/tableHeader/TableHeader";
import { ToastProps } from "../../components/atoms/toast/Toast";
import _ from "lodash";

export interface TableState {
  error: any;
  isContinuousLoading: boolean;
  isLoading: boolean;
  shouldShowLoadMore: boolean;
}

interface DeleteTargetProps {
  type: "folder" | "file";
  path: string;
}

export interface FilesReducerState extends TableState {
  bucketName: string;
  continuousToken: string;
  createdFolderName: string;
  createResultMessage: string;
  currentFilePath: string;
  data: Array<any>;
  deleteTarget: DeleteTargetProps | null;
  domainName: string;
  isCreatingFolder: boolean;
  isDeleteModalShowing: boolean;
  isDeleteManyModalShowing: boolean;
  isUploadModalShowing: boolean;
  isUploadPopupShowing: boolean;
  popoverIndex: number;
  requestUrl: string;
  searchValue: string;
  shouldShowLoadMore: boolean;
  tableColumns: Array<ColumnProps> | null;
  tableRows: Array<TableRowObject> | null;
  targetFile: string;
  selectTarget: Array<string>;
  toastObject: ToastProps | null;
  isPermissionModalShowing: boolean;
  isPermissionChanging: boolean;
  isPopoverActionShowing: boolean;
  downloadError: Error | null;
  isMetadataModalShowing: boolean;
  isBulkPopoverShowing: boolean;
  isMultiplePermissionModalShowing: boolean;
}

const initialState: FilesReducerState = {
  bucketName: "",
  continuousToken: "",
  createdFolderName: "",
  createResultMessage: "",
  currentFilePath: "",
  error: null,
  data: [],
  deleteTarget: null,
  domainName: "",
  downloadError: null,
  isContinuousLoading: false,
  isCreatingFolder: false,
  isDeleteModalShowing: false,
  isPermissionModalShowing: false,
  isPermissionChanging: false,
  isDeleteManyModalShowing: false,
  isUploadModalShowing: false,
  isUploadPopupShowing: false,
  isLoading: true,
  popoverIndex: -1,
  requestUrl: "",
  searchValue: "",
  shouldShowLoadMore: false,
  tableColumns: null,
  tableRows: null,
  targetFile: "",
  toastObject: null,
  selectTarget: [],
  isPopoverActionShowing: false,
  isMetadataModalShowing: false,
  isBulkPopoverShowing: false,
  isMultiplePermissionModalShowing: false,
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Array<any>>) => {
      state.data = action.payload;
    },
    setBucketName: (state, action: PayloadAction<string>) => {
      state.bucketName = action.payload;
    },
    setTableRows: (state, action: PayloadAction<Array<TableRowObject>>) => {
      state.tableRows = action.payload;
    },
    setTableColumns: (state, action: PayloadAction<Array<ColumnProps>>) => {
      state.tableColumns = action.payload;
    },
    setPopoverIndex: (state, action: PayloadAction<number>) => {
      state.popoverIndex = action.payload;
    },
    setRequestUrl: (state, action: PayloadAction<string>) => {
      state.requestUrl = action.payload;
    },
    setCurrentFilePath: (state, action: PayloadAction<string>) => {
      state.currentFilePath = action.payload;
    },
    setContinuousToken: (state, action: PayloadAction<string>) => {
      state.continuousToken = action.payload;
    },
    setFolderSuccess: (state, action: PayloadAction<string>) => {
      state.createdFolderName = action.payload;
      state.createResultMessage = "Success";
      state.isCreatingFolder = false;
    },
    resetFolderState: (state) => {
      state.createdFolderName = "";
      state.createResultMessage = "";
      state.isCreatingFolder = false;
    },
    showDeleteModal: (state) => {
      state.isDeleteModalShowing = true;
    },
    dismissDeleteModal: (state) => {
      state.isDeleteModalShowing = false;
      state.popoverIndex = -1;
      state.targetFile = "";
    },
    showPermissionModal: (state) => {
      state.isPermissionModalShowing = true;
    },
    dismissPermissionModal: (state) => {
      state.isPermissionModalShowing = false;
      state.popoverIndex = -1;
      state.targetFile = "";
    },
    showDeleteManyModal: (state) => {
      state.isDeleteManyModalShowing = true;
    },
    dismissDeleteManyModal: (state) => {
      state.isDeleteManyModalShowing = false;
    },
    dismissPopover: (state) => {
      state.popoverIndex = -1;
    },
    setIsFolderModalShowing: (state, action: PayloadAction<boolean>) => {
      state.isCreatingFolder = action.payload;
    },
    showPopover: (
      state,
      action: PayloadAction<{
        fileName: string;
        fileIndex: number;
      }>
    ) => {
      state.targetFile = action.payload.fileName;
      state.popoverIndex = action.payload.fileIndex;
    },
    setTableState: (state, action: PayloadAction<TableState>) => {
      state.isLoading = action.payload.isLoading;
      state.isContinuousLoading = action.payload.isContinuousLoading;
      state.error = action.payload.error;
      state.shouldShowLoadMore = action.payload.shouldShowLoadMore;
    },
    resetStates: (state) => {
      const uploadPopupState = state.isUploadPopupShowing;
      Object.assign(state, initialState);
      state.isUploadPopupShowing = uploadPopupState;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setPermissionChanging: (state, action: PayloadAction<boolean>) => {
      state.isPermissionChanging = action.payload;
    },
    setIsPermissionModalShowing: (state, action: PayloadAction<boolean>) => {
      state.isPermissionModalShowing = action.payload;
    },
    setIsUploadModalShowing: (state, action: PayloadAction<boolean>) => {
      state.isUploadModalShowing = action.payload;
    },
    setDeleteTarget: (
      state,
      action: PayloadAction<DeleteTargetProps | null>
    ) => {
      state.deleteTarget = action.payload;
    },
    setSavePermission: (state) => {
      state.createResultMessage = "Success";
      state.isPermissionModalShowing = false;
      state.popoverIndex = -1;
      state.targetFile = "";
      state.isPermissionChanging = false;
    },
    setToastText: (state, action: PayloadAction<ToastProps>) => {
      state.toastObject = action.payload;
    },
    resetToastText: (state) => {
      state.toastObject = null;
    },
    setSelectTarget: (state, action: PayloadAction<string>) => {
      const isSelected = _.find(
        state.selectTarget,
        (item) => item === action.payload
      );
      if (!isSelected) {
        state.selectTarget = _.union(state.selectTarget, [action.payload]);
      } else {
        state.selectTarget = state.selectTarget.filter(
          (item) => item !== action.payload
        );
      }
    },
    clearSelectTarget: (state) => {
      state.selectTarget = [];
    },
    selectAllFiles: (state) => {
      const data = state.data.filter((item) => item.type === "file");
      const target = data.map((item) => item.path);
      state.selectTarget = [...target];
    },
    addSelectTarget: (state, action: PayloadAction<string>) => {
      if (!state.selectTarget.includes(action.payload)) {
        state.selectTarget.push(action.payload);
      }
    },
    removeSelectTarget: (state, action: PayloadAction<string>) => {
      state.selectTarget = state.selectTarget.filter(
        (item) => item !== action.payload
      );
    },
    setIsUploadPopupShowing: (state, action: PayloadAction<boolean>) => {
      state.isUploadPopupShowing = action.payload;
    },
    showPopoverAction: (state) => {
      state.isPopoverActionShowing = true;
    },
    dismissPopoverAction: (state) => {
      state.isPopoverActionShowing = false;
    },
    setDownloadError: (state, action: PayloadAction<Error | null>) => {
      state.downloadError = action.payload;
    },
    setIsMetadataModalShowing: (state, action: PayloadAction<boolean>) => {
      state.isMetadataModalShowing = action.payload;
    },
    setDomainName: (state, action: PayloadAction<string>) => {
      state.domainName = action.payload;
    },
    setIsBulkPopoverShowing: (state, action: PayloadAction<boolean>) => {
      state.isBulkPopoverShowing = action.payload;
    },
    setIsMultiplePermissionModalShowing: (state, action: PayloadAction<boolean>) => {
      state.isMultiplePermissionModalShowing = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  dismissDeleteModal,
  dismissPermissionModal,
  dismissDeleteManyModal,
  dismissPopover,
  resetFolderState,
  resetStates,
  setBucketName,
  setContinuousToken,
  setCurrentFilePath,
  setData,
  setDeleteTarget,
  setFolderSuccess,
  setIsFolderModalShowing,
  setIsUploadModalShowing,
  setIsUploadPopupShowing,
  setPopoverIndex,
  setRequestUrl,
  setSearchValue,
  setTableColumns,
  setTableRows,
  setTableState,
  setPermissionChanging,
  showPermissionModal,
  setSelectTarget,
  clearSelectTarget,
  selectAllFiles,
  addSelectTarget,
  removeSelectTarget,
  showDeleteModal,
  showDeleteManyModal,
  showPopover,
  setSavePermission,
  setToastText,
  showPopoverAction,
  dismissPopoverAction,
  resetToastText,
  setDownloadError,
  setIsMetadataModalShowing,
  setDomainName,
  setIsPermissionModalShowing,
  setIsBulkPopoverShowing,
  setIsMultiplePermissionModalShowing,
} = filesSlice.actions;

export default filesSlice.reducer;
