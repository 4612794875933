import styled, { css } from "styled-components";

import { Container as TooltipContainer } from "../../../atoms/tooltip/Tooltip.style";
import { TableRow } from "../../../atoms/tableBody/TableBody.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const ModalContainer = styled.div`
  @media ${maxLayout.md} {
    .modal-card {
      height: 90vh;
      width: 100vw;
      border-radius: unset;
    }
  }
`;

export const ModalCorsConfigContainer = styled.div`
  height: 100%;
`;

export const ModalContent = styled.div`
  width: 70vw;
  max-width: 900px;
  min-width: 700px;
  min-height: 300px;
  border-bottom: 1px solid var(--grey400);
  margin-bottom: var(--space-s);
  ${TableRow} { 
    align-items: flex-start;
  }
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  overflow: hidden;
  @media ${maxLayout.md} {
    width: 100%;
    min-width: unset;
    min-height: unset;
  }
`;

export const HeaderTable = styled.div<{ isShowingInfomation: boolean }>`
  @media ${maxLayout.md} {
    .table-header {
      display: ${(props) => (props.isShowingInfomation ? "none" : "block")};
    }
  }
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media ${maxLayout.md} {
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const ErrorText = styled.p`
  color: var(--red);
`;

export const ButtonContainer = styled.div`
  width: 120px;
  margin-left: auto;
  margin: 5px;
  @media ${maxLayout.md} {
    width: 100%;
    margin: var(--space-m) 0;
  }
`;

export const TextOrigins = styled.div`
  ${TooltipContainer} {
    display: inline;
    left: 46px;
    z-index: 1000;
  }
`;

export const AddRulesContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--space-s);
  background: var(--white);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: var(--space-base);
`;

export const IconWarpper = styled.div<{ color: string }>`
  font-size: 12px;
  color: ${(props) => props.color};
  cursor: pointer;
  margin-left: var(--space-s);
  @media ${maxLayout.md} {
    margin-left: 0;
  }
`;

export const AddRulesText = styled.div`
  font-size: 14px;
  color: var(--grey800);
  margin-left: var(--space-s);
`;

export const RowContainer = styled.div<{ isShowingInfomation: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  height: 505px;

  ${(props) => {
    if (props.isShowingInfomation) {
      return css`
        grid-template-columns: 1fr 3fr;
      `;
    }
  }}
  @media ${maxLayout.md} {
    display: block;
    height: 60.5vh;
    ${(props) => {
      if (props.isShowingInfomation) {
        return css`
          height: calc(60.5vh + 36.5px);
        `;
      }
    }}
  }
  @media ${maxLayout.xxs} {
    height: 58.5vh;
    ${(props) => {
      if (props.isShowingInfomation) {
        return css`
          height: calc(58.5vh + 36.5px);
        `;
      }
    }}
  }
`;

export const RulesContainer = styled.div<{ isShowingInfomation: boolean }>`
  overflow: auto;
  @media ${maxLayout.md} {
    display: ${(props) => (props.isShowingInfomation ? "none" : "block")};
  }
`;

export const RulesInfomation = styled.div<{ isShowingInfomation: boolean }>`
  position: relative;
  height: 505px;
  display: none;
  border-left: 1px solid var(--grey400);
  overflow: auto;
  ${(props) => {
    if (props.isShowingInfomation) {
      return css`
        display: block;
      `;
    }
  }}
  @media ${maxLayout.md} {
    height: 100%;
    border-left: unset;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const OriginContainer = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--space-s) var(--space-base);
  border-bottom: 1px solid var(--grey400);

  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--grey200);
    `}

  &:hover {
    background-color: var(--grey200);
  }

  &:active {
    background-color: var(--grey200);
  }

  @media ${maxLayout.md} {
    padding: var(--space-s);
  }
`;

export const Origin = styled.div<{
  isNewRule: boolean;
  isShowingInfomation: boolean;
}>`
  font-size: 14px;
  color: ${(props) => (!props.isNewRule ? "var(--grey900)" : `var(--grey800)`)};
  margin-right: ${(props) =>
    props.isShowingInfomation ? "" : `var(--space-xs)`};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const RuleInfomationSection = styled.div`
  padding: 0px var(--space-base) var(--space-m) var(--space-base);
  @media ${maxLayout.md} {
    padding: 0 0 var(--space-m) 0;
  }
`;

export const BackToRuleContainer = styled.div`
  display: none;
  @media ${maxLayout.md} {
    position: fixed;
    display: flex;
    align-items: center;
    background: white;
    font-size: 14px;
    color: var(--primary);
    width: calc(100% - 64px);
    padding-bottom: var(--space-base);
    z-index: 1;
    margin-top: -1px;
    border-bottom: 1px solid var(--grey400);
  }
`;

export const BackText = styled.div``;

export const BackIconArrow = styled.div`
  padding-right: var(--space-s);
`;

export const OriginsHolder = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: var(--space-base);
  @media ${maxLayout.md} {
    padding-top: var(--space-xl);
  }
`;

export const Header = styled.div`
  font-size: 14px;
  color: var(--grey900);
  margin-bottom: var(--space-xs);
  font-weight: 700;
`;

export const Info = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: var(--grey700);
  margin-bottom: var(--space-s);
`;

export const OriginArea = styled.textarea<{ isInvalid?: Boolean }>`
  overflow: hidden;
  padding: var(--space-xs) var(--space-s);
  font-size: 14px;
  color: var(--grey900);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--grey400);
  resize: none;
  line-height: 170%;
  ::placeholder {
    color: var(--grey500);
  }
  ${(props) => {
    if (props.isInvalid) {
      return css`
        :focus {
          outline: none !important;
          border: 1px solid red;
        }
      `;
    }
  }}

`;

export const WarningOriginMessage = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-top: var(--space-xs);
  color: var(--red);
`;

export const WarningIconWarpper = styled.div`
  display: flex;
  align-items: center;
  margin-right: var(--space-xs);
`;

export const WarningInvalid = styled.div`
  display: flex;
  align-items: center;
`;

export const WarningEmpty = styled.div`
  display: flex;
  align-items: center;
`;

export const EditOrigin = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 14px;
  color: var(--grey800);
`;
export const EditOriginText = styled.div<{ isShowingInfomation: boolean }>`
  margin-right: var(--space-s);

  ${(props) => {
    if (props.isShowingInfomation) {
      return css`
        visibility: hidden;
      `;
    }
  }}
`;
export const EditOriginIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const TableDescription = styled.div`
  font-size: 14px;
  color: var(--grey900);
  display: flex;
  margin-top: var(--space-xs);
`;

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: var(--space-m);
  @media ${maxLayout.md} {
    grid-template-columns: repeat(auto-fill, minmax(75px, 100px));
    grid-row-gap: var(--space-s);
  }
`;

export const CheckboxItem = styled.div``;

export const AllowedMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.p`
  color: var(--red);
  font-size: 12px;
  margin-top: var(--space-xs);
`;

export const MethodsHolder = styled.div``;

export const AllowedHeaderContainer = styled.div``;

export const AccessControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputNumberWrapper = styled.div`
  /* width: 127px; */
`;

export const DeleteRuleButtonWarpper = styled.div``;

export const DiscardContainer = styled.div<{ isCorErrorMessage: Boolean }>`
  width: 73%;
  display: flex;
  justify-content: flex-end;
  ${(props) => {
    if (props.isCorErrorMessage) {
      return css`
        width: 96.5%;
      `;
    }
  }}
  @media ${maxLayout.md} {
    width: 100%;
  }
`;

export const TextNotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const TitleNotificationBar = styled.div`
  margin-left: var(--space-s);
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--grey900);
`;

export const SaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media ${maxLayout.md} {
    margin-top: var(--space-xs);
    width: 100%;
  }
`;
