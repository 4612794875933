import styled from "styled-components";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const SectionSpaceCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--space-m);

  @media ${maxLayout.md} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${maxLayout.xs} {
    grid-template-columns: 1fr;
    grid-gap: var(--space-base);
  }
`;

export const CardFooter = styled.div`
  color: var(--grey800);
  font-size: 14px;
  
  svg {
    margin-right: var(--space-s);
  }
`;
