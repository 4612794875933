import styled from "styled-components";
import {
  subTitleStyle,
  sectionHeaderStyle,
} from "../../GlobalStyle/GlobalStyle.style";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const Container = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
`;

export const ChildrenWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--space-base);
  z-index: 1002;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(33, 45, 55, 0.7);
  z-index: 1001;
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  @media ${maxLayout.md} {
    width: 100%;
  }
`;

export const Table = styled.div`
  min-height: 250px;
  width: 590px;
  text-align: left;
  border-collapse: collapse;
  margin-bottom: var(--space-base);

  @media ${maxLayout.sm} {
    max-width: 80vw;
  }
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey400);
  .column1 {
    flex: 0 0 65%;
  }
  .column2 {
    flex: 0 0 35%;
  }
`;

export const TableHeader = styled.div`
  ${subTitleStyle}
  font-weight: 500;
  color: var(--grey900);
  background-color: var(--grey300);
  border-bottom: 1px solid var(--grey400);
  padding: var(--space-s) var(--space-base);

  :first-child {
    border-radius: var(--border-radius-default) 0 0 0;
  }

  :last-child {
    border-radius: 0 var(--border-radius-default) 0 0;
  }
`;

export const TableDescription = styled.div`
  font-size: 14px;

  color: var(--grey900);
  padding: var(--space-s) var(--space-base);
  display: flex;
  align-items: center;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DropdownIconWarpper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  color: var(--red);
  font-size: 12px;
  margin-top: var(--space-xs);

  .tooltip {
    margin-top: -12px;
  }
`;

export const WarningText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: max-content;
  margin-left: -16px;
  margin-top: -4px;

  p {
    margin-left: var(--space-xs);
  }

  p:hover {
    cursor: default;
    text-decoration: underline;
  }

  span {
    padding-top: 1px;
  }
`;

export const GranteeContainer = styled.div<{ isWarning: Boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.isWarning ? `margin-top: -10px;` : "")};
`;

export const ModalText = styled.div`
  ${subTitleStyle};
  color: var(--grey900);
  margin-bottom: var(--space-base);
  width: 396px;

  * {
    overflow-wrap: anywhere;
  }

  @media ${maxLayout.sm} {
    width: 80vw;
  }
`;

export const ChildrenContainer = styled.div`
  background-color: var(--white);
  border-radius: var(--border-radius-default);
  padding: var(--space-base);
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-base);
`;

export const Title = styled.div`
  ${sectionHeaderStyle}
  color: var(--grey900);
  padding-right: var(--space-base);
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  color: var(--grey800);
  padding: var(--space-s) var(--space-base);
  margin-right: -16px;
  margin-top: -6px;
  margin-bottom: -6px;
`;
