import { Button, Modal, TextInput } from "../../..";
import {
  InputWrapper,
  ButtonContainer,
} from "../../bucketList/modalCreateBucket/ModalCreateBucket.style";
import {
  trimStartAndEndWhiteSpace,
  validateFolderName,
} from "../../../../utils/file";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { createFolder } from "../../../../services/apis";
import { useHistory } from "react-router-dom";
import { setFolderSuccess } from "../../../../store/reducers/files";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  addToast,
  removeToast,
  ToastProps,
} from "../../../../store/reducers/toasts";
import { v4 as uuid } from "uuid";

interface NewFolderModalProps {
  bucketName: string;
  currentPath: string;
  isCreatingFolder: boolean;
  onCancel: () => void;
}

export const ModalNewFolder = (props: NewFolderModalProps) => {
  const { bucketName, currentPath, isCreatingFolder, onCancel } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { currentSpace } = useAppSelector((state) => state.spaces);
  const [folderName, setFolderName] = useState<string>("");
  const [createResultMessage, setCreateResultMessage] = useState<string>("");

  const handleFolderNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFolderName(event.target.value);
  };

  const handleCreateFolder = async () => {
    let trimmedFolderName = trimStartAndEndWhiteSpace(folderName);
    const id = uuid();
    let toastData: ToastProps;

    const payload = {
      spaceKey: currentSpace,
      bucketName,
    };

    try {
      const response = await createFolder(payload, {
        path: `${currentPath}${trimmedFolderName}`,
      });

      if (response.status === 201) {
        dispatch(setFolderSuccess(folderName));
        setFolderName("");
        toastData = {
          id,
          status: "success",
          title: "Folder Created",
          description: `Folder "${folderName}" has been created.`,
        };
        dispatch(addToast(toastData));
        const encodedPath = encodeURIComponent(
          `${currentPath}${trimmedFolderName}/`
        );
        history.push(`${bucketName}?path=${encodedPath}`);
      }
    } catch (err: any) {
      const data: { errorCode: string } = err.response.data;
      toastData = {
        id,
        status: "error",
        title: "Create Folder Failed",
        description: `Folder couldn’t be created. Please try again.`,
      };
      dispatch(addToast(toastData));

      setCreateResultMessage(data.errorCode);
    }

    let timer = setTimeout(() => {
      dispatch(removeToast(id));
      clearTimeout(timer);
    }, 5000);
  };

  const handleCreateFolderKeyDown = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && validateFolderName(folderName)) {
      handleCreateFolder();
    } else if (event.key === "Escape") {
      onCloseModal();
    }
  };

  const onCloseModal = () => {
    setFolderName("");
    onCancel();
  };

  return (
    <Modal title="New Folder" isOpen={isCreatingFolder} onClose={onCloseModal}>
      <InputWrapper>
        <TextInput
          text={folderName}
          error={
            !["Success", ""].includes(createResultMessage) ||
            (!validateFolderName(folderName) && folderName !== "")
          }
          placeHolder="Enter folder name"
          label={
            !["Success", ""].includes(createResultMessage)
              ? createResultMessage
              : validateFolderName(folderName) || folderName === ""
              ? `Folder name can be up to 63 characters long and consist only of letters, numbers, underscore (_), hyphen (-), or space.`
              : `Invalid name format. Folder name can be up to 63 characters long and consist only of letters, numbers, underscore (_), hyphen (-), or space.`
          }
          onChange={handleFolderNameChange}
          onKeyDown={handleCreateFolderKeyDown}
        />
      </InputWrapper>
      <ButtonContainer>
        <Button
          onClick={handleCreateFolder}
          disable={!validateFolderName(folderName)}
          fullWidth
        >
          Create
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
