import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import Router from "./router";
import GlobalStyle from "./components/GlobalStyle/GlobalStyle";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { StackToast } from "./components/molecules/stackToast/StackToast";

ReactDOM.render(
  <GlobalStyle>
    <Provider store={store}>
      <Router />
      <StackToast />
    </Provider>
  </GlobalStyle>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
