import styled from "styled-components";
import { Input } from "../../../atoms/textInput/TextInput.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";
import { TableBodyContainer } from "../../../atoms/tableBody/TableBody.style";

export const ModalManageMetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 562px;
  min-height: 250px;
  
  ${Input} {
    padding-right: var(--space-s);
  }
  
  @media ${maxLayout.sm} {
    width: 80vw;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  z-index: 2;
  color: var(--grey800);
  width: 100%;
  height: 100%;

`;

export const AddRowContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--grey800);
  cursor: pointer;
  padding: var(--space-s) var(--space-base);
  transition: background-color 200ms ease;

  svg {
    margin-right: 10px;
  }

  :hover {
    background-color: var(--grey200);
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const TableContainer = styled.div`
  border-radius: var(--border-radius-default);
  overflow: hidden;
  
  ${TableBodyContainer} {
    max-height: 272px;
    overflow-y: auto;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  color: var(--red);
  margin-right: var(--space-s);
  margin-bottom: 0;
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  @media ${maxLayout.md} {
    width: 100%;
  }
`;
