import { MouseEvent } from "react";
import { Input, Box, Label, Text } from "./Checkbox.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CheckboxProps {
  /**
   * Text for describe checkbox value.
   */
  label?: string;
  /**
   * True if it was checked.
   */
  checked: boolean;
  /**
   * disabled this checkbox if true.
   */
  disabled?: boolean;
  /**
   * function that fire when click the box.
   */
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

export const Checkbox = (props: CheckboxProps) => {
  const { label, checked, disabled, onClick = () => {} } = props;
  return (
    <Label onClick={onClick} isDisabled={disabled}>
      <Input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={() => {}}
      />
      <Box className="box">
        <FontAwesomeIcon className="icon" size={"xs"} icon={["fas", "check"]} />
      </Box>
      {label && <Text>{label}</Text>}
    </Label>
  );
};
