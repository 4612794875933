import styled, { css, keyframes } from "styled-components";

export const TooltipAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
`;

interface TooltipTextProps {
  position: "top" | "bottom";
  textAlign: "left" | "center" | "right";
}

export const TooltipText = styled.div<TooltipTextProps>`
  visibility: hidden;
  width: 180px;

  background-color: var(--white);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-large);
  
  position: absolute;
  padding: var(--space-s) var(--space-base);

  ${ (props) => {
    if (props.position === "top") {
      return css`
        bottom: 15px;
      `;
    }
    return css`
      top: 32px;
    `;
  } };

  ${ (props) => {
    if (props.textAlign === "left") {
      return css`
        left: -50%;
      `;
    }
    if (props.textAlign === "center") {
      return css`
        left: 50%;
        transform: translateX(-57%);
      `;
    }
    if (props.textAlign === "right") {
      return css`
        right: -35%;
      `;
    }
  } };

  color: var(--grey800);
  font-size: 12px;
  font-weight: 400;
  text-align: ${(props) => props.textAlign};
  word-break: break-word;

  z-index: 1;

  :after {
    content: "";
    position: absolute;

    ${ (props) => {
      if (props.position === "top") {
        return css`
          top: 100%;
          border-color: var(--white) transparent transparent transparent;
      `;
      }
      return css`
        bottom: 100%;
        border-color: transparent transparent var(--white) transparent;
    `;
    } };

    ${ (props) => {
      if (props.textAlign === "left") {
        return css`
          left: 15%;
        `;
      }
      if (props.textAlign === "center") {
        return css`
          left: 50%;
        `;
      }
      if (props.textAlign === "right") {
        return css`
          right: 20%;
        `;
      }
    } }

    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
  }
`;

export const TooltipContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  height: 100%;
  width: 40px;
  animation: TooltipAnimation 250ms ease;

  :hover {
    ${TooltipText} {
      visibility: visible;
    }
  }
`;
