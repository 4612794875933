import styled from "styled-components";

export const ChoiceContainer = styled.div<{ withMarginBottom: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  cursor: pointer;

  border-radius: var(--border-radius-large);
  border: 1.5px solid var(--grey400);
  background-color: var(--white);
  padding: var(--space-base);
  margin-bottom: ${(props) => props.withMarginBottom ? "var(--space-base)" : 0};
  transition: background-color 200ms ease;
  
  :hover {
    background-color: var(--grey200);
  }
`;

export const RadioChecked = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  background-color: var(--primary);
  margin-right: var(--space-s);
  margin-top: 4px;
  border-radius: 50px;
  :after {
    content: "";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: var(--white);
    top: 3.8px;
    left: 3.6px;
    border-radius: 50px;
  }
`;

export const Radio = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  background-color: var(--white);
  margin-right: var(--space-s);
  margin-top: var(--space-xs);
  
  border: 2px solid var(--grey500);
  border-radius: 50px;
`;

export const TextContainer = styled.div``;

export const ChoiceTitle = styled.p`
  color: var(--grey900);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: var(--space-xs);
  
  svg {
    position: relative;
    top: -1px;
    font-size: 12px;
    margin-right: var(--space-xs);
  }
`;

export const ChoiceDescription = styled.p`
  font-size: 12px;
  color: var(--grey700);
`;
