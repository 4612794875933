import styled, { css } from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-right: auto;
  padding-top: var(--space-s);
  padding-right: var(--space-base);

  .fa-chevron-right {
    width: 0.4em;
    margin-left: var(--space-s);
    margin-right: var(--space-s);
  }

  .fa-home {
    font-size: 0.9em;
  }
`;

export const Path = styled.span<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: fit-content; */
  a {
    pointer-events: ${(props) => (props.isActive ? "none" : "all")};
    :hover {
      text-decoration: underline;
      text-decoration-color: var(--grey600);
    }
  }

  @media ${maxLayout.lg} {
    width: inherit;
    /* overflow: hidden; */
  }
`;

export const PathSpan = styled.div<{ isActive: boolean }>`
  position: relative;
  color: ${(props) => (props.isActive ? "var(--grey200)" : "var(--grey600)")};
  font-size: 14px;
  font-weight: 500;
  ${(props) => {
    if (props.isActive) {
      // ตัวสุดท้าย
      return css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 20px;
        width: 50vw;
      `;
    } else {
      return css`
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media ${maxLayout.lg} {
          max-width: 25vw;
        }
      `;
    }
  }}
`;