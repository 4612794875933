import {
  DataMetaContainer,
  InputGroup,
  MetaContent,
  MetaGroup,
  MetaTitle, Title,
} from "./SectionFileProperties.style";
import { formatBytes } from "../../../../utils/formatNumber";
import { LoadingPlaceholder } from "../../../atoms/loadingPlaceholder/LoadingPlaceholder";
import { formatDateTime } from "../../../../utils/dateTime";
import { InputCopy } from "../../inputCopy/InputCopy";
import { SectionFilePropertiesContainer } from "./SectionFileProperties.style";
import { FileInfoProps } from "../../../organisms/fileList/fileListSidebar/FileListSidebar";

interface SectionFilePropertiesProps {
  fileInfo: FileInfoProps | null | undefined;
}

export const SectionFileProperties = (props: SectionFilePropertiesProps) => {
  const { fileInfo } = props;

  return (
    <SectionFilePropertiesContainer>
      <Title>
        File Properties
      </Title>
      <DataMetaContainer>
        <MetaGroup>
          <MetaTitle>
            Size
          </MetaTitle>
          <MetaContent>
            {
              fileInfo ? (<span>{ formatBytes(fileInfo.size) }</span>) : (
                <LoadingPlaceholder width={'100%'} height={14} />
              )
            }
          </MetaContent>
        </MetaGroup>
        <MetaGroup>
          <MetaTitle>
            Type
          </MetaTitle>
          <MetaContent>
            {
              fileInfo ? (<span>{ fileInfo.extension }</span>) : (
                <LoadingPlaceholder width={'100%'} height={14} />
              )
            }
          </MetaContent>
        </MetaGroup>
        <MetaGroup>
          <MetaTitle>
            Last Modified
          </MetaTitle>
          <MetaContent>
            {
              fileInfo ? (
                <span>{ formatDateTime(fileInfo.lastModified) }</span>
              ) : (
                <LoadingPlaceholder width={'100%'} height={14} />
              )
            }
          </MetaContent>
        </MetaGroup>
      </DataMetaContainer>
      <InputGroup>
        <p>URL</p>
        <InputCopy value={fileInfo ? fileInfo.url : ''} />
      </InputGroup>
      <InputGroup>
        <p>Entity Tag</p>
        <InputCopy value={fileInfo ? fileInfo.etag : ''} />
      </InputGroup>
    </SectionFilePropertiesContainer>
  )
};
