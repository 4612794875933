import styled, { css } from "styled-components";
import { ButtonStyle } from "./Button";

const primaryStyle = css`
  color: var(--grey100);
  border: 1px solid var(--primary);
  background-color: var(--primary);
`;

const secondaryAStyle = css`
  color: var(--grey800);
  border: 1px solid var(--grey800);
  background-color: transparent;
`;

const secondaryBStyle = css`
  color: var(--primary);
  border: 1px solid var(--primary);
  background-color: transparent;
`;

const secondaryCStyle = css`
  color: var(--grey800);
  border: 1px solid var(--white);
  background-color: var(--white);
`;

const secondaryDStyle = css`
  color: var(--grey100);
  border: 1px solid var(--grey800);
  background-color: var(--grey800);
`;

const dangerStyle = css`
  color: var(--grey100);
  border: 1px solid var(--red);
  background-color: var(--red);
`;

const dangerOutlineStyle = css`
  color: var(--red);
  border: 1px solid var(--red);
  background-color: transparent;
`;

export const ButtonWrapper = styled.button<{
  buttonStyle?: ButtonStyle;
  disable?: boolean;
  fullWidth?: boolean;
  isIconButton?: boolean;
}>`
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  padding: var(--space-s) ${(props) => (props.isIconButton ? "10px" : "15px")};
  border-radius: var(--border-radius-large);
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  > svg {
    margin-right: ${(props) => (props.isIconButton ? "var(--space-s)" : "0px")};
  }

  ${(props) => {
    if (props.buttonStyle === "secondary-a") {
      if (props.disable) {
        return css`
          ${secondaryAStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryAStyle};
          :hover {
            cursor: pointer;
            color: var(--white);
            background-color: var(--grey800);
          }
        `;
      }
    } else if (props.buttonStyle === "secondary-b") {
      if (props.disable) {
        return css`
          ${secondaryBStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryBStyle};
          :hover {
            cursor: pointer;
            ${primaryStyle}
          }
        `;
      }
    } else if (props.buttonStyle === "secondary-c") {
      if (props.disable) {
        return css`
          ${secondaryCStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryCStyle};
          :hover {
            cursor: pointer;
            background-color: var(--grey400);
            border-color: var(--grey400);
          }
          :active {
            border-color: rgba(0, 166, 237, 0.4);
          }
        `;
      }
    } else if (props.buttonStyle === "secondary-d") {
      if (props.disable) {
        return css`
          ${secondaryDStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryDStyle};
          :hover {
            cursor: pointer;
            background-color: var(--grey700);
            border-color: var(--grey700);
          }
          :active {
            border-color: rgba(108, 117, 125, 0.4);
          }
        `;
      }
    } else if (props.buttonStyle === "danger") {
      if (props.disable) {
        return css`
          ${dangerStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${dangerStyle};
          :hover {
            cursor: pointer;
            background-color: var(--redDarken);
            border-color: var(--redDarken);
          }
          :active {
            border-color: rgba(207, 54, 55, 0.35);
          }
        `;
      }
    }else if (props.buttonStyle === "danger-outline") {
      if (props.disable) {
        return css`
          ${dangerOutlineStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${dangerOutlineStyle};
          :hover {
            cursor: pointer;
            color: var(--white);
            background-color: var(--red);
          }
        `;
      }
    } else {
      if (props.disable) {
        return css`
          ${primaryStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${primaryStyle};
          :hover {
            cursor: pointer;
            background-color: var(--middle);
            border-color: var(--middle);
          }
          :active {
            border-color: rgba(45, 131, 219, 0.35);
          }
        `;
      }
    }
  }}
`;
