import { ModalText } from "../../../atoms/modal/Modal.style";
import { DeleteModal } from "../../deleteModal/DeleteModal";
import {
  resetLoadingState,
  setLoadingState,
} from "../../../../store/reducers/loading";
import { v4 as uuid } from "uuid";
import { deleteOneSpace } from "../../../../store/reducers/spaces";
import {
  addToast,
  removeToast,
  ToastProps,
} from "../../../../store/reducers/toasts";
import { useAppDispatch } from "../../../../store/hooks";
import { TextInput } from "../../..";
import { ChangeEvent, KeyboardEvent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

interface ModalDeleteSpaceProps {
  isModalShowing: boolean;
  onCloseModal: () => void;
  spaceKey: string;
}

export const ModalDeleteSpace = (props: ModalDeleteSpaceProps) => {
  const { spaceKey, isModalShowing, onCloseModal } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [inputText, setInputText] = useState<string>("");
  const [isReadyToDelete, setIsReadyToDelete] = useState<boolean>(false);

  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    setInputText(value);
    setIsReadyToDelete(value === spaceKey);
  };

  const onKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isReadyToDelete) {
      handleDeleteSpace();
    }
  };

  const handleDeleteSpace = () => {
    dispatch(setLoadingState());

    const deleteData = async () => {
      const id = uuid();
      let toastData: ToastProps | null = null;
      try {
        const response = await dispatch(deleteOneSpace(spaceKey));
        if (deleteOneSpace.fulfilled.match(response)) {
          if (!response.payload.errorCode) {
            onCloseModal();

            // Toast for delete successfully.
            toastData = {
              id,
              status: "success",
              title: "Space Deleted",
              description: `Space "${spaceKey}" has been deleted.`,
            };

            history.push("/");
          } else {
            toastData = {
              id,
              status: "error",
              title: "Delete Space Failed",
              description: `This Space cannot be deleted at this moment. Please try again later.`,
            };
          }
        }
      } catch (e) {
        // Toast for delete fail.
        toastData = {
          id,
          status: "error",
          title: "Delete Space Failed",
          description: `This Space cannot be deleted at this moment. Please try again later.`,
        };
      }
      if (toastData) {
        dispatch(addToast(toastData));
        const timer = setTimeout(() => {
          dispatch(removeToast(id));
          clearTimeout(timer);
        }, 5000);
      }
      dispatch(resetLoadingState());
    };

    deleteData();
  };

  useEffect(() => {
    if (isModalShowing) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalShowing]);

  return (
    <DeleteModal
      title="Delete Space"
      disable={!isReadyToDelete}
      isOpen={isModalShowing}
      onClose={onCloseModal}
      onDelete={handleDeleteSpace}
      testId="delete-space-submit"
    >
      <>
        <ModalText>
          Are you sure you want to delete <strong>"{spaceKey}"</strong>
          ?
          <br />
          <br />
          Confirm you want to permanently delete this Space by entering its name
          below.
        </ModalText>
        <TextInput
          text={inputText}
          placeHolder="spacename"
          onChange={(event) => onTextChange(event)}
          onKeyDown={onKeydown}
          testId="delete-space-input"
        />
      </>
    </DeleteModal>
  );
};
