/* eslint-disable react-hooks/exhaustive-deps */
import {
  PageContainer,
  Container,
  LoadingContainer,
} from "./ManageSpace.style";
import { ManageSpaceHeader } from "../../components/organisms/manageSpace/manageSpaceHeader/ManageSpaceHeader";
import { RouteComponentProps } from "react-router";
import { ManageSpaceKeys } from "../../components/organisms/manageSpace/manageSpaceKeys/ManageSpaceKeys";
import { ManageSpaceDelete } from "../../components/organisms/manageSpace/manageSpaceDelete/ManageSpaceDelete";
import { useEffect, useState } from "react";
import { getSpaceByKey } from "../../services/apis";
import { setCurrentSpace } from "../../store/reducers/buckets";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PageNoPermission } from "../../errors/PageNoPermission";
import { LoadingMessage } from "../../components/organisms/messages/LoadingMessage";
import { PopupUploadProgress } from "../../components/organisms/fileList/popupUploadProgress/PopupUploadProgress";

interface MatchParams {
  spaceKey: string;
  bucketName: string;
}

interface ManageTeamAccessKeyProps extends RouteComponentProps<MatchParams> {}

export const ManageSpace = (props: ManageTeamAccessKeyProps) => {
  const spaceKey = props.match.params.spaceKey;
  const bucketName = props.match.params.bucketName;
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const [isAllowToAccess, setIsAllowToAccess] = useState<boolean>(false);
  const [isDefaultSpace, setIsDefaultSpace] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    setIsAllowToAccess(false);
    const getSpaceInfo = async () => {
      try {
        const response = await getSpaceByKey(spaceKey);
        dispatch(setCurrentSpace(response.data));
        console.log(user);
        setIsAllowToAccess(user?.id === response.data.ownerBytearkAccountId);
        setIsDefaultSpace(response.data.isDefaultSpace);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    getSpaceInfo();

    document.title = "Manage Space - ByteArk Storage";
  }, []);

  if (!isLoading && !isAllowToAccess) {
    return <PageNoPermission />;
  }

  return (
    <PageContainer>
      <ManageSpaceHeader spaceKey={spaceKey} bucketName={bucketName} />
      <Container>
        {isLoading ? (
          <LoadingContainer>
            <LoadingMessage />
          </LoadingContainer>
        ) : (
          <>
            <ManageSpaceKeys {...props} />
            <ManageSpaceDelete {...props} isDefaultSpace={isDefaultSpace} />
          </>
        )}
      </Container>
      <PopupUploadProgress />
    </PageContainer>
  );
};
