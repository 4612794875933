import _ from "lodash";
import {
  DownloadButtonContainer,
  FileInfoContainer,
  FileListSidebarContainer,
  GuideContainer,
  OverflowContainer,
  SidebarOverlay,
  SidebarTitle,
} from "./FileListSidebar.style";
import { useEffect, useState } from "react";
import { loadFileDetails } from "../../../../services/apis";
import { ErrorMessage } from "../../../organisms/messages/ErrorMessage";
import { LoadingPlaceholder } from "../../../atoms/loadingPlaceholder/LoadingPlaceholder";
import { getFileIcon, onDownloadFile } from "../../../../utils/file";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Button, TextWithIcon } from "../../..";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setCurrentFilePath, setDownloadError } from "../../../../store/reducers/files";
import { SectionFileProperties } from "../../../molecules/fileList/sectionFileProperties/SectionFileProperties";
import { SectionFilePermissions } from "../../../molecules/fileList/sectionFilePermissions/SectionFilePermissions";
import { AxiosError } from "axios";

export interface FileInfoProps {
  acp: {
    everyonePermission: string;
    grants: Array<{
      grantee: {
        id: string;
        displayName: string;
      };
      granteeType: "Individual" | "Group";
      permission: "READ" | "WRITE" | "FULL_CONTROL";
    }>;
  };
  etag: string;
  extension: string;
  lastModified: string;
  name: string;
  path: string;
  size: number;
  type: string;
  url: string;
}

export const FileListSidebar = () => {
  const dispatch = useAppDispatch();
  const { bucketName, currentFilePath } = useAppSelector(
    (state) => state.files
  );
  const { currentSpace } = useAppSelector((state) => state.spaces);

  const [fileInfo, setFileInfo] = useState<FileInfoProps | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownloadClicked, setIsDownloadClicked] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isMobileSidebarShowing, setIsMobileSidebarShowing] = useState<boolean>(
    false
  );

  const onClickDownload = async () => {
    if (!fileInfo) {
      return;
    }

    setIsDownloadClicked(true);

    await onDownloadFile({
      spaceKey: currentSpace,
      bucketName,
      filename: fileInfo.name,
      path: currentFilePath,
      onError: (error) => {
        dispatch(setDownloadError(error as Error));
      },
    });

    setIsDownloadClicked(false);
  };

  const onClickOverlay = () => {
    setIsMobileSidebarShowing(false);
    // Wait for the animation before resetting
    const timer = setTimeout(() => {
      dispatch(setCurrentFilePath(""));
      clearTimeout(timer);
    }, 250);
  };

  useEffect(() => {
    const loadData = async () => {
      setFileInfo(null);
      if (!currentFilePath) {
        return;
      }

      setIsLoading(true);
      setError("");
      try {
        const response = await loadFileDetails(currentSpace, bucketName, {
          path: currentFilePath,
        });
        setFileInfo(_.get(response, "data"));
        setIsLoading(false);
      } catch (error) {
        const e = error as AxiosError;
        setIsLoading(false);
        setError(_.get(e, "response.data.errorCode"));
      }
    };

    loadData();

    if (currentFilePath) {
      setIsMobileSidebarShowing(true);
    }
  }, [bucketName, currentFilePath, currentSpace]);

  return (
    <>
      <FileListSidebarContainer hide={!isMobileSidebarShowing}>
        {!Boolean(currentFilePath) && (
          <GuideContainer>
            <h4>Guide</h4>
            <p>
              You can develop your own software, or use Amazon S3-compatible software
              such as Cyberduck to manage files.
            </p>
            <p>
              <a href={"https://docs.byteark.com/th/storage/file-uploading.html"} target={"_blank"} rel={"noreferrer"}>
                How to use desktop clients
              </a>
            </p>
            <p>
              <a href={"https://docs.byteark.com/th/storage/file-uploading.html"} target={"_blank"} rel={"noreferrer"}>
                How to develop your software using: NodeJS / PHP / Golang
              </a>
            </p>
          </GuideContainer>
        )}
        {Boolean(currentFilePath) && !error && (
          <FileInfoContainer>
            {fileInfo ? (
              <SidebarTitle>
                <TextWithIcon
                  icon={getFileIcon(fileInfo.extension)}
                  text={fileInfo.name}
                  fontWeight={700}
                  verticalAlign={"top"}
                />
              </SidebarTitle>
            ) : (
              <LoadingPlaceholder width={"100%"} height={33.5} />
            )}
            <OverflowContainer fileNameLength={fileInfo?.name.length || 1}>
              <SectionFileProperties fileInfo={fileInfo} />
              <SectionFilePermissions fileInfo={fileInfo} />
            </OverflowContainer>
            <DownloadButtonContainer>
              <Button
                disable={isLoading || isDownloadClicked}
                onClick={onClickDownload}
                buttonStyle={"secondary-a"}
              >
                <FontAwesomeIcon icon={["fas", "download"]} /> Download File
              </Button>
            </DownloadButtonContainer>
          </FileInfoContainer>
        )}
        {!Boolean(isLoading) && error && <ErrorMessage />}
      </FileListSidebarContainer>
      <SidebarOverlay
        hide={!isMobileSidebarShowing}
        onClick={onClickOverlay}
      />
    </>
  );
};
