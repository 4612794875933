/* eslint-disable react-hooks/exhaustive-deps */
import { ContentContainer } from "../../../../pages/manageSpace/ManageSpace.style";
import { ModalDeleteSpace } from "../modalDeleteSpace/ModalDeleteSpace";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  SectionHeader,
  TitleContainer,
  Title,
  Description,
  ActionButtonWrapper,
  MobileButtonWrapper,
} from "./ManageSpaceDelete.style";
import { Button } from "../../..";
import { fetchManyBucket } from "../../../../store/reducers/buckets";
import { useAppDispatch } from "../../../../store/hooks";

interface MatchParams {
  spaceKey: string;
}

interface ManageSpaceDeleteProps extends RouteComponentProps<MatchParams> {
  isDefaultSpace: boolean;
}

export const ManageSpaceDelete = (props: ManageSpaceDeleteProps) => {
  const { isDefaultSpace } = props;
  const { spaceKey } = props.match.params;
  const dispatch = useAppDispatch();

  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState<boolean>(
    false
  );
  const [isReadyToDelete, setIsReadyToDelete] = useState<boolean>(false);

  const onCloseModal = () => {
    setIsDeleteModalShowing(false);
  };

  const loadSpace = async () => {
    setIsReadyToDelete(false);
    const response = await dispatch(fetchManyBucket(spaceKey));

    if (fetchManyBucket.fulfilled.match(response)) {
      const { size } = response.payload;
      setIsReadyToDelete(size === 0);
    }
  };

  useEffect(() => {
    loadSpace();
  }, []);

  return (
    <ContentContainer>
      <SectionHeader>
        <TitleContainer>
          <Title>Delete Space</Title>
          <Description>
            {isDefaultSpace
              ? "This Space is unable to delete because it is your default Space. In addition, the default Space is also used to confirm that you always have at least a Space."
              : isReadyToDelete
              ? "If you are no longer want this Space, you can permanently delete this Space."
              : "In order to delete this Space, you need to delete all buckets inside this Space."}
          </Description>
        </TitleContainer>
        {!isDefaultSpace && (
          <ActionButtonWrapper>
            <Button
              disable={!isReadyToDelete}
              onClick={() => setIsDeleteModalShowing(true)}
              buttonStyle={"danger"}
              data-cy="delete-space-button"
            >
              Delete
            </Button>
          </ActionButtonWrapper>
        )}
      </SectionHeader>
      {!isDefaultSpace && (
        <>
          <MobileButtonWrapper>
            <Button
              disable={!isReadyToDelete}
              onClick={() => setIsDeleteModalShowing(true)}
              buttonStyle={"danger"}
              data-cy="delete-space-button"
            >
              Delete
            </Button>
          </MobileButtonWrapper>
          <ModalDeleteSpace
            spaceKey={spaceKey}
            isModalShowing={isDeleteModalShowing}
            onCloseModal={onCloseModal}
          />
        </>
      )}
    </ContentContainer>
  );
};
