import { ChangeEvent, KeyboardEvent } from "react";
import {
  Container,
  TagContainer,
  TagLabel,
  IconWarpper,
  Tags,
  Label,
  AddingButton,
  AddIconWarpper,
  AddTextWarpper,
  TagInput,
  WarningMessage,
} from "./InputTagLabelNewDesign.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface InputTagLabelProps {
  /**
   * tag list.
   */
  tags?: string[];
  /**
   * adding text.
   */
  addingText: string;
  /**
   * placeholder of this text input.
   */
  placeHolder: string;
  /**
   * hint text or error message.
   */
  label?: string;
  /**
   * display warning if true.
   * @default false
   */
  error?: boolean;
  /**
   * A function that triggers when keydown.
   */
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  /**
   * A function that triggers while typing.
   */
  onChange: (event: ChangeEvent<HTMLInputElement>, indexToEdit: number) => void;
  /**
   * A function that triggers while typing.
   */
  onDeleteTag?: (indexToRemove: any) => void;
  onClick?: () => void;
  validateErrorInput?: (text: string) => boolean;
}

export const InputTagLabelNewDesign = (
  props: InputTagLabelProps
): JSX.Element => {
  const {
    tags = [],
    placeHolder,
    label,
    onChange,
    onDeleteTag,
    onClick,
    addingText,
    validateErrorInput,
  } = props;

  if (!onDeleteTag) {
    return <></>;
  }

  return (
    <div>
      <Container>
        <Tags>
          {tags &&
            tags.map((tag, index) => (
              <TagContainer key={index}>
                <TagInput>
                  <TagLabel
                    value={tag}
                    type="text"
                    placeholder={placeHolder}
                    error={validateErrorInput ? validateErrorInput(tag) : false}
                    onChange={(e) => onChange(e, index)}
                  />
                  <IconWarpper onClick={() => onDeleteTag(index)}>
                    <FontAwesomeIcon icon={["fas", "trash-alt"]} />
                  </IconWarpper>
                </TagInput>
                <WarningMessage>
                  {validateErrorInput
                    ? validateErrorInput(tag) && (
                        <Label error={validateErrorInput(tag)}>
                          {validateErrorInput(tag) && (
                            <span>
                              <FontAwesomeIcon
                                icon={["fas", "exclamation-triangle"]}
                              />{" "}
                            </span>
                          )}
                          {label}
                        </Label>
                      )
                    : ""}
                </WarningMessage>
              </TagContainer>
            ))}
          <AddingButton onClick={onClick}>
            <AddIconWarpper>
              <FontAwesomeIcon icon={["fas", "plus"]} />
            </AddIconWarpper>
            <AddTextWarpper>{addingText}</AddTextWarpper>
          </AddingButton>
        </Tags>
      </Container>
    </div>
  );
};
