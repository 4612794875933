import {
  forwardRef,
  Ref,
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Container,
  SelectButton,
  SelectOverlay,
  OptionsContainer,
  Option,
  SelectedValueLabel,
} from "./Select.style";

export interface OptionProps {
  /**
   * Option's label
   */
  label: string;
  /**
   * Option's value
   */
  value: string | number;
}

export interface SelectRefObject {
  onVisible: () => void;
  scrollIntoSelectedView: () => any;
}

interface SelectProps {
  /**
   * Array of option
   */
  options: OptionProps[];
  /**
   * Default selected value
   * @default first option's value
   */
  defaultValue?: any;
  /**
   * Call when selected value changed
   */
  onSelect?: (selectedValue: any) => void;
  /**
   * Custom border radius
   * @default 3px
   */
  borderRadius?: string;
  isScrolling?: boolean;
}

export const Select = forwardRef(
  (props: SelectProps, ref: Ref<SelectRefObject>) => {
    const {
      options,
      defaultValue = options[0].value,
      onSelect,
      borderRadius,
      isScrolling = false,
    } = props;

    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const optionsContainer = useRef(null);

    const displaySelectedOption = () => {
      let inputLabel = "";

      options.forEach((option) => {
        if (option.value === value) inputLabel = option.label;
      });

      return inputLabel;
    };

    useImperativeHandle(ref, () => ({ onVisible, scrollIntoSelectedView }));
    const onVisible = () => {
      setVisible(false);
    };

    const scrollIntoSelectedView = () => {
      if (optionsContainer.current) {
        const el = optionsContainer.current as HTMLElement;
        const parent = el.parentNode as HTMLElement;
        if (parent) {
          parent.scrollTop = el.offsetTop;
        }
      }
    };

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    return (
      <Container>
        <SelectButton
          onClick={() => {
            setVisible(true);
            if (isScrolling) {
              scrollIntoSelectedView();
            }
          }}
          borderRadius={borderRadius}
        >
          <SelectedValueLabel>{displaySelectedOption()}</SelectedValueLabel>
          <FontAwesomeIcon icon={["fas", "caret-down"]} />
        </SelectButton>
        <SelectOverlay isActive={visible} onClick={() => setVisible(false)} />
        <OptionsContainer isActive={visible} isScrolling={isScrolling}>
          {options.map((option) => (
            <Option
              ref={option.value === value ? optionsContainer : undefined}
              key={option.value}
              onClick={() => {
                onSelect && onSelect(option.value);
                setValue(option.value);
                setVisible(false);
              }}
              isSelected={option.value === value}
            >
              {option.label}
            </Option>
          ))}
        </OptionsContainer>
      </Container>
    );
  }
);
