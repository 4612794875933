/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useState, useEffect, ChangeEvent } from "react";
import { RouteComponentProps, useHistory } from "react-router";

import { Container } from "../../components/GlobalStyle/GlobalStyle.style";
import {
  PageContainer,
  Content,
  SearchBoxWrapper,
  ActionContainer,
  SearchResultContainer,
  IconWrapper,
  ButtonContainer,
} from "./BucketList.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmptyMessage } from "../../components/organisms/messages/EmptyMessage";
import { LoadingMessage } from "../../components/organisms/messages/LoadingMessage";
import { ErrorMessage } from "../../components/organisms/messages/ErrorMessage";
import { NoResultMessage } from "../../components/organisms/messages/NoResultMessage";

import { SearchBox } from "../../components/atoms/searchBox/SearchBox";
import { ModalCreateBucket } from "../../components/organisms/bucketList/modalCreateBucket/ModalCreateBucket";
import { Button } from "../../components";

import { BucketListTable } from "../../components/organisms/bucketList/bucketListTable/BucketListTable";
import { BucketListHeader } from "../../components/organisms/bucketList/bucketListHeader/BucketListHeader";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchManyBucket, setCurrentSpace } from "../../store/reducers/buckets";
import { resetStates } from "../../store/reducers/files";
import { PageNoPermission } from "../../errors/PageNoPermission";
import { getSpaceByKey } from "../../services/apis";
import { PopupUploadProgress } from "../../components/organisms/fileList/popupUploadProgress/PopupUploadProgress";

export interface BucketListData {
  name: string;
  domainName: string;
}

interface MatchParams {
  teamname?: string;
  spaceKey: string;
  bucketName: string;
}

interface BucketListProps extends RouteComponentProps<MatchParams> {}

export const BucketList = (props: BucketListProps) => {
  const spaceKey = props.match.params.spaceKey;
  const bucketName = props.match.params.bucketName;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);
  const { buckets, currentSpace, size } = useAppSelector(
    (state) => state.buckets
  );

  const [filterData, setFilterData] = useState<Array<BucketListData> | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState<string>("");

  // Modal State for creating bucket.
  const [isCreatingBucket, setIsCreatingBucket] = useState<boolean>(false);

  // Searching State
  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    if (event.target.value === "") {
      setFilterData(buckets);
    } else {
      const filterData = _.filter(buckets, (item) => {
        return item.name
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setFilterData(filterData);
    }
  };

  const loadData = async () => {
    dispatch(resetStates());
    setFilterData(null);
    setIsLoading(true);
    setPageError("");

    const response = await dispatch(fetchManyBucket(spaceKey));

    if (fetchManyBucket.fulfilled.match(response)) {
      if (!response.payload.errorCode) {
        Boolean(buckets.length)
          ? setFilterData(buckets)
          : setFilterData(response.payload.data);
        setIsLoading(false);
        setPageError("");
      } else {
        setIsLoading(false);

        setPageError(response.payload.errorCode);
      }
    }
  };

  useEffect(() => {
    const getSpaceInfo = async () => {
      try {
        const response = await getSpaceByKey(spaceKey);
        dispatch(setCurrentSpace(response.data));
      } catch (error) {
        // If error it will not show manage spaces button. So let it disapear.
        console.log(error);
      }
    };

    getSpaceInfo();
  }, []);

  useEffect(() => {
    loadData();

    document.title = `${spaceKey} buckets - ByteArk Storage`;
  }, [size]);

  useEffect(() => {
    if (Boolean(buckets.length)) {
      setFilterData(buckets);
    }
  }, [buckets]);

  const isEmpty = !pageError && buckets && !Boolean(size);
  const isNotFound =
    Boolean(searchValue.length) &&
    !pageError &&
    filterData &&
    !Boolean(filterData.length);

  if (pageError && pageError.toLowerCase().includes("forbidden")) {
    return <PageNoPermission />;
  }

  return (
    <PageContainer>
      <BucketListHeader spaceKey={spaceKey} bucketName={bucketName} />
      <Container>
        <Content>
          <ActionContainer>
            <SearchBoxWrapper>
              <SearchBox
                text={searchValue}
                placeholder="Search by bucket name..."
                onChange={handleSearchChange}
                onDelete={() => {
                  setSearchValue("");
                  setFilterData(buckets);
                }}
              />
            </SearchBoxWrapper>

            <ButtonContainer>
              {currentSpace && user?.id === currentSpace.ownerBytearkAccountId && (
                <Button
                  buttonStyle={"secondary-d"}
                  fullWidth
                  onClick={() =>
                    history.push(
                      `/${user?.team.namespace}/spaces/${spaceKey}/manage`
                    )
                  }
                >
                  <IconWrapper>
                    <FontAwesomeIcon size={"sm"} icon={["fas", "edit"]} />
                  </IconWrapper>
                  Manage Space
                </Button>
              )}
              <Button
                isIconButton
                fullWidth
                onClick={() => setIsCreatingBucket(true)}
                data-cy="new-bucket-button"
              >
                <FontAwesomeIcon icon={["fas", "plus"]} />
                New Bucket
              </Button>
            </ButtonContainer>
          </ActionContainer>
          {filterData && searchValue !== "" && (
            <SearchResultContainer>
              {filterData.length !== 0 ? filterData.length : "No"} results for ‘
              {searchValue}’
            </SearchResultContainer>
          )}
          {Boolean(isLoading) && <LoadingMessage />}
          {!Boolean(isLoading) && !isEmpty && filterData && (
            <BucketListTable filterData={filterData} spaceKey={spaceKey} />
          )}
          {!Boolean(isLoading) && pageError && <ErrorMessage />}
          {!Boolean(isLoading) && !isNotFound && isEmpty && (
            <EmptyMessage
              description={
                "Buckets are containers for everything that you store in ByteArk Storage.\n" +
                "After you create a bucket, you can upload your files there."
              }
              item={"buckets"}
              button={{
                text: (
                  <>
                    <FontAwesomeIcon icon={["fas", "plus"]} /> New Bucket
                  </>
                ),
                onClick: () => setIsCreatingBucket(true),
              }}
            />
          )}
          {!Boolean(isLoading) && isNotFound && (
            <NoResultMessage item={searchValue} />
          )}
        </Content>
      </Container>
      <PopupUploadProgress />
      <ModalCreateBucket
        spaceKey={spaceKey}
        isOpen={isCreatingBucket}
        onClose={() => {
          setIsCreatingBucket(false);
        }}
        onSuccess={loadData}
      />
    </PageContainer>
  );
};
