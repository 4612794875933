import { Route, Switch } from "react-router-dom";
import { Router } from "react-router";
import ProtectedRoute from "./middlewares/ProtectedRoute";

import history from "./utils/history/history";
import {
  CheckRedirect,
  Activate,
  BucketList,
  FileList,
  PageNotFound,
  BucketAccesses,
  BucketHistory,
  BucketSettings,
  ManageSpace,
  SpaceList,
} from "./pages";

const isDevelopment = process.env.NODE_ENV === "development";

const RouterClass = () => {
  if (isDevelopment) {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={CheckRedirect} />
          <Route exact path="/spaces" component={SpaceList} />
          <Route exact path="/bucket" component={BucketList} />
          <Route exact path="/activate" component={Activate} />
          <Route
            exact
            path="/spaces/:spaceKey/manage"
            component={ManageSpace}
          />
          <Route
            path="/spaces/:spaceKey/bucket/:bucketName"
            component={FileList}
          />
          <Route
            exact
            path="/spaces/:spaceKey/bucket/:bucketName/accesses"
            component={BucketAccesses}
          />
          <Route
            exact
            path="/spaces/:spaceKey/bucket/:bucketName/usage-history"
            component={BucketHistory}
          />
          <Route
            exact
            path="/spaces/:spaceKey/bucket/:bucketName/settings"
            component={BucketSettings}
          />
          <Route exact path="/:teamname" component={CheckRedirect} />
          <Route exact path="/:teamname/spaces" component={SpaceList} />
          <Route
            exact
            path="/:teamname/spaces/:spaceKey/bucket"
            component={BucketList}
          />
          <Route
            exact
            path="/:teamname/spaces/:spaceKey/bucket/:bucketName/accesses"
            component={BucketAccesses}
          />
          <Route
            exact
            path="/:teamname/spaces/:spaceKey/bucket/:bucketName/usage-history"
            component={BucketHistory}
          />
          <Route
            exact
            path="/:teamname/spaces/:spaceKey/bucket/:bucketName/settings"
            component={BucketSettings}
          />
          <Route exact path="/:teamname/activate" component={Activate} />
          <Route
            path="/:teamname/spaces/:spaceKey/bucket/:bucketName"
            component={FileList}
          />
          <Route
            exact
            path="/:teamname/spaces/:spaceKey/manage"
            component={ManageSpace}
          />
          {/** Page not found */}
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    );
  }
  return (
    <Router history={history}>
      <Switch>
        <ProtectedRoute exact path="/" component={CheckRedirect} />
        <ProtectedRoute exact path="/:teamname" component={CheckRedirect} />
        <ProtectedRoute exact path="/:teamname/spaces" component={SpaceList} />
        <ProtectedRoute
          exact
          path="/:teamname/spaces/:spaceKey/bucket"
          component={BucketList}
        />
        <ProtectedRoute
          exact
          path="/:teamname/spaces/:spaceKey/bucket/:bucketName/accesses"
          component={BucketAccesses}
        />
        <ProtectedRoute
          exact
          path="/:teamname/spaces/:spaceKey/bucket/:bucketName/usage-history"
          component={BucketHistory}
        />
        <ProtectedRoute
          exact
          path="/:teamname/spaces/:spaceKey/bucket/:bucketName/settings"
          component={BucketSettings}
        />
        <ProtectedRoute exact path="/:teamname/activate" component={Activate} />
        <ProtectedRoute
          path="/:teamname/spaces/:spaceKey/bucket/:bucketName"
          component={FileList}
        />
        <ProtectedRoute
          exact
          path="/:teamname/spaces/:spaceKey/manage"
          component={ManageSpace}
        />
        {/* Route For testing */}
        <ProtectedRoute
          path="/spaces/:spaceKey/bucket/:bucketName"
          component={FileList}
        />
        {/** Page not found */}
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default RouterClass;
