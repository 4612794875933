import { NavBar } from "../../..";
import {
  HeaderWrapper,
  ServiceLabel,
  ServiceLabelContainer,
} from "./ManageSpaceHeader.style";
import { BreadcrumbHeader } from "../../../atoms/breadcrumbHeader/BreadcrumbHeader";
import { HeaderContainer } from "../../../GlobalStyle/GlobalStyle.style";
import { useAppSelector } from "../../../../store/hooks";

interface ManageSpaceHeaderProps {
  spaceKey: string;
  bucketName: string;
}

export const ManageSpaceHeader = (props: ManageSpaceHeaderProps) => {
  const { spaceKey } = props;
  const { user } = useAppSelector((state) => state.user);
  const breadcrumbItems = [
    {
      title: spaceKey,
      redirectUrl: `/${user?.team.namespace}/spaces/${spaceKey}/bucket`,
    },
    {
      title: "Manage Space",
      redirectUrl: `/${user?.team.namespace}/spaces/${spaceKey}/bucket`,
    },
  ];

  return (
    <HeaderContainer>
      <NavBar
        userProfile={user && user.displayImage.sizes.small.url}
        serviceLabel={spaceKey}
      />
      <HeaderWrapper>
        <BreadcrumbHeader
          path={breadcrumbItems}
        ></BreadcrumbHeader>
        <ServiceLabelContainer>
          <ServiceLabel>Manage Space</ServiceLabel>
        </ServiceLabelContainer>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
