import styled from "styled-components";
import { subTitleStyle } from "../../../GlobalStyle/GlobalStyle.style";
import { ButtonWrapper } from "../../../atoms/button/Button.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";
import {
  Container,
  TooltipContainer,
  TooltipText,
} from "../../../atoms/tooltip/Tooltip.style";
import { TableRow } from "../../../atoms/tableBody/TableBody.style";
import { TableHeaderContainer } from "../../../atoms/tableHeader/TableHeader.style";

export const SectionCorsConfigContainer = styled.div`
  background-color: var(--white);
  border-radius: var(--border-radius-default);
  padding: var(--space-base);
  margin-top: var(--space-m);
  
  @media ${maxLayout.sm} {
    display: flex;
    flex-flow: column wrap;
  }
`;

export const SectionHeader = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Title = styled.h2`
  color: var(--grey900);
  font-size: 24px;
  margin-bottom: var(--space-s);

  @media ${maxLayout.sm} {
    font-size: 18px;
  }
`;

export const Description = styled.p`
  ${subTitleStyle};
`;

export const ActionButtonWrapper = styled.div`
  ${ButtonWrapper} {
    width: 156px;
    padding: var(--space-s) 18px;
  }

  @media ${maxLayout.sm} {
    display: none;
  }
`;

export const MobileButtonWrapper = styled.div`
  display: none;
  width: 100%;
  margin-top: var(--space-base);

  ${ButtonWrapper} {
    width: 100%;
  }

  @media ${maxLayout.sm} {
    order: 1;
    display: block;
  }
`;

export const SectionBody = styled.div`
  margin-top: var(--space-base);

  p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .column1 {
    width: 376px;
  }

  ${Container} {
    display: block;
    height: 20px;
  }

  ${TooltipContainer} {
    width: 100%;
  }

  ${TableRow} {
    :nth-child(2n) {
      background-color: var(--grey100);
    }
  }

  @media ${maxLayout.sm} {
    order: 2;

    ${TableHeaderContainer} {
      display: none;
    }

    ${TableRow} {
      flex-flow: column wrap;
      align-items: flex-start;
      :nth-child(2n) {
        background-color: var(--white);
      }
    }

    .table-cell {
      padding: var(--space-s) 0;
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const OriginContainer = styled.div`
  ${TooltipText} {
    font-size: 12px;
    bottom: 35px;
    width: 300px;
    left: 57%;
  }
`;

export const HeaderContainer = styled.div`
  ${TooltipText} {
    left: 34%;
  }
`;

export const UnderlinedText = styled.p`
  text-decoration: underline;
`;

export const MobileTitle = styled.p`
  display: none !important;
  font-weight: 700;

  @media ${maxLayout.sm} {
    display: block !important;
  }
`;

export const BaseTableWrapper = styled.div`
  border-radius: var(--border-radius-default);
  /* overflow: hidden; */
`;
