import {
  Container,
  TooltipContainer,
  TooltipText,
} from "./Tooltip.style";

interface PopoverProps {
  children: JSX.Element;
  /** @default "top" */
  position?: "top" | "bottom";
  text: string | JSX.Element;
  /** @default "center" */
  textAlign?: "left" | "center" | "right";
}

export const Tooltip = (props: PopoverProps) => {
  const {
    children,
    position = "top",
    text,
    textAlign = "center",
  } = props;

  return (
    <Container>
      <TooltipContainer>
        <TooltipText
          className="tooltip"
          textAlign={textAlign}
          position={position}>
          { text }
        </TooltipText>
        { children }
      </TooltipContainer>
    </Container>
  );
};
