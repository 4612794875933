import styled from "styled-components";
import { TitleText } from "../../textWithIcon/TextAndIcon.style";

export const SectionFilePermissionsContainer = styled.div`
  margin-top: var(--space-base);
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-s);
`;

export const Title = styled.p`
  color: var(--grey800);
  font-size: 14px;
  font-weight: 500;
`;

export const ManageLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;
  box-shadow: none;
  color: var(--grey700);
  cursor: pointer;
  
  :hover {
    text-decoration: underline;
  }
  
  svg {
    margin-left: var(--space-s);
    color: var(--grey700);
  }
`;

export const PermissionItem = styled.div`
  margin-bottom: var(--space-s);
  ${TitleText} {
    margin-top: 3px;
    margin-bottom: var(--space-xs);
  }
`;

export const PermissionDescription = styled.p<{ danger?: boolean }>`
  color: ${(props) => props.danger ? "var(--red)" : "var(--grey800)"};
  font-size: 12px;
`;

export const PermissionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-xs);
  background-color: #F0F5F8;
  border-radius: 50px;

  width: 26px;
  height: 26px;
  font-size: 11px;
`;
