import styled from "styled-components";
import { maxLayout } from "../utils/breakpoint/breakpoint";

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  text-align: center;

  background-color: var(--white);
  border-radius: var(--border-radius-default);

  height: 627px;
  margin-top: var(--space-l);
  max-height: 70vh;

  padding-left: var(--space-base);
  padding-right: var(--space-base);
`;

export const ErrorImage = styled.img`
  width: 320px;
  margin-bottom: 40px;
  
  @media ${maxLayout.xs} {
    width: 80%;
  }
`;

export const ErrorTitle = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: var(--navy);
  margin-bottom: var(--space-m);

  @media ${maxLayout.xs} {
    font-size: 22px;
  }
`;

export const ErrorDescription = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: var(--grey800);
  margin-bottom: var(--space-m);

  @media ${maxLayout.xs} {
    font-size: 14px;
  }
`;

export const ErrorAdditionalInfo = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey700);
`;

export const EmailText = styled.span`
  font-weight: 500;
`;
