import styled from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  left: var(--space-l);
  bottom: var(--space-xl);
  z-index: 1000;
  @media ${maxLayout.md} {
    left: var(--space-base);
  bottom: var(--space-l);
  }
`;

export const ToastWrapper = styled.div`
  :not(:first-child) {
    margin-bottom: var(--space-base);
  }
`;
