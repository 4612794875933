/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StorageLogoSrc from "../../../assets/images/logo.svg";
import { Dropdown } from "../../atoms/dropdown/Dropdown";
import { MainTabType, MainTab } from "../../atoms/mainTabs/TabData";
import {
  NavBarContainer,
  StorageLogo,
  StorageLogoContainer,
  ButtonGroup,
  ProfileImage,
  ProfileLabel,
  IconWrapper,
  HiddenSideBar,
  SidebarHeader,
  ProfileDropdownWrapper,
  SidebarServiceLabel,
  SidebarServiceUrl,
  SidebarTabContainer,
  HiddenContainer,
  TabContainer,
  SidebarOverLay,
  SidebarServiceContainer,
  SideTabProfileContainer,
  SideTabProfileWWrapper,
  SidebarProfileTab,
  CloseIconWrapper,
} from "./Header.style";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { logout } from "../../../store/reducers/user";

interface NavBarProps {
  /**
   * Array of tab
   */
  tabs?: MainTab[];
  /**
   * User's profile
   */
  userProfile?: string;
  /**
   * Service label
   */
  serviceLabel?: string;
  /**
   * Service url
   */
  serviceUrl?: string;
  /**
   * Set active tab
   */
  activeTab?: MainTabType;
}

export const NavBar = (props: NavBarProps) => {
  const { activeTab, tabs, serviceLabel, serviceUrl } = props;

  const [visible, setVisible] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const PROFILE_OPTIONS = [
    {
      label: "My Spaces",
      icon: <FontAwesomeIcon size={"sm"} icon={["fad", "clouds"]} />,
      onClick: () => {
        window.location.href = `/${user?.team.namespace}/spaces`;
      },
    },
    {
      label: "Logout",
      icon: <FontAwesomeIcon icon={["fas", "sign-out-alt"]} />,
      onClick: () => {
        window.location.href = `/logout`;
        dispatch(logout());
      },
    },
  ];

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [visible]);

  return (
    <NavBarContainer>
      <SidebarOverLay isActive={visible} onClick={() => setVisible(false)} />
      <HiddenSideBar isActive={visible}>
        <SidebarHeader>
          <StorageLogoContainer
            to={user ? `/${user.team.namespace}/spaces` : "/"}
          >
            <StorageLogo src={StorageLogoSrc} />
            <div>Storage</div>
          </StorageLogoContainer>
          <CloseIconWrapper>
            <FontAwesomeIcon
              icon={["fas", "times"]}
              onClick={() => setVisible(false)}
              size="lg"
            />
          </CloseIconWrapper>
        </SidebarHeader>
        <SidebarServiceContainer isServiceUrl={!Boolean(serviceUrl)}>
          <SidebarServiceLabel>{serviceLabel}</SidebarServiceLabel>
          {serviceUrl && <SidebarServiceUrl>{serviceUrl}</SidebarServiceUrl>}
        </SidebarServiceContainer>
        <SidebarTabContainer>
          {tabs &&
            tabs.map((mainTab) => (
              <Fragment key={`main-${mainTab.label}`}>
                <TabContainer
                  to={mainTab.redirectUrl}
                  type={"main" as any}
                  isActive={activeTab === mainTab.label}
                >
                  {mainTab.label}
                </TabContainer>
              </Fragment>
            ))}
        </SidebarTabContainer>
        <SideTabProfileContainer
          onClick={() => setProfileVisible(!profileVisible)}
        >
          <SideTabProfileWWrapper>
            <ProfileImage>
              <FontAwesomeIcon icon={["fas", "user"]} />
            </ProfileImage>
            <ProfileLabel>{user ? user.email : "user@inox.co.th"}</ProfileLabel>
          </SideTabProfileWWrapper>
          <FontAwesomeIcon
            icon={profileVisible ? ["far", "angle-down"] : ["far", "angle-up"]}
          />
        </SideTabProfileContainer>
        <HiddenContainer isActive={profileVisible}>
          {PROFILE_OPTIONS.map((option) => (
            <SidebarProfileTab
              onClick={() => option.onClick()}
              key={`profile-${option.label}`}
            >
              {option.icon} {option.label}
            </SidebarProfileTab>
          ))}
        </HiddenContainer>
      </HiddenSideBar>
      <IconWrapper>
        <FontAwesomeIcon
          icon={["fas", "bars"]}
          size="lg"
          onClick={() => setVisible(true)}
        />
      </IconWrapper>
      <StorageLogoContainer to={"/"}>
        <StorageLogo src={StorageLogoSrc} />
        <div>Storage</div>
      </StorageLogoContainer>
      <ButtonGroup>
        <ProfileDropdownWrapper>
          <Dropdown options={PROFILE_OPTIONS}>
            <ProfileImage>
              <FontAwesomeIcon icon={["fas", "user"]} />
            </ProfileImage>
            <ProfileLabel>{user ? user.email : "user@inox.co.th"}</ProfileLabel>
            <FontAwesomeIcon icon={["fas", "caret-down"]} />
          </Dropdown>
        </ProfileDropdownWrapper>
      </ButtonGroup>
    </NavBarContainer>
  );
};
