import styled from "styled-components";
import {
  sectionHeaderStyle,
  subTitleStyle,
} from "../../components/GlobalStyle/GlobalStyle.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 584px;
  padding: var(--space-m) var(--space-xxl) var(--space-l);
  margin-top: var(--space-m);
  margin-bottom: var(--space-m);
  background-color: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: var(--border-radius-default);

  @media ${maxLayout.md} {
    min-height: calc(100vh - 176px);
    padding: var(--space-base);
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${maxLayout.md} {
    flex-flow: column wrap;
    width: 100%;
  }
`;

export const HeroContent = styled.div`
  h1 {
    font-size: 36px;
    color: var(--navy);

    @media ${maxLayout.md} {
      font-size: 24px;
    }
  }

  h3 {
    ${sectionHeaderStyle}
    font-weight: 400;
    color: var(--grey800);
    margin-top: var(--space-l);

    @media ${maxLayout.md} {
      margin-top: var(--space-base);
      font-size: 14px;
    }
  }

  p {
    ${subTitleStyle}
    margin-top: var(--space-base);
  }

  @media ${maxLayout.md} {
    order: 2;
  }
`;

export const GradientButton = styled.button`
  cursor: pointer;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  color: var(--white);
  background: var(--ci-gradient-45);
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));
  padding: var(--space-base);
  margin-top: var(--space-xl);
  border: none;
  border-radius: var(--border-radius-large);
  transition: all 1s ease-out; /* Only work on Edge */

  :hover {
    padding: var(--space-s);

    background: var(--ci-gradient-45-darken);
  }

  @media ${maxLayout.lg} {
    font-size: 21px;
  }

  @media ${maxLayout.md} {
    margin-top: var(--space-base);
    padding: 12px;
    font-size: 18px;
  }
`;

export const Image = styled.img`
  width: 500px;
  margin-left: 100px;

  @media ${maxLayout.xl} {
    width: 400px;
  }

  @media ${maxLayout.lg} {
    width: 360px;
  }

  @media ${maxLayout.md} {
    order: 1;
    width: 70%;
    margin-left: auto;
    order: 1;
    margin-right: auto;
    margin-bottom: var(--space-m);
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-l);

  h2 {
    ${sectionHeaderStyle}
    color: var(--navy);
  }

  p {
    ${subTitleStyle}
    margin-top: var(--space-s);
  }

  @media ${maxLayout.md} {
    flex-flow: column wrap;
    justify-content: flex-start;
    margin-bottom: var(--space-m);
  }
`;

export const FeatureContainer = styled.div`
  flex: 1 0 30%;
  :not(:last-child) {
    margin-right: var(--space-l);
  }

  @media ${maxLayout.md} {
    flex: 0 0 100%;
    margin-right: 0;
    margin-top: var(--space-m);
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  color: var(--navy);
  background: linear-gradient(143.7deg, #62bff4 21.35%, #735de5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${maxLayout.xs} {
    font-size: 6em;
  }
`;

export const Status = styled.h1`
  ${sectionHeaderStyle}
  color: var(--grey800);
  margin-top: var(--space-l);
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  width: 318px;
  max-width: 80vw;
  margin-top: var(--space-xxxl);
`;
