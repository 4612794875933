import { Link, MainTabs, NavBar } from "../../..";
import {
  BackButtonContainer,
  HeaderWrapper,
  ServiceLabel,
  ServiceLabelContainer,
} from "./BucketAccessesHeader.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeaderContainer } from "../../../GlobalStyle/GlobalStyle.style";
import { MainTab } from "../../../atoms/mainTabs/TabData";
import { useAppSelector } from "../../../../store/hooks";
interface BucketSettingsHeaderProps {
  spaceKey: string;
  bucketName: string;
}

export const BucketAccessesHeader = (props: BucketSettingsHeaderProps) => {
  const { spaceKey, bucketName } = props;

  const { user } = useAppSelector((state) => state.user);
  const { domainName } = useAppSelector((state) => state.files);

  const tabs: MainTab[] = [
    {
      label: "Files",
      redirectUrl: `/${user?.team.namespace}/spaces/${spaceKey}/bucket/${bucketName}`,
    },
    // {
    //   label: "Accesses",
    //   redirectUrl: `/${user?.team.namespace}/spaces/${spaceKey}/bucket/${bucketName}/access`,
    // },
    {
      label: "Usage History",
      redirectUrl: `/${user?.team.namespace}/spaces/${spaceKey}/bucket/${bucketName}/usage-history`,
    },
    {
      label: "Settings",
      redirectUrl: `/${user?.team.namespace}/spaces/${spaceKey}/bucket/${bucketName}/settings`,
    },
  ];

  return (
    <HeaderContainer>
      <NavBar
        userProfile={user && user.displayImage.sizes.small.url}
        activeTab={"Accesses"}
        tabs={tabs}
        serviceLabel={bucketName}
        serviceUrl={domainName}
      />
      <HeaderWrapper>
        <BackButtonContainer>
          <Link to={"/"}>
            <FontAwesomeIcon color={"#FFF"} icon={["fas", "chevron-left"]} />
          </Link>
        </BackButtonContainer>
        <ServiceLabelContainer>
          <ServiceLabel>{bucketName}</ServiceLabel>
        </ServiceLabelContainer>
        <MainTabs activeTab={"Accesses"} tabs={tabs} />
      </HeaderWrapper>
    </HeaderContainer>
  );
};
