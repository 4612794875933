import {
  PermissionText,
  PermissionDescription,
  PermissionDropdown,
  PermissionSettings,
  PermissionTitle,
  PermissionWarning,
  RowWrapper,
} from "./PermissionSection.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "../../..";
import { OptionProps } from "../../../atoms/select/Select";
import { useEffect, useState } from "react";

interface PermissionSectionProps {
  customDescriptionText?: string;
  onSelect: (value: string) => void;
  isSingular?: boolean;
  value?: string;
}

export const PermissionSection = (props: PermissionSectionProps) => {
  const {
    customDescriptionText,
    onSelect,
    isSingular = false,
    value,
  } = props;
  const [selected, setSelected] = useState("PRIVATE");

  const options: Array<OptionProps> = [
    {
      label: "Only me",
      value: "PRIVATE",
    },
    {
      label: "Anyone with the link",
      value: "PUBLIC",
    },
  ];

  const onDropdownSelect = (value: string) => {
    setSelected(value);
    onSelect(value);
  };

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <PermissionSettings>
      <RowWrapper>
        <PermissionText>
          <PermissionTitle>
            Who can access {isSingular ? "this file" : "these files"}?
          </PermissionTitle>
          <PermissionDescription>
            { Boolean(customDescriptionText) && customDescriptionText }
            { !Boolean(customDescriptionText) && (
              isSingular
                ? "This permission setting will be applied to a selected file."
                : "This permission setting will be applied to all files above."
            ) }
          </PermissionDescription>
        </PermissionText>
        <PermissionDropdown>
          <Select
            defaultValue={selected}
            options={options}
            onSelect={onDropdownSelect} />
        </PermissionDropdown>
      </RowWrapper>
      <PermissionWarning
        hide={selected === "PRIVATE"}>
        <FontAwesomeIcon color={"#EB5757"} icon={["fas", "exclamation-circle"]} />
        <strong>Publicly accessible:</strong>
        { isSingular
          ? "Anyone on the internet with a link will be able to access this files."
          : "Anyone on the internet with a link will be able to access these files."
        }
      </PermissionWarning>
    </PermissionSettings>
  );
};
