import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  loadBucketList,
  getBucket,
  createBucket,
  deleteBucket,
} from "../../services/apis";
import { SpaceProps } from "./spaces";

interface DataProps {
  spaceKey: string;
  bucketName: string;
}

type BucketResponse = {
  name: string;
  domainName: string;
};

type BucketListResponse = {
  data: Array<BucketResponse>;
  size: number;
};

interface UserReducerState {
  buckets: Array<BucketResponse>;
  currentSpace: SpaceProps | null;
  size: number;
  targetBucket?: BucketResponse;
  error: string | null | undefined;
}

const initialState: UserReducerState = {
  buckets: [],
  currentSpace: null,
  size: 0,
  targetBucket: undefined,
  error: null,
};

const fetchManyBucket = createAsyncThunk(
  "buckets/fetchManyBucket",
  async (spaceKey: string) => {
    try {
      const response = await loadBucketList(spaceKey);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return error.response.data;
    }
  }
);

const fetchOneBucket = createAsyncThunk(
  "buckets/fetchOneBucket",
  async (props: DataProps) => {
    try {
      const response = await getBucket(props.spaceKey, props.bucketName);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return error.response.data;
    }
  }
);

const createOneBucket = createAsyncThunk(
  "buckets/createOneBucket",
  async (props: DataProps) => {
    try {
      const response = await createBucket(props.spaceKey, props.bucketName);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return error.response.data;
    }
  }
);

const deleteOneBucket = createAsyncThunk(
  "buckets/deleteOneBucket",
  async (props: DataProps) => {
    try {
      const response = await deleteBucket(props.spaceKey, props.bucketName);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return error.response.data;
    }
  }
);

export const bucketsSlice = createSlice({
  name: "buckets",
  initialState,
  reducers: {
    setCurrentSpace(state, action: PayloadAction<SpaceProps>) {
      state.currentSpace = action.payload;
    },
    resetCurrentSpace(state) {
      state.currentSpace = null;
    },
    resetErrorState(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchManyBucket.fulfilled,
      (state, action: PayloadAction<BucketListResponse | any>) => {
        if (!action.payload.errorCode) {
          state.buckets = action.payload.data;
          state.size = action.payload.size;
        } else {
          state.error = action.payload.errorCode;
        }
      }
    );
    builder.addCase(
      fetchOneBucket.fulfilled,
      (state, action: PayloadAction<BucketResponse | any>) => {
        if (!action.payload.errorCode) {
          state.targetBucket = action.payload;
        } else {
          state.error = action.payload.errorCode;
        }
      }
    );
    builder.addCase(
      createOneBucket.fulfilled,
      (state, action: PayloadAction<BucketResponse | any>) => {
        if (!action.payload.errorCode) {
          state.buckets.push(action.payload);
        } else {
          state.error = action.payload.errorCode;
        }
      }
    );
    builder.addCase(
      deleteOneBucket.fulfilled,
      (state, action: PayloadAction<string | any>) => {
        if (!action.payload.errorCode) {
          state.buckets.filter((bucket) => bucket.name !== action.payload);
        } else {
          state.error = action.payload.errorCode;
        }
      }
    );
  },
});

export const {
  setCurrentSpace,
  resetCurrentSpace,
  resetErrorState,
} = bucketsSlice.actions;

export { fetchManyBucket, fetchOneBucket, createOneBucket, deleteOneBucket };

export default bucketsSlice.reducer;
