import {
  EmailText,
  ErrorAdditionalInfo,
  ErrorContainer,
  ErrorDescription,
  ErrorImage,
  ErrorTitle,
} from "./ErrorStyles.style";
import image from "../assets/images/nopermission.svg";
import { Container } from "../components/GlobalStyle/GlobalStyle.style";
import { PageContainer } from "../pages/fileList/FileList.style";
import { ErrorHeader } from "./ErrorHeader";
import { useAppSelector } from "../store/hooks";
import { useEffect } from "react";

export const PageNoPermission = () => {
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    document.title = "ByteArk Storage";
  }, []);

  return (
    <PageContainer>
      <ErrorHeader />
      <Container>
        <ErrorContainer>
          <ErrorImage
            width={320}
            height={222}
            src={image} />
          <ErrorTitle>
            No Permission
          </ErrorTitle>
          <ErrorDescription>
            You don’t have permission to view this page.<br />
            Please ask the owner to verify the link and/or update permissions.
          </ErrorDescription>
          <ErrorAdditionalInfo>
            You’re signed in as <EmailText>{user ? user.email : "user@inox.co.th"}</EmailText>
          </ErrorAdditionalInfo>
        </ErrorContainer>
      </Container>
    </PageContainer>
  );
};
