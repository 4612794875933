import styled from "styled-components";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";
import { subTitleStyle } from "../../../GlobalStyle/GlobalStyle.style";

export const Description = styled.div`
  ${subTitleStyle}
  width: 396px;
  margin-bottom: var(--space-s);
  color: var(--grey900);

  @media ${maxLayout.sm} {
    max-width: 80vw;
  }
`;

export const InputWrapper = styled.div`
  width: 396px;
  margin-bottom: var(--space-base);

  @media ${maxLayout.sm} {
    max-width: 80vw;
  }
`;

export const SubTitle = styled.div`
  color: var(--grey900);
  margin-bottom: var(--space-xs);
  font-size: 12px;
  font-weight: bold;
`;

export const ItalicDescription = styled.div`
  color: var(--grey900);
  font-style: italic;
  font-size: 14px;
  overflow-wrap: anywhere;
  width: 396px;
  margin-bottom: var(--space-base);

  @media ${maxLayout.sm} {
    max-width: 80vw;
  }
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  @media ${maxLayout.md} {
    width: 100%;
  }
`;
