import _ from "lodash";

import { useEffect, useState } from "react";

import { useAppSelector } from "../../../../store/hooks";
import {
  loadDailyBucketSize,
  dailyBucketUsagesPayload,
  bucketUsagePayload,
  loadDailyDataTransfer,
  downloadBucketHistory,
  getSpaceByKey,
} from "../../../../services/apis";

import {
  formatNumber,
  formatBytes3Precision,
} from "../../../../utils/formatNumber";
import { formatGigabytes } from "../../../../utils/formatGigabytes";

import {
  getToday,
  formatFullDate,
  getLocaleMonth,
} from "../../../../utils/dateTime";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../..";

import {
  SectionBucketUsageHistoryContainer,
  SectionHeader,
  SectionTitle,
  SectionDescription,
  ContentContainer,
  UsageInformation,
  UsageInformationHeader,
  Description,
  Header,
  Detail,
  CardContainer,
  Card,
  CardHeader,
  CardTitle,
  BaseTableWrapper,
  ButtonWrapper,
  DownloadButtonHolder,
  SelectButtonHolder,
  ButtonText,
} from "./SectionBucketUsageHistory.style";

import { SelectMonthYearDropdown } from "../selectMonthYearDropdown/SelectMonthYearDropdown";

import { SideTab, sideTabItemProp } from "../../../atoms/sideTab/SideTab";
import { BaseTable } from "../../../molecules/baseTable/BaseTable";
import { ColumnProps } from "../../../atoms/tableHeader/TableHeader";
import { ColumnChart } from "../../../atoms/columnChart/ColumnChart";
import { LoadingPlaceholder } from "../../../atoms/loadingPlaceholder/LoadingPlaceholder";
import { AxiosError } from "axios";

interface SectionBucketUsageHistoryProps {
  spaceKey: string;
}

export const SectionBucketUsageHistory = (
  props: SectionBucketUsageHistoryProps
) => {
  const { spaceKey } = props;
  const { bucketName } = useAppSelector((state) => state.files);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [bucketData, setBucketData] = useState<Array<dailyBucketUsagesPayload>>(
    []
  );
  const [dataType, setDataType] = useState<string>("");
  const [dateTimeCollection, setDateTimeCollection] = useState<Array<string>>(
    []
  );
  const [totalSizeCollection, setTotalSizeCollection] = useState<Array<number>>(
    []
  );
  const [sumUsage, setSumUsage] = useState<number>(0);
  const [averageUsage, setAverageUsage] = useState<number>(0);

  const [showingDataIndex, setShowingDataIndex] = useState(0);
  const [tableRows, setTableRows] = useState<Array<any>>([]);

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [createdSpaceDate, setCreatedSpaceDate] = useState<string>("");

  const [month, setMonth] = useState<string>(
    getToday()[1] > 9 ? `${getToday()[1]}` : `0${getToday()[1]}`
  );
  const [year, setYear] = useState<string>(`${getToday()[2]}`);

  const isLastDay = (date: string) => {
    const newDate = new Date(date);
    return new Date(newDate.getTime() + 86400000).getDate() === 1;
  };

  const getLastDay = () => {
    if (isLastDay(`${year}-${month}-31`)) {
      return 31;
    } else if (isLastDay(`${year}-${month}-30`)) {
      return 30;
    } else if (isLastDay(`${year}-${month}-29`)) {
      return 29;
    } else if (isLastDay(`${year}-${month}-28`)) {
      return 28;
    }
  };

  const onSelectMonth = (month: any) => {
    setMonth(month);
  };
  const onSelectYear = (year: any) => {
    setYear(year);
  };

  const onChangeMonth = (month: any) => {
    setMonth(month);
  };

  const query = {
    startDate: `${year}-${month}-01`,
    endDate: `${year}-${month}-${getLastDay()}`,
    limit: 31,
  };

  const getDateTimeThatHaveingDataInString = (
    startDay: string,
    endDay: string,
    year: string
  ) => {
    let dateString = `${getLocaleMonth(startDate)} `;
    if (startDay === endDay) {
      dateString += `${startDay}, ${year}`;
    } else {
      dateString += `${startDay} - ${endDay}, ${year}`;
    }
    return dateString;
  };

  const sideItem: Array<sideTabItemProp> = [
    {
      title: "Bucket Size",
      tableHeader: "Bucket Size",
      info: "Amount of data in bytes stored in this bucket.",
    },
    {
      title: "Data Transfer",
      tableHeader: "Data Transfer",
      info: "Amount of data transfer activity within this bucket.",
    },
  ];

  const columns: Array<ColumnProps> = [
    {
      title: "Date",
      size: "lg",
    },
    {
      title: sideItem[showingDataIndex].title,
      size: "md",
    },
    {
      title: `${sideItem[showingDataIndex].tableHeader} (GB)`,
      size: "md",
    },
  ];

  const mapTableRows = (filtered: Array<dailyBucketUsagesPayload>) => {
    const baseArray = filtered ? filtered : bucketData;
    let filteredDate: string[] = [];

    let isFirstDataThatHavingUsage = false;
    const newData: any[] = _.map(baseArray, (item) => {
      if (!item.dataUsageInBytes && !isFirstDataThatHavingUsage) {
        return {
          ...item,
          dataUsageInBytes: 0,
          dataUsageInGB: 0,
        };
      }
      isFirstDataThatHavingUsage = true;
      return item;
    });

    const row = _.map(newData, (item) => {
      if (item.dataUsageInBytes !== null && item.dataUsageInGB !== null) {
        filteredDate = [...filteredDate, item.date];
        return {
          columns: [
            <Detail>{formatFullDate(item.date)}</Detail>,
            <Detail
              alignEnd
              hover
              title={`${formatNumber(item.dataUsageInBytes)} bytes`}
            >
              {formatBytes3Precision(item.dataUsageInBytes)}
            </Detail>,
            <Detail alignEnd>{item.dataUsageInGB.toFixed(3)} GB</Detail>,
          ],
        };
      }
      return undefined;
    });
    const filteredRow = row.filter((item) => {
      return item && item;
    });
    setTableRows(filteredRow);
  };

  const resetState = () => {
    setIsLoading(false);
    setBucketData([]);
    setDataType("");
    setDateTimeCollection([]);
    setTotalSizeCollection([]);
    setSumUsage(0);
    setAverageUsage(0);
    setStartDate("");
    setEndDate("");
  };

  const loadData = async () => {
    resetState();
    try {
      const response =
        showingDataIndex === 0
          ? await loadDailyBucketSize(spaceKey, bucketName, query)
          : await loadDailyDataTransfer(spaceKey, bucketName, query);
      const dataCors: bucketUsagePayload = _.get(response, "data");
      const bucketUsages: dailyBucketUsagesPayload[] = dataCors.bucketUsages;

      const bucketType = _.get(bucketUsages, "[0].type");

      const date: string[] = _.map(bucketUsages, "date");
      const totalFileSize: number[] = _.map(bucketUsages, "dataUsageInBytes");
      const dataThatHavingUsage: any[] = _.filter(bucketUsages, (item) => {
        return (
          item.dataUsageInBytes !== null && item.dataUsageInGB !== null && item
        );
      });

      const sumUsageData: number = _.get(dataCors, "totalUsagesInBytes");
      const averageUsageData: number = _.get(dataCors, "averageUsageInBytes");

      const dateThatHavingData: string[] = _.map(dataThatHavingUsage, "date");

      setBucketData(bucketUsages);
      setDataType(bucketType);
      setDateTimeCollection(date);
      setTotalSizeCollection(totalFileSize);
      setSumUsage(sumUsageData);
      setAverageUsage(averageUsageData);
      setStartDate(dateThatHavingData[0]);
      setEndDate(dateThatHavingData[dateThatHavingData.length - 1]);
      setIsLoading(false);

      mapTableRows(bucketUsages);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.response?.data.errorCode);
    }
  };

  const onDownloadBucketHistory = async () => {
    try {
      const response = await downloadBucketHistory(
        spaceKey,
        bucketName,
        dataType,
        query
      );
      const data = response.data;
      const c = document.createElement("a");
      const fileName = `${year}-${month}-${bucketName}-${sideItem[
        showingDataIndex
      ].title
        .toLowerCase()
        .replace(" ", "-")}.csv`;
      c.download = fileName;

      const blob = new Blob([data], {
        type: "text/csv",
      });
      c.href = window.URL.createObjectURL(blob);
      c.click();
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.response?.data.errorCode);
    }
  };

  const loadSpaceInfo = async () => {
    try {
      const response = await getSpaceByKey(spaceKey);
      const corsData = response.data;
      setCreatedSpaceDate(corsData.createdAt);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (bucketName && spaceKey && bucketName.length) {
      loadData();
      loadSpaceInfo();
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [bucketName, showingDataIndex, month, year]);

  return (
    <SectionBucketUsageHistoryContainer>
      <SectionHeader>
        <SectionTitle>Bucket Usage History</SectionTitle>
        <SectionDescription>
          Explore metrics for usage and data transfer activity within your
          bucket.
        </SectionDescription>
      </SectionHeader>
      <ContentContainer>
        <SideTab
          onClick={(sideTabIndex) => setShowingDataIndex(sideTabIndex)}
          sideTabItem={sideItem}
          activeTab={showingDataIndex}
        />
        <UsageInformation>
          <UsageInformationHeader>
            <div>
              <Header>{sideItem[showingDataIndex].title}</Header>
              <Description>{sideItem[showingDataIndex].info}</Description>
            </div>
            <ButtonWrapper>
              <DownloadButtonHolder>
                <Button
                  isIconButton
                  buttonStyle="secondary-a"
                  onClick={onDownloadBucketHistory}
                >
                  <FontAwesomeIcon size={"sm"} icon={["fas", "download"]} />
                  <ButtonText>Download CSV</ButtonText>
                </Button>
              </DownloadButtonHolder>

              <SelectButtonHolder>
                <SelectMonthYearDropdown
                  buttonStyle="secondary-a"
                  onSelectMonth={onSelectMonth}
                  onSelectYear={onSelectYear}
                  defaultMonthValue={month}
                  defaultYearValue={year}
                  createdDate={createdSpaceDate}
                  onChangeMonth={onChangeMonth}
                />
              </SelectButtonHolder>
            </ButtonWrapper>
          </UsageInformationHeader>
          <CardContainer column={showingDataIndex === 0 ? 2 : 3}>
            <Card>
              <CardHeader>Date</CardHeader>
              <CardTitle>
                {!isLoading ? (
                  startDate && endDate ? (
                    getDateTimeThatHaveingDataInString(
                      startDate.split("-")[2],
                      endDate.split("-")[2],
                      startDate.split("-")[0]
                    )
                  ) : (
                    "——"
                  )
                ) : (
                  <LoadingPlaceholder width={"90%"} height={"25px"} />
                )}
              </CardTitle>
            </Card>
            {showingDataIndex === 1 ? (
              <Card>
                <CardHeader>Total Data Transfer</CardHeader>
                <CardTitle>
                  {!isLoading ? (
                    formatBytes3Precision(sumUsage)
                  ) : (
                    <LoadingPlaceholder width={"90%"} height={"25px"} />
                  )}
                </CardTitle>
              </Card>
            ) : (
              ""
            )}
            <Card>
              <CardHeader>
                {showingDataIndex === 0
                  ? "Total Bucket Size"
                  : "Average Data Transfer"}{" "}
                (in GB)
              </CardHeader>
              <CardTitle>
                {!isLoading ? (
                  showingDataIndex === 0 ? (
                    formatGigabytes(sumUsage)
                  ) : (
                    formatGigabytes(averageUsage)
                  )
                ) : (
                  <LoadingPlaceholder width={"90%"} height={"25px"} />
                )}
              </CardTitle>
            </Card>
          </CardContainer>
          <ColumnChart
            xAxis={dateTimeCollection}
            yAxis={totalSizeCollection}
            dataType={sideItem[showingDataIndex].title}
          />
          <BaseTableWrapper>
            <BaseTable columns={columns} rows={tableRows} />
          </BaseTableWrapper>
        </UsageInformation>
      </ContentContainer>
    </SectionBucketUsageHistoryContainer>
  );
};
