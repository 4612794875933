import { Button } from "../../..";
import { useAppDispatch } from "../../../../store/hooks";
import {
  SectionDeleteBucketContainer,
  ActionButtonWrapper,
  Description,
  SectionHeader,
  Title,
  TitleContainer,
  MobileButtonWrapper,
} from "./SectionDeleteBucket.style";
import { setIsDeleteBucketModalShowing } from "../../../../store/reducers/settings";
import { ModalDeleteBucket } from "../modalDeleteBucket/ModalDeleteBucket";
import { useEffect, useState } from "react";
import { loadObjects } from "../../../../services/apis";

interface SectionDeleteBucketProps {
  spaceKey: string;
  bucketName: string;
}

export const SectionDeleteBucket = (props: SectionDeleteBucketProps) => {
  const { spaceKey, bucketName } = props;
  const dispatch = useAppDispatch();

  const [dataSize, setDataSize] = useState<number>(1);

  useEffect(() => {
    const loadFileList = async () => {
      const response = await loadObjects(spaceKey, bucketName);
      const data = response.data;
      setDataSize(data.size);
    };

    loadFileList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionDeleteBucketContainer>
      <ModalDeleteBucket />
      <SectionHeader>
        <TitleContainer>
          <Title>Delete Bucket</Title>
          <Description>
            {dataSize !== 0
              ? "In order to delete this bucket, you need to delete all files and folders inside this bucket."
              : "If you are no longer want this bucket, you can permanently delete this bucket."}
          </Description>
        </TitleContainer>
        <ActionButtonWrapper>
          <Button
            onClick={() => dispatch(setIsDeleteBucketModalShowing(true))}
            buttonStyle={"danger"}
            disable={dataSize !== 0}
            data-cy="delete-bucket-button"
          >
            Delete
          </Button>
        </ActionButtonWrapper>
      </SectionHeader>
      <MobileButtonWrapper>
        <Button
          onClick={() => dispatch(setIsDeleteBucketModalShowing(true))}
          buttonStyle={"danger"}
          disable={dataSize !== 0}
          data-cy="delete-bucket-button"
        >
          Delete
        </Button>
      </MobileButtonWrapper>
    </SectionDeleteBucketContainer>
  );
};
