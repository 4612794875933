export const validateUrl = (url: string): boolean => {
  if (url === "") {
    return false;
  } else {
    const regex = new RegExp(
      /^(http(s)?:\/\/[0-9a-zA-Z\-.]+(:[0-9]{1,5})?|\*)$/
    );
    return regex.test(url);
  }
};

export const validateKey = (url: string): boolean => {
  // Can be lowercase, numbers, underscore (_) or hyphen (-).
  if (url === "") {
    return false;
  } else {
    const regex = new RegExp(/^[0-9a-z\-_]*$/);
    return regex.test(url);
  }
};

export const validateSpaceName = (spaceName: string): boolean => {
  // Space name must be between 3 and 63 characters long and
  // can consist only of lowercase letters, numbers, underscore (_), and hyphens (-).
  if (spaceName.length < 3 || spaceName.length >= 63) {
    return false;
  } else {
    const regex = new RegExp(/^[0-9a-z\-_]*$/);
    return regex.test(spaceName);
  }
};

export const validateStartAndEndWithChar = (text: string): boolean => {
  const regex = new RegExp(/^[a-zA-z].*[a-zA-Z]$/);
  return regex.test(text);
};

export const validateMetaDataKey = (key: string): boolean => {
  const regex = new RegExp(/^[0-9a-z-]*$/);
  return regex.test(key);
};

export const validateLowercase = (key: string): boolean => {
  const regex = new RegExp(/^[a-z]*$/);
  return regex.test(key);
};
