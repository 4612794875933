import styled from "styled-components";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";
import { TitleText } from "../../../molecules/textWithIcon/TextAndIcon.style";
import { SelectButton } from "../../../atoms/select/Select.style";
import { PermissionWarning } from "../../../molecules/fileList/permissionSection/PermissionSection.style";

export const ModalManagePermissionsContainer = styled.div`
  width: 590px;

  ${PermissionWarning} {
    margin-top: 22px;
  }

  @media ${maxLayout.md} {
    width: 80vw;

    ${PermissionWarning} {
      margin-top: var(--space-base);
    }
  }
`;

export const ActionButtonContainer = styled.div`
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-top: var(--space-m);
  top: -52px;
  margin-bottom: -49px;
  
  @media ${maxLayout.md} {
    width: 100%;
    top: 0;
    margin-top: var(--space-s);
    margin-bottom: 0;
    
    button {
      width: 100%;
    }
  }
`;

export const SectionInvited = styled.div`
  margin-bottom: var(--space-base);
`;

export const Title = styled.p`
  color: var(--grey900);
  font-size: 14px;
  font-weight: 500;
`;

export const Description = styled.p`
  color: var(--grey700);
  font-size: 12px;
`;

export const PermissionItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-s);
  ${TitleText} {
    margin-top: 3px;
    margin-bottom: var(--space-xs);
  }
`;

export const PermissionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-xs);
  background-color: var(--lightBlue);
  border-radius: 50px;
  width: 36px;
  height: 36px;
  font-size: 12px;
`;

export const PermissionDropdown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 38%;
  ${SelectButton} {
    background-color: var(--grey300);
  }

  @media ${maxLayout.sm} {
    flex: 0 0 100%;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-radius: var(--border-radius-default);
  cursor: pointer;
  height: 100%;
  padding-left: var(--space-base);
  padding-right: var(--space-base);
  margin-left: var(--space-base);
  transition: background-color 200ms ease;
  
  :hover {
    background-color: var(--grey300);
  }
  
  svg {
    color: var(--grey700);
  }
`;
