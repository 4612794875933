import styled from "styled-components";
import { TableBodyContainer } from "../../../atoms/tableBody/TableBody.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";
import { PermissionSettings } from "../../../molecules/fileList/permissionSection/PermissionSection.style";

export const UploadModalContainer = styled.div`
  ${PermissionSettings} {
    margin-top: var(--space-l);
  }
`;

export const DropArea = styled.div<{ hasFile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: column wrap;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%239EA4A9' stroke-width='3' stroke-dasharray='4%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-size: contain;
  border-radius: var(--border-radius-large);
  cursor: pointer;

  width: 100%;
  height: ${(props) => (props.hasFile ? "200px" : "350px")};

  .fa-cloud-upload-alt,
  p {
    color: var(--grey700);
    transition: color 200ms ease;
  }

  .fa-cloud-upload-alt {
    margin-bottom: var(--space-base);
  }

  :hover {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='%23F8F9FA' rx='5' ry='5' stroke='%23343A40' stroke-width='3' stroke-dasharray='4%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    .fa-cloud-upload-alt,
    p {
      color: var(--grey800);
    }
  }

  @media ${maxLayout.md} {
    width: 80vw;
    padding-left: var(--space-base);
    padding-right: var(--space-base);
  }

  @media ${maxLayout.sm} {
    height: 160px;

    p {
      font-size: 14px;
    }
  }
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  z-index: 2;

  width: 100%;
  height: 100%;

  .fa-trash-alt {
    color: var(--grey800);
    transition: color 200ms ease;
  }

  :hover {
    .fa-trash-alt {
      color: var(--red);
    }
  }
`;

export const TableContainer = styled.div`
  margin-top: var(--space-base);

  ${TableBodyContainer} {
    max-height: 250px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--grey600);
    }
  }

  // Align table header with table body's scrollbar
  .header-cell.column-xs {
    width: 78px;
    max-width: 78px;
  }

  .column2 {
    text-align: right;
  }
`;

export const UploadButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;
