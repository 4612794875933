import { ReactNode } from "react";

import { ButtonWrapper } from "./Button.style";

export type ButtonStyle =
  | "primary"
  | "secondary-a"
  | "secondary-b"
  | "secondary-c"
  | "secondary-d"
  | "danger"
  | "danger-outline";

interface ButtonProps {
  /**
   * Button label
   */
  children: ReactNode;
  /**
   * Button's type
   * @default primary
   */
  buttonStyle?: ButtonStyle;
  /**
   * Set button to disable state
   */
  disable?: boolean;
  /**
   * Set button to have full width
   */
  fullWidth?: boolean;
  /**
   * Set button to use icon label style
   */
  isIconButton?: boolean;
  /**
   * Called when clicked button
   */
  onClick?: () => void;
  /**
   * HTML5 Button Type
   */
  type?: "submit" | "button" | "reset";
}

export const Button = (props: ButtonProps) => {
  const {
    children,
    disable,
    onClick,
    buttonStyle = "primary",
    type = "button",
    ...ButtonProps
  } = props;

  return (
    <ButtonWrapper
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        !disable && onClick && onClick();
      }}
      buttonStyle={buttonStyle}
      disable={disable}
      type={type}
      {...ButtonProps}
    >
      {children}
    </ButtonWrapper>
  );
};
