import { BaseErrorMessage, ErrorMessageButton } from "../../molecules/baseErrorMessage/BaseErrorMessage";

interface NoResultMessageProps {
  /**
   * The name of the item that is empty
   * @default data
   * */
  item?: string;
  /**
   * Description
   * @default We couldn’t find any buckets matching ${item}.
   * */
  description?: string;
  /**
   * The button under the description text.
   */
  button?: ErrorMessageButton,
}

export const NoResultMessage = (props: NoResultMessageProps) => {
  let { item = "buckets", description, button } = props;

  if (!description) {
    description = `We couldn’t find any ${item} matching your search.`;
  }

  return (
    <BaseErrorMessage
      icon={"search"}
      title={`No result found`}
      description={description}
      button={button}
    />
  );
};
