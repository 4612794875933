import { Button } from "../../atoms/button/Button";
import {
  ContainerBar,
  TitleWarpper,
  ButtonWrapper,
  DiscardButton,
  SaveButton,
} from "./NotificationBar.style";

interface NotificationBarProp {
  children: JSX.Element;
  /**
   * A showing state of the notification.
   */
  isShowing: boolean;
  /**
   * Background color of notification bar.
   */
  backgroundColor: string;
  /**
   * Background color of notification bar.
   */
  borderColor: string;
  /**
   * A function that triggers Discard button.
   */
  onDiscard?: () => void;
  /**
   * A function that triggers Save button.
   */
  onSave?: () => void;
  disable:boolean;
    
}

export const NotificationBar = (props: NotificationBarProp) => {
  const {
    children,
    isShowing,
    backgroundColor,
    borderColor,
    onDiscard,
    onSave,
    disable,
  } = props;

  return (
    <ContainerBar
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      isShowing={isShowing}
    >
      <TitleWarpper>{children}</TitleWarpper>
      <ButtonWrapper>
        <DiscardButton onClick={onDiscard}>Discard</DiscardButton>
        <SaveButton onClick={onSave}>
          <Button fullWidth onClick={onSave} disable={disable}>
            Save
          </Button>
        </SaveButton>
      </ButtonWrapper>
    </ContainerBar>
  );
};
