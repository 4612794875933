import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 127px;
`;

export const Input = styled.input<{ error?: boolean }>`
  color: var(--grey900);
  background-color: var(--white);
  border: 1px solid var(--grey400);
  padding-top: var(--space-s);
  padding-right: 55%;
  padding-bottom: var(--space-s);
  padding-left: var(--space-s);
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  border-radius: var(--border-radius-default);
  transition: border-color 0.2s ease-out;
  height: 36px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: var(--grey500);
  }

  &:focus,
  &:focus-visible {
    border-color: rgba(0, 166, 237, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: var(--grey400);
  }

  ${(props) =>
    props.error &&
    css`
      border-color: var(--red) !important;
      :focus {
        outline: none !important;
        border: 1px solid var(--red);
      }
    `}
`;

export const Unit = styled.p`
  color: var(--grey500);
  font-size: 14px;
  position: absolute;
  right: var(--space-base);
  top: var(--space-s);
`;

export const WarningMessage = styled.div<{ error?: boolean }>``;

export const Label = styled.p<{ error?: boolean }>`
  color: var(--grey700);
  font-size: 12px;
  margin-top: var(--space-s);

  ${(props) =>
    props.error &&
    css`
      color: var(--red);
    `}
`;
