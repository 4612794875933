import { useEffect, useState, ReactNode } from "react";
import { Route, useLocation } from "react-router-dom";

import { configAxiosForWebConsole } from "../axios/config";
import { axiosAuthAPI } from "../axios";
import { RouteComponentProps } from "react-router";
import * as React from "react";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { login } from "../store/reducers/user";

interface ProtectedRouteProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
    | undefined;
  path?: string;
  exact?: boolean;
  render?: () => ReactNode;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { component, path, exact = false, render } = props;
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const teamName = user ? user.team.namespace : location.pathname.split("/")[1];

  const isAuthenticated = async () => {
    configAxiosForWebConsole(teamName);
    const userResponse = await axiosAuthAPI
      .get("me")
      .catch((err) => console.log("Error in getting user data: ", err));

    if (userResponse) {
      const userData = _.get(userResponse, "data");
      await dispatch(login(userData));
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    isAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamName]);

  return loading ? (
    <></>
  ) : teamName && user ? (
    <Route path={path} exact={exact} component={component} render={render} />
  ) : (
    <Route render={() => (window.location.href = `/accounts/byteark/`)} />
  );
};

export default ProtectedRoute;
