import styled from "styled-components";
import {maxLayout} from "../../../utils/breakpoint/breakpoint";

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey900);
  margin-bottom: var(--space-xs);
  width: 100%;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey900);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const InputWraper = styled.div`
  width: 100%;
`;

export const NameContainer = styled.div`
  margin-bottom: var(--space-base);
`;

export const Wrapper = styled.div`
  margin-bottom: var(--space-base);
`;

export const ModalCardContainer = styled.div`
  .modal-card {
    padding: var(--space-base) var(--space-base) var(--space-m);
  }
`;

export const ContentContainer = styled.div`
  @media ${maxLayout.md} {
    width: 80vw;
  }
`;
