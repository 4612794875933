import styled from "styled-components";
import { TableRow } from "../../../atoms/tableBody/TableBody.style";
import { TableHeaderCell } from "../../../atoms/tableHeader/TableHeader.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const SectionBucketUsageHistoryContainer = styled.div`
  background-color: var(--white);
  border-radius: var(--border-radius-default);
  padding-top: var(--space-base);
  margin-top: var(--space-m);
  margin-bottom: var(--space-l);


  @media ${maxLayout.md} {
    margin-top: var(--space-base);
    margin-bottom: var(--space-m);
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--space-m);

  @media ${maxLayout.md} {
    padding: 0 var(--space-base);
  }
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: var(--grey900);
  margin-bottom: var(--space-s);
`;

export const SectionDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--grey800);
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 165px 2fr;
  margin-top: var(--space-base);

  @media ${maxLayout.xl} {
    grid-template-columns: 1fr 4fr;
  }

  @media ${maxLayout.lg} {
    display: block;
  }

  @media ${maxLayout.md} {
    display: block;
  }
`;

export const UsageInformation = styled.div`
  padding: 0 var(--space-m);
  padding-bottom: var(--space-l);

  ${TableHeaderCell} {
    :not(:first-child) {
      text-align: end;
    }
  }

  ${TableRow} {
    :nth-child(2n) {
      background-color: var(--grey100);
    }

    :hover {
      background-color: var(--grey400);
    }
  }

  @media ${maxLayout.md} {
    padding: 0 var(--space-base);
    padding-bottom: var(--space-m);
  }

  @media ${maxLayout.sm} {
    ${TableRow} {
      flex-flow: row;
      align-items: flex-start;
    }
  }
`;

export const UsageInformationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media ${maxLayout.md} {
    flex-direction: column;

    button {
      width: 100%;
      margin-top: var(--space-base);
    }
  }
`;

export const Header = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: var(--grey900);
  margin-bottom: var(--space-xs);
`;
export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey700);
`;

export const Detail = styled.div<{ alignEnd?: boolean; hover?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey900);
  position: relative;
  cursor: ${(props) => props.hover && "default"};

  text-align: ${(props) => (props.alignEnd ? "end" : "start")};
`;

export const CardContainer = styled.div<{ column: number }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.column}, 1fr)`};
  margin: var(--space-m) 0;
  position: relative;

  @media ${maxLayout.md} {
    display: flex;
    flex-direction: column;
    margin: var(--space-base) 0;
  }
`;

export const Card = styled.div`
  display: block;
  width: 100%;

  @media ${maxLayout.md} {
    display: flex;
    flex-direction: column;

    :not(:first-child) {
      margin-top: var(--space-base);
    }
  }
`;

export const CardHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--grey700);
  margin-bottom: var(--space-s);

  @media ${maxLayout.md} {
    margin-bottom: var(--space-xs);
  }
`;

export const CardTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: var(--grey900);
`;

export const BaseTableWrapper = styled.div`
  border-radius: var(--border-radius-default);
  overflow: hidden;

  @media ${maxLayout.md} {
    .column2 {
      display: none;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  @media ${maxLayout.md} {
    width: 100%;
  }
`;

export const DownloadButtonHolder = styled.div`
  position: relative;
  button {
    padding: var(--space-s) var(--space-m);
  }

  @media ${maxLayout.lg} {
    button {
      padding: 12px;
    }

    svg {
      margin-right: 0px;
    }
  }
`;

export const SelectButtonHolder = styled.div`
  position: relative;
  margin-left: var(--space-base);

  button {
    padding: var(--space-s) 10px;
  }

  svg {
    margin-left: var(--space-s);
    margin-top: -2px;
  }

  @media ${maxLayout.md} {
    flex: 1 1;
  }
`;

export const ButtonText = styled.div`
  @media ${maxLayout.lg} {
    display: none;
  }
`;
