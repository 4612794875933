import { Button, Modal } from "../../..";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  clearSelectTarget,
  resetToastText,
  setIsBulkPopoverShowing,
  setIsMultiplePermissionModalShowing,
  setSavePermission,
  setToastText,
} from "../../../../store/reducers/files";
import { PermissionSection } from "../../../molecules/fileList/permissionSection/PermissionSection";
import { useState } from "react";
import {
  ActionButtonContainer,
  ModalManagePermissionsContainer,
} from "./ModalManagePermissions.style";
import {
  replaceAcpMultipleFiles,
  replaceAcpMultipleFilesPayload,
} from "../../../../services/apis";
import { LoadingPlaceholder } from "../../../atoms/loadingPlaceholder/LoadingPlaceholder";
import { AxiosError } from "axios";

export const ModalManagePermissionsMultiple = () => {
  const {
    isMultiplePermissionModalShowing,
    bucketName,
    selectTarget,
  } = useAppSelector((state) => state.files);
  const { currentSpace } = useAppSelector((state) => state.spaces);
  const dispatch = useAppDispatch();

  const [isClickedSave, setIsClickedSave] = useState<boolean>(false);
  const [permission, setPermission] = useState<"PUBLIC" | "PRIVATE">("PRIVATE");

  const onCloseModal = () => {
    setPermission("PRIVATE");
    setIsClickedSave(false);
    dispatch(setIsBulkPopoverShowing(false));
    dispatch(setIsMultiplePermissionModalShowing(false));
  };

  const onPermissionSelect = (value: string) => {
    setPermission(value as "PUBLIC" | "PRIVATE");
  };

  const onSave = async () => {
    if (!selectTarget) {
      return;
    }

    setIsClickedSave(true);

    const permissionPayload: replaceAcpMultipleFilesPayload = {
      // fileList has already been reset when it reaches this line. So, use the local one instead.
      targetPaths: selectTarget,
      grants: [
        {
          granteeId: "EVERYONE",
          permission: permission,
        },
      ],
    };

    try {
      const response = await replaceAcpMultipleFiles(
        currentSpace,
        bucketName,
        permissionPayload
      );
      if (response.status === 204) {
        dispatch(setSavePermission());
        dispatch(
          setToastText({
            title: "Permissions Updated",
            description: "Permissions have been updated.",
          })
        );
        dispatch(clearSelectTarget());
        onCloseModal();
        const timer = setTimeout(() => {
          dispatch(resetToastText());
          clearTimeout(timer);
        }, 5000);
      }
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.response?.data.errorCode);
      dispatch(
        setToastText({
          title: "Update Permissions Failed",
          description: `Permissions couldn’t be updated due to ${e.response?.data.errorCode}. Please try again.`,
          iconColor: "danger",
          icon: "times-circle",
        })
      );
      onCloseModal();
      const timer = setTimeout(() => {
        dispatch(resetToastText());
        clearTimeout(timer);
      }, 5000);
    }
  };

  if (!isMultiplePermissionModalShowing) {
    return <></>;
  }

  return (
    <Modal
      title={`Manage Permissions of Selected Files`}
      isOpen={isMultiplePermissionModalShowing}
      onClose={onCloseModal}
    >
      <ModalManagePermissionsContainer>
        {Boolean(!isMultiplePermissionModalShowing || !permission) ? (
          <LoadingPlaceholder width={"100%"} height={112} />
        ) : (
          <>
            <PermissionSection
              customDescriptionText={
                "This permission setting will be applied to all selected files."
              }
              value={permission}
              onSelect={onPermissionSelect}
            />
            <ActionButtonContainer>
              <Button
                disable={isClickedSave}
                onClick={onSave}
                buttonStyle={"primary"}
              >
                Save
              </Button>
            </ActionButtonContainer>
          </>
        )}
      </ModalManagePermissionsContainer>
    </Modal>
  );
};
