import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSpaces,
  postSpaces,
  deleteSpace,
  createSpacePayload,
} from "../../services/apis";

export interface SpaceProps {
  id: string;
  key: string;
  teamNamespace: string;
  ownerBytearkAccountId: number;
  isDefaultSpace: boolean;
  defaultPermission: "owner-access" | "team-access";
}

export interface ErrorResponse {
  errorCode: string;
}

interface SpacesResponseProps {
  spaces: Array<SpaceProps>;
}

interface SpacesStateProps {
  spaces: Array<SpaceProps>;
  createError: string | null | undefined;
  error: string | null | undefined;
  isSpacePermissionShowing: boolean;
  isSpaceCreationShowing: boolean;
  spacePopoverIndex: number;
  currentSpace: string;
  isSpaceLoading: boolean;
  spaceSearchValue: string;
  filteredSpaces: Array<SpaceProps>;
  selectedSpace: SpaceProps | null;
}

const initialState: SpacesStateProps = {
  spaces: [],
  createError: "",
  error: "",
  isSpacePermissionShowing: false,
  isSpaceCreationShowing: false,
  spacePopoverIndex: -1,
  currentSpace: "",
  isSpaceLoading: true,
  spaceSearchValue: "",
  filteredSpaces: [],
  selectedSpace: null,
};

const fetchSpaces = createAsyncThunk("spaces/fetchSpaces", async () => {
  try {
    const response = await getSpaces();
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return error.response.data;
  }
});

const createSpaces = createAsyncThunk(
  "spaces/createSpaces",
  async (payload: createSpacePayload) => {
    try {
      const response = await postSpaces(payload);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return error.response.data;
    }
  }
);

const deleteOneSpace = createAsyncThunk(
  "spaces/deleteOneSpace",
  async (spaceKey: string) => {
    try {
      await deleteSpace(spaceKey);
      return spaceKey;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return error.response.data;
    }
  }
);

export const spacesSlice = createSlice({
  name: "spaces",
  initialState,
  reducers: {
    setIsSpacePermissionShowing(state, action: PayloadAction<boolean>) {
      state.isSpacePermissionShowing = action.payload;
    },
    setIsSpaceCreationShowing(state, action: PayloadAction<boolean>) {
      state.isSpaceCreationShowing = action.payload;
    },
    setSpacePopoverIndex(state, action: PayloadAction<number>) {
      state.spacePopoverIndex = action.payload;
    },
    setCurrentSpace(state, action: PayloadAction<string>) {
      state.currentSpace = action.payload;
    },
    setIsSpaceLoading(state, action: PayloadAction<boolean>) {
      state.isSpaceLoading = action.payload;
    },
    setSpaceSearchValue(state, action: PayloadAction<string>) {
      state.spaceSearchValue = action.payload;
    },
    setFilteredSpaces(state, action: PayloadAction<Array<SpaceProps>>) {
      state.filteredSpaces = action.payload;
    },
    setSelectedSpace(state, action: PayloadAction<SpaceProps>) {
      state.selectedSpace = action.payload;
    },
    resetSpaceState(state) {
      Object.assign(state, initialState);
    },
    resetErrorState(state) {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSpaces.fulfilled,
      (state, action: PayloadAction<SpacesResponseProps | any>) => {
        if (!action.payload.errorCode) {
          state.spaces = action.payload.spaces;
          state.filteredSpaces = action.payload.spaces;
        } else {
          state.error = action.payload.errorCode;
        }
      }
    );
    builder.addCase(
      createSpaces.fulfilled,
      (state, action: PayloadAction<{ spaces: SpaceProps } | any>) => {
        if (!action.payload.errorCode) {
          state.spaces.push(action.payload.spaces);
          state.filteredSpaces.push(action.payload.spaces);
        } else {
          state.createError = action.payload.errorCode;
        }
      }
    );
    builder.addCase(
      deleteOneSpace.fulfilled,
      (state, action: PayloadAction<string | any>) => {
        if (!action.payload.errorCode) {
          state.spaces = state.spaces.filter(
            (space) => space.key !== action.payload
          );
          state.filteredSpaces = state.filteredSpaces.filter(
            (space) => space.key !== action.payload
          );
        } else {
          state.error = action.payload.errorCode;
        }
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsSpacePermissionShowing,
  setIsSpaceCreationShowing,
  setSpacePopoverIndex,
  setCurrentSpace,
  setIsSpaceLoading,
  setSpaceSearchValue,
  setFilteredSpaces,
  setSelectedSpace,
  resetSpaceState,
  resetErrorState,
} = spacesSlice.actions;
export { fetchSpaces, createSpaces, deleteOneSpace };

export default spacesSlice.reducer;
