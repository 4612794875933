import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { Button, Modal, TextInput } from "../../..";
import {
  Description,
  InputWrapper,
  SubTitle,
  ItalicDescription,
} from "./ModalCreateBucket.style";
import { ButtonContainer } from "../../../atoms/modal/Modal.style";
import { validateBucketName } from "../../../../utils/bucket";
import { getSpacesInfo } from "../../../../services/apis";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { validateStartAndEndWithChar } from "../../../../utils/validate";
import {
  addToast,
  removeToast,
  ToastProps,
} from "../../../../store/reducers/toasts";
import {
  resetLoadingState,
  setLoadingState,
} from "../../../../store/reducers/loading";
import { v4 as uuid } from "uuid";
import {
  createOneBucket,
  resetErrorState,
} from "../../../../store/reducers/buckets";

interface ModalCreateBucketProps {
  spaceKey: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const ModalCreateBucket = (props: ModalCreateBucketProps) => {
  const { spaceKey, isOpen, onClose, onSuccess } = props;

  const dispatch = useAppDispatch();

  const { loadingStatus } = useAppSelector((state) => state.loading);

  const [userDomain, setUserDomain] = useState<string>("");
  const [bucketName, setBucketName] = useState<string>("");
  const [createResultMessage, setCreateResultMessage] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBucketName(event.target.value);
    setCreateResultMessage("");
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && validateBucketName(bucketName)) {
      handleCreate();
    } else if (event.key === "Escape") {
      onClose();
      setBucketName("");
      setCreateResultMessage("");
    }
  };

  const handleCreate = async () => {
    dispatch(setLoadingState());
    dispatch(resetErrorState());
    const response = await dispatch(createOneBucket({ spaceKey, bucketName }));

    const id = uuid();
    let toastData: ToastProps;

    if (createOneBucket.fulfilled.match(response)) {
      if (!response.payload.errorCode) {
        setCreateResultMessage("Success");
        setBucketName("");
        onClose();
        onSuccess();
        toastData = {
          id,
          status: "success",
          title: "Bucket Created",
          description: `Bucket "${bucketName}" has been created.`,
        };
        dispatch(addToast(toastData));
      } else {
        if (response.payload.errorCode === "bucketAlreadyExists") {
          setCreateResultMessage("Bucket with the same name already exists.");
        } else {
          toastData = {
            id,
            status: "error",
            title: "Create Bucket Failed",
            description: `Bucket couldn’t be created due to ${response.payload.errorCode}. Please try again.`,
          };
          onClose();
          setBucketName("");
          dispatch(addToast(toastData));
        }
      }

      let timer = setTimeout(() => {
        dispatch(removeToast(id));
        clearTimeout(timer);
      }, 5000);
    }
    dispatch(resetLoadingState());
  };

  const handleClose = () => {
    setBucketName("");
    setCreateResultMessage("");
    onClose();
  };

  useEffect(() => {
    const getDomain = async () => {
      try {
        const response = await getSpacesInfo(spaceKey);
        const { domain } = response.data;
        setUserDomain(domain);
      } catch (error) {
        // If error it will not show domain name. So let it be.
        console.log("Can't get domain name");
      }
    };

    getDomain();

    return () => {
      setUserDomain("");
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} title="New Bucket" onClose={handleClose}>
        <Description>
          Please name your bucket wisely, you cannot change this later.
        </Description>
        <InputWrapper>
          <TextInput
            text={bucketName}
            error={
              !["Success", ""].includes(createResultMessage) ||
              (!validateBucketName(bucketName) && bucketName !== "") ||
              (!validateStartAndEndWithChar(bucketName) && bucketName !== "")
            }
            placeHolder="bucketname"
            label={
              !["Success", ""].includes(createResultMessage)
                ? createResultMessage
                : !validateBucketName(bucketName) && bucketName !== ""
                ? `Invalid name format. \nBucket name must be between 3 and 63 characters long and can consist only of lowercase letters, numbers, and hyphens (-).`
                : !validateStartAndEndWithChar(bucketName) && bucketName !== ""
                ? "Bucket name must start and end with lowercase letters."
                : "Bucket name must be between 3 and 63 characters long and can consist only of lowercase letters, numbers, and hyphens (-)."
            }
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            testId="new-bucket-input"
          />
        </InputWrapper>
        <>
          {Boolean(userDomain) && (
            <>
              <SubTitle>Storage domain</SubTitle>
              <ItalicDescription>
                {`${
                  validateBucketName(bucketName) ? bucketName : "bucketname"
                }.${userDomain}`}
              </ItalicDescription>
            </>
          )}
        </>
        <ButtonContainer>
          <Button
            type="submit"
            onClick={handleCreate}
            disable={
              !validateBucketName(bucketName) ||
              !validateStartAndEndWithChar(bucketName) ||
              loadingStatus === "loading"
            }
            fullWidth
            data-cy="new-bucket-submit"
          >
            Create
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};
