import styled, { keyframes } from "styled-components";
import { subTitleStyle } from "../../GlobalStyle/GlobalStyle.style";

const wave = keyframes`
  50% {
    transform: scale(0.9);
  }
`;

export const Input = styled.input`
  display: none;

  :checked + .box {
    background-color: var(--primary);
    border-color: var(--primary);
    animation: ${wave} 0.4s ease;

    .icon {
      opacity: 1;
    }
  }

  :disabled + .box {
    background-color: var(--grey500);
    border-color: var(--grey500);
  }
`;

export const Box = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 2px solid var(--grey500);
  transform: scale(1);
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgb(0 16 75 / 5%);
  border-radius: var(--border-radius-default);

  .icon {
    color: var(--grey300);
    opacity: 0;
  }
`;

export const Label = styled.label<{isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  cursor: ${props => props.isDisabled ? "default" : "pointer"};
  user-select: none;
  transition: all 2s ease;
  width: fit-content;
`;

export const Text = styled.p`
  ${subTitleStyle}
  color: var(--grey900);
  margin-left: var(--space-xs);
`;
