import React, { ComponentProps } from "react";
import ReactApexChart from "react-apexcharts";
import { formatBytes } from "../../../utils/formatNumber";

export interface ColumnChartProp {
  xAxis: string[];
  yAxis: number[];
  dataType: string;
}

export const ColumnChart = (props: ColumnChartProp) => {
  const { xAxis, yAxis, dataType } = props;
  const chartValue: ComponentProps<typeof ReactApexChart> = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        }
      },
      legend: {
        showForSingleSeries: true,
        fontSize: "12px",
        itemMargin: {
          horizontal: 5,
          vertical: 16,
        },
        labels: {
          colors: "#6C757D",
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 3,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        tickAmount: 1,
        labels: {
          formatter: function (y: any) {
            return formatBytes(y);
          },
          style: {
            colors: "#9EA4A9",
            fontSize: "10px",
            fontWeight: 400,
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: xAxis,
        labels: {
          format: "MMM dd",
          style: {
            colors: "#9EA4A9",
            fontSize: "10px",
            fontWeight: 400,
          },
        },
      },
    },
    series: [
      {
        name: dataType,
        data: yAxis,
      },
    ],
  };

  return (
    <ReactApexChart
      options={chartValue.options}
      series={chartValue.series}
      type="bar"
      height={350}
    />
  );
};
