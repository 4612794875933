import { createSlice } from "@reduxjs/toolkit";

export interface LoadingReducerState {
  loadingStatus: "idle" | "loading";
}

const initialState: LoadingReducerState = {
  loadingStatus: "idle",
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    resetLoadingState: (state) => {
      state.loadingStatus = "idle";
    },
    setLoadingState: (state) => {
      state.loadingStatus = "loading";
    },
  },
});

export const { resetLoadingState, setLoadingState } = loadingSlice.actions;

export default loadingSlice.reducer;
