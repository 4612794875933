import {
  ManageLink, PermissionDescription, PermissionIcon,
  PermissionItem,
  SectionFilePermissionsContainer,
  Title,
  TitleContainer,
} from "./SectionFilePermissions.style";
import { FileInfoProps } from "../../../organisms/fileList/fileListSidebar/FileListSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextWithIcon } from "../../..";
import theme from "../../../../theme/theme";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setDeleteTarget, showPermissionModal } from "../../../../store/reducers/files";

interface SectionFilePermissionsProps {
  fileInfo: FileInfoProps | null | undefined;
}

export const SectionFilePermissions = (props: SectionFilePermissionsProps) => {
  const { fileInfo } = props;
  const dispatch = useAppDispatch();
  const { currentFilePath } = useAppSelector((state) => state.files);

  if (!fileInfo || !fileInfo.acp) {
    return (<></>);
  }

  const { everyonePermission, grants } = fileInfo.acp;

  const onClickManagePermission = () => {
    dispatch(showPermissionModal());
    dispatch(setDeleteTarget({ type: "file", path:  currentFilePath }));
  };

  return (
    <SectionFilePermissionsContainer>
      <TitleContainer>
        <Title>
          Who has access
        </Title>
        <ManageLink
          onClick={onClickManagePermission}>
          Manage
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </ManageLink>
      </TitleContainer>
      {
        everyonePermission.includes("PUBLIC") ? (
          <PermissionItem>
            <TextWithIcon
              color={theme.colors.red}
              icon={<PermissionIcon><FontAwesomeIcon icon={["fas", "link"]} /></PermissionIcon>}
              text={"Anyone with the link"}
              fontWeight={500}
              verticalAlign={"top"}>
              <PermissionDescription danger={true}>
                This file can be accessed by anyone on the Internet. We recommend that you block a public access to your file.
              </PermissionDescription>
            </TextWithIcon>
          </PermissionItem>
        ) : (
          <PermissionItem>
            <TextWithIcon
              icon={<PermissionIcon><FontAwesomeIcon icon={["fas", "lock"]} /></PermissionIcon>}
              // text={`Only Me ${grants.length && "and Invited People"}`}
              text={"Only Me"}
              fontWeight={500}
              verticalAlign={"top"}>
              <PermissionDescription>
                This file can be accessed by you.
                {/*This file can be accessed by you {grants.length && " and invited people below"}.*/}
              </PermissionDescription>
            </TextWithIcon>
          </PermissionItem>
        )
      }
      {
        false && grants.map((grant, index) => {
          const { grantee, granteeType, permission } = grant;
          return (
            <PermissionItem key={`sidebar-grant-${index}`}>
              <TextWithIcon
                icon={
                  <PermissionIcon>
                    {
                      granteeType === "Individual" ? (
                        <FontAwesomeIcon icon={["fas", "user"]} />
                      ) : (
                        <FontAwesomeIcon icon={["fas", "users"]} />
                      )
                    }
                  </PermissionIcon>
                }
                text={grantee.displayName}
                verticalAlign={"top"}>
                <PermissionDescription>
                  Can { permission === "FULL_CONTROL" ? "read and write" : permission.toLowerCase() }
                </PermissionDescription>
              </TextWithIcon>
            </PermissionItem>
          );
        })
      }
    </SectionFilePermissionsContainer>
  )
};
