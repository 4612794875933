import { BaseErrorMessage, ErrorMessageButton } from "../../molecules/baseErrorMessage/BaseErrorMessage";

interface EmptyMessageProps {
  /**
   * The error title
   * @default 'Unable to load data'
   * */
  title?: string,
  /**
   * The error description
   * @default 'There was an error while loading data. Please try again by reloading the page.'
   * */
  description?: string,
  /**
   * The button under the description text.
   */
  button?: ErrorMessageButton,
}

export const ErrorMessage = (props: EmptyMessageProps) => {
  const {
    title = 'Unable to load data',
    description = 'There was an error while loading data. Please try again by reloading the page.',
    button,
  } = props;

  return (
    <BaseErrorMessage
      icon={'exclamation-triangle'}
      title={title}
      description={description}
      button={button}
    />
  );
};
