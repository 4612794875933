import styled from "styled-components";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";
import {
  pageHeaderStyle,
  subTitleStyle,
} from "../../../GlobalStyle/GlobalStyle.style";

export const HeaderWrapper = styled.div`
  position: relative;
  max-width: 1100px;
  width: 100%;
  @media ${maxLayout.xl} {
    max-width: unset;
  }
`;

export const ServiceLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 1rem 0 1.5rem;
  flex-wrap: wrap;

  @media ${maxLayout.sm} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
`;

export const ServiceLabel = styled.span`
  ${pageHeaderStyle};
  color: var(--white);
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 20px;
  padding-bottom: var(--space-s);
  white-space: nowrap;
  
  @media ${maxLayout.sm} {
    width: 100%;
    padding-bottom: 6px;
  }
`;

export const ServiceUrl = styled.span`
  ${subTitleStyle};
  font-style: italic;
  
  @media ${maxLayout.sm} {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    margin-left: 0;
  }
`;

export const BackButtonContainer = styled.div`
  @media ${maxLayout.xl} {
    display: none;
  }
  position: absolute;
  top: 28px;
  left: -48px;
  cursor: pointer;

  .fa-chevron-left {
    font-size: 1.45em;
  }
`;
