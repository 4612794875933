import qs from "qs";
import { axiosStorageAPI, axiosUploadAPI, axiosAccessKeyAPI } from "../axios";
import { configAxiosForDevelopment } from "../axios/config";

if (process.env && process.env.NODE_ENV === "development") {
  configAxiosForDevelopment();
}

// Bucket
export const loadBucketList = (spaceKey: string) => {
  return axiosStorageAPI.get(`/spaces/${spaceKey}/buckets`);
};

export const getBucket = (spaceKey: string, bucketName: string) => {
  return axiosStorageAPI.get(`/spaces/${spaceKey}/buckets/${bucketName}`);
};

export const createBucket = (spaceKey: string, bucketName: string) => {
  return axiosStorageAPI.put(`/spaces/${spaceKey}/buckets/${bucketName}`);
};

export const deleteBucket = (spaceKey: string, bucketName: string) => {
  return axiosStorageAPI.delete(`/spaces/${spaceKey}/buckets/${bucketName}`);
};

// Storage
interface LoadObjectsQuery {
  /**
   * Path prefix of objects to list
   * @default /
   */
  prefix: string | null;
  /**
   * Path delimeter of objects to list
   * @default /
   */
  delimeter?: string;
  /**
   * Number of object to list. More objects will be omitted, and can be loaded more with continuationToken.
   * @default 50
   */
  limit?: number;
  /**
   * Path prefix of objects to list
   */
  continuationToken?: string | null;
}

export const loadObjects = (
  spaceKey: string,
  bucketName: string,
  filter?: LoadObjectsQuery
) => {
  if (filter) {
    const query = qs.stringify(filter, {
      addQueryPrefix: true,
      skipNulls: true,
    });
    return axiosStorageAPI.get(
      `/spaces/${spaceKey}/buckets/${bucketName}/objects${query}`
    );
  }
  return axiosStorageAPI.get(
    `/spaces/${spaceKey}/buckets/${bucketName}/objects`
  );
};

interface FileDetailsQuery {
  /**
   * Path of the file
   * @default /
   */
  path: string | null;
}

export const loadFileDetails = (
  spaceKey: string,
  bucketName: string,
  filter: FileDetailsQuery
) => {
  const query = qs.stringify(filter, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  return axiosStorageAPI.get(
    `/spaces/${spaceKey}/buckets/${bucketName}/files/details${query}`
  );
};

export const loadFilePermissions = (
  spaceKey: string,
  bucketName: string,
  filter: FileDetailsQuery
) => {
  const query = qs.stringify(filter, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  return axiosStorageAPI.get(
    `/spaces/${spaceKey}/buckets/${bucketName}/files/acp${query}`
  );
};

interface permissionPayload {
  granteeType: string;
  permission: string;
}

interface updateFilePermissionPayload {
  bucketName: string;
  data: permissionPayload;
}

// update file permission
export const updateFilePermission = (
  spaceKey: string,
  payload: updateFilePermissionPayload,
  filter: FileDetailsQuery
) => {
  const { bucketName, data } = payload;
  const query = qs.stringify(filter, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  return axiosStorageAPI.patch(
    `/spaces/${spaceKey}/buckets/${bucketName}/files/acp${query}`,
    data
  );
};

export interface CreateObjectsQuery {
  /**
   * The absolute path of a new object to create
   * @default /
   */
  path: string;
  /**
   * Determines the upload mode of the destination file. full is for files at most 5 MB large.
   * Larger files should be uploaded using partial, only upload the first part of the file.
   * @default "full"
   */
  uploadMode?: "full" | "partial";
}

interface CreateObjectPayload {
  spaceKey: string;
  bucketName: string;
  file?: any;
}

export const createObject = (
  payload: CreateObjectPayload,
  filter: CreateObjectsQuery,
  config?: any
) => {
  const { spaceKey, bucketName, file } = payload;
  const preparedFilter = {
    ...filter,
    uploadMode: filter.uploadMode ? filter.uploadMode : "full",
  };

  const query = qs.stringify(preparedFilter, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosUploadAPI.post(
    `/spaces/${spaceKey}/buckets/${bucketName}/files${query}`,
    file,
    config
  );
};

export interface UploadNextPartFilterProps {
  path: string;
  part: number;
  uploadId: string;
  isFinalPart: 0 | 1;
}

export const uploadNextPart = (
  payload: CreateObjectPayload,
  filter: UploadNextPartFilterProps,
  config?: any
) => {
  const { spaceKey, bucketName, file } = payload;
  const query = qs.stringify(filter, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosUploadAPI.put(
    `/spaces/${spaceKey}/buckets/${bucketName}/files${query}`,
    file,
    config
  );
};

export const createFolder = (
  payload: CreateObjectPayload,
  filter: CreateObjectsQuery
) => {
  const { spaceKey, bucketName } = payload;

  const query = qs.stringify(filter, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosStorageAPI.post(
    `/spaces/${spaceKey}/buckets/${bucketName}/folders${query}`
  );
};

export const deleteFolder = (
  spaceKey: string,
  bucketName: string,
  filter: CreateObjectsQuery
) => {
  const query = qs.stringify(filter, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosStorageAPI.delete(
    `/spaces/${spaceKey}/buckets/${bucketName}/folders${query}`
  );
};

export const deleteObject = (
  spaceKey: string,
  bucketName: string,
  filter?: CreateObjectsQuery
) => {
  if (filter) {
    const query = qs.stringify(filter, {
      addQueryPrefix: true,
      skipNulls: true,
    });
    return axiosStorageAPI.delete(
      `/spaces/${spaceKey}/buckets/${bucketName}/files${query}`
    );
  }
  return axiosStorageAPI.delete(
    `/spaces/${spaceKey}/buckets/${bucketName}/files`
  );
};

export const deleteManyFiles = async (
  spaceKey: string,
  bucketName: string,
  filters: Array<CreateObjectsQuery>
) => {
  const filesPath = filters.map((item) => item.path);
  return axiosStorageAPI.delete(
    `/spaces/${spaceKey}/buckets/${bucketName}/files`,
    {
      data: { targetFilePaths: filesPath },
    }
  );
};

export interface CancelFileUploadFilters {
  path: string;
}

export const cancelFileUpload = async (
  spaceKey: string,
  bucketName: string,
  uploadId: string,
  filters: CancelFileUploadFilters
) => {
  const query = qs.stringify(filters, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosUploadAPI.delete(
    `/spaces/${spaceKey}/buckets/${bucketName}/file-uploads/${uploadId}${query}`
  );
};

export interface DownloadFileFilters {
  path: string;
}

export const downloadFile = async (
  spaceKey: string,
  bucketName: string,
  filters: DownloadFileFilters
) => {
  const query = qs.stringify(filters, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosStorageAPI.get(
    `/spaces/${spaceKey}/buckets/${bucketName}/files${query}`
  );
};

export const getFileMetadata = async (
  spaceKey: string,
  bucketName: string,
  filters: FileDetailsQuery
) => {
  const query = qs.stringify(filters, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosStorageAPI.get(
    `/spaces/${spaceKey}/buckets/${bucketName}/files/user-metadata${query}`
  );
};

export interface MetadataProps {
  key: string;
  value: string;
}

interface UpdateFileMetadataProps {
  spaceKey: string;
  bucketName: string;
  userMetadata: Array<MetadataProps>;
}

export const updateFileMetadata = async (
  payload: UpdateFileMetadataProps,
  filters: FileDetailsQuery
) => {
  const { spaceKey, bucketName, userMetadata } = payload;
  const query = qs.stringify(filters, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return axiosStorageAPI.put(
    `/spaces/${spaceKey}/buckets/${bucketName}/files/user-metadata${query}`,
    { userMetadata }
  );
};

export const loadBucketCorsPolicy = async (
  spaceKey: string,
  bucketName: string
) => {
  return axiosStorageAPI.get(`/spaces/${spaceKey}/buckets/${bucketName}/cors`);
};

// Context
export const checkSpaceActivation = () => {
  return axiosAccessKeyAPI.get("/spaces/activated/default");
};

export const activateSpace = () => {
  return axiosAccessKeyAPI.post("/spaces/default");
};

// CorsPolicy
export const getBucketCorsPolicy = (spaceKey: string, bucketName: string) => {
  return axiosStorageAPI.get(`/spaces/${spaceKey}/buckets/${bucketName}/cors`);
};

export interface allowPayload {
  methods: string[];
  origins: string[];
  headers: string[];
}

export interface rulesetPayload {
  allow: allowPayload;
  exposeHeaders: string[];
  maxAgeInSeconds: number;
}

export interface bucketCorsPayload {
  ruleset: rulesetPayload[];
}

interface replaceBucketCorsPayload {
  spaceKey: string;
  bucketName: string;
  data: bucketCorsPayload;
}

export const replaceBucketCorsPolicy = (payload: replaceBucketCorsPayload) => {
  const { spaceKey, bucketName, data } = payload;
  return axiosStorageAPI.put(
    `/spaces/${spaceKey}/buckets/${bucketName}/cors`,
    data
  );
};

export const deleteBucketCorsPolicy = (
  spaceKey: string,
  bucketName: string
) => {
  return axiosStorageAPI.delete(
    `/spaces/${spaceKey}/buckets/${bucketName}/cors`
  );
};

interface grantObject {
  granteeId: string;
  permission: "PUBLIC" | "PRIVATE";
}

export interface replaceAcpMultipleFilesPayload {
  targetPaths: Array<string>;
  grants: Array<grantObject>;
}

export const replaceAcpMultipleFiles = (
  spaceKey: string,
  bucketName: string,
  payload: replaceAcpMultipleFilesPayload
) => {
  return axiosStorageAPI.put(
    `/spaces/${spaceKey}/buckets/${bucketName}/files/acp`,
    payload
  );
};

// Team Access Keys
export interface AccessKeysData {
  /**
   * The name (id) of this key.
   */
  id: string;
  /**
   * What you can do with this key.
   */
  access: "read" | "write" | "readwrite";
}

export const getTeamsAccessKey = (spaceKey: string) => {
  return axiosAccessKeyAPI.get(`/spaces/${spaceKey}/teams/access-keys`);
};

export const generateTeamsAccessKey = (
  spaceKey: string,
  payload: AccessKeysData
) => {
  return axiosAccessKeyAPI.post(
    `/spaces/${spaceKey}/teams/access-keys`,
    payload
  );
};

export const editTeamsAccessKeyPermission = (
  spaceKey: string,
  payload: AccessKeysData
) => {
  return axiosAccessKeyAPI.put(
    `/spaces/${spaceKey}/teams/access-keys/${payload.id}/permission`,
    {
      access: payload.access,
    }
  );
};

export const deleteTeamsAccessKey = (spaceKey: string, id: string) => {
  return axiosAccessKeyAPI.delete(
    `/spaces/${spaceKey}/teams/access-keys/${id}`
  );
};

export const getTeamsAccessSecret = (spaceKey: string, id: string) => {
  return axiosAccessKeyAPI.get(
    `/spaces/${spaceKey}/teams/access-keys/${id}/access-key`
  );
};

// BucketHistory
interface dailyBucketUsagesQuery {
  startDate: string;
  endDate: string;
  limit?: number;
}

export const loadDailyBucketSize = async (
  spaceKey: string,
  bucketName: string,
  filters: dailyBucketUsagesQuery
) => {
  const query = qs.stringify(filters, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  return axiosStorageAPI.get(
    `/spaces/${spaceKey}/buckets/${bucketName}/usage/size${query}`
  );
};

export const loadDailyDataTransfer = async (
  spaceKey: string,
  bucketName: string,
  filters: dailyBucketUsagesQuery
) => {
  const query = qs.stringify(filters, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  return axiosStorageAPI.get(
    `/spaces/${spaceKey}/buckets/${bucketName}/usage/data-transfer${query}`
  );
};

export const downloadBucketHistory = async (
  spaceKey: string,
  bucketName: string,
  type: string,
  filters: dailyBucketUsagesQuery
) => {
  const query = qs.stringify(filters, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  return axiosStorageAPI.get(
    `/spaces/${spaceKey}/buckets/${bucketName}/usage/${type}/csv${query}`
  );
};

export interface bucketUsagePayload {
  averageUsageInBytes: number;
  totalUsageInBytes: number;
  bucketUsages: dailyBucketUsagesPayload[];
}

export interface dailyBucketUsagesPayload {
  bucketName: string;
  dataUsageInBytes: number;
  dataUsageInGB: number;
  type: string;
  date: string;
}

// Spaces
export const getSpaces = async () => {
  return axiosAccessKeyAPI.get("/spaces");
};

export const getSpaceByKey = async (spaceKey: string) => {
  return axiosAccessKeyAPI.get(`/spaces/${spaceKey}`);
};

export const getSpacesInfo = async (spaceKey: string) => {
  return axiosStorageAPI.get(`/spaces/${spaceKey}`);
};

interface SpacePermissionPayloadProps {
  defaultPermission: string;
}

export const updateSpacePermission = async (
  spaceKey: string,
  payload: SpacePermissionPayloadProps
) => {
  return axiosAccessKeyAPI.put(
    `/spaces/${spaceKey}/default-permission`,
    payload
  );
};
export interface createSpacePayload {
  spaceKey: string;
  defaultPermission: "owner-access" | "team-access";
}

export const postSpaces = async (payload: createSpacePayload) => {
  return axiosAccessKeyAPI.post("/spaces", payload);
};

export const deleteSpace = async (spaceKey: string) => {
  return axiosAccessKeyAPI.delete(`/spaces/${spaceKey}`);
};
