interface FeatureProps {
  feature: string;
  detail: string;
}

interface HeroProps extends FeatureProps {
  subTitle: string;
}

export const heroData: HeroProps = {
  feature: "Storage",
  subTitle: "เพิ่มความเร็วให้ทุกไฟล์ ไปยังทุกที่ด้วยสปีดที่ไวกว่าเดิม",
  detail:
    "พื้นที่จัดเก็บไฟล์สำหรับรองรับการดาวน์โหลดปริมาณมาก ไม่ว่าจะเป็นรูปภาพบนเว็บไซต์ วิดีโอความละเอียดสูง ชุดติดตั้งและชุดปรับปรุงข้อมูลของซอฟต์แวร์คอมพิวเตอร์หรือเกมส์",
};

export const featuresData: Array<FeatureProps> = [
  {
    feature: "Full Control / Fully Secured",
    detail:
      "ส่งต่อข้อมูลของคุณผ่านช่องทางเข้ารหัสที่ปลอดภัยไปยังทุกที่ ด้วยระบบ CDN ของเรา ผู้ใช้บริการสามารถใช้งาน SSL ได้ฟรี 100% ผ่าน Sub-Domain ของ Byteark.com หรือสามารถที่จะ Custom ไปยัง Sub-Domain อื่นๆ ตามที่คุณต้องการได้อีกด้วย",
  },
  {
    feature: "Simple API and Control Panel",
    detail:
      "จัดการข้อมูลในการเชื่อมต่อกับ Origin รองรับการ Pull/Push ข้อมูลระหว่างเซิร์ฟเวอร์ สามารถเชื่อมต่อการทำงานผ่าน API รองรับการทำงานบนโค้ดภาษาโปรแกรมต่างๆ มากมาย",
  },
  {
    feature: "Amazon S3-Compatible",
    detail:
      "คุณสามารถใช้ซอฟต์แวร์หรือไลบรารี่ใดๆ ที่รองรับกับ Amazon S3 เพื่อเชื่อมต่อเข้ากับ ByteArk Cloud Storage ได้ทันที",
  },
];
