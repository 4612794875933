import _ from "lodash";
import { Button, Modal, Select, TextWithIcon } from "../../..";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setCurrentFilePath,
  setIsPermissionModalShowing,
  setPopoverIndex,
  setSavePermission,
} from "../../../../store/reducers/files";
import { useEffect, useState } from "react";
import {
  ActionButtonContainer,
  DeleteButton,
  Description,
  ModalManagePermissionsContainer,
  PermissionDropdown,
  PermissionIcon,
  PermissionItem,
  SectionInvited,
  Title,
} from "./ModalManagePermissions.style";
import {
  loadFilePermissions,
  updateFilePermission,
} from "../../../../services/apis";
import { LoadingPlaceholder } from "../../../atoms/loadingPlaceholder/LoadingPlaceholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionProps } from "../../../atoms/select/Select";
import {
  PermissionDescription,
  PermissionSettings,
  PermissionText,
  PermissionTitle,
  PermissionWarning,
  RowWrapper,
} from "../../../molecules/fileList/permissionSection/PermissionSection.style";
import { v4 as uuid } from "uuid";
import { addToast, removeToast } from "../../../../store/reducers/toasts";

interface GrantsProps {
  grantee: {
    id: string;
    displayName: string;
  };
  granteeType: "Individual" | "Group";
  permission: "READ" | "WRITE" | "FULL_CONTROL";
}

export const ModalManagePermissions = () => {
  const { isPermissionModalShowing, bucketName, deleteTarget } = useAppSelector(
    (state) => state.files
  );
  const { currentSpace } = useAppSelector((state) => state.spaces);
  const dispatch = useAppDispatch();

  const [isClickedSave, setIsClickedSave] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<string>("PRIVATE");
  const [permission, setPermission] = useState<string>("");
  const [grants, setGrants] = useState<Array<GrantsProps>>([]);

  const onCloseModal = () => {
    setPermission("");
    setDefaultValue("PRIVATE");
    setIsClickedSave(false);
    dispatch(setIsPermissionModalShowing(false));
  };

  const onPermissionSelect = (value: string) => {
    setPermission(value);
  };

  const onSave = async () => {
    if (!deleteTarget) {
      return;
    }

    setIsClickedSave(true);
    const { path } = deleteTarget;
    const id = uuid();

    try {
      const response = await updateFilePermission(
        currentSpace,
        {
          bucketName,
          data: {
            granteeType: "everyone",
            permission: permission,
          },
        },
        {
          path,
        }
      );
      if (response.status === 204) {
        dispatch(setSavePermission());
        dispatch(
          addToast({
            id,
            status: "success",
            title: "Permissions Updated",
            description: "Permissions have been updated.",
          })
        );

        dispatch(setCurrentFilePath(""));
        dispatch(setCurrentFilePath(path));

        onCloseModal();
      }
    } catch (error) {
      dispatch(
        addToast({
          id,
          status: "error",
          title: "Update Permissions Failed",
          description: "Permissions couldn’t be updated. Please try again.",
        })
      );
      onCloseModal();
    }
    const timer = setTimeout(() => {
      dispatch(removeToast(id));
      clearTimeout(timer);
    }, 5000);
  };

  const options: Array<OptionProps> = [
    {
      // label: `Only me${grants.length && " and invited people"}`,
      label: "Only me",
      value: "PRIVATE",
    },
    {
      label: "Anyone with the link",
      value: "PUBLIC",
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      setPermission("");
      if (!deleteTarget) {
        return;
      }

      try {
        const response = await loadFilePermissions(currentSpace, bucketName, {
          path: deleteTarget.path,
        });

        const everyonePermission = _.get(response, "data.everyonePermission");
        const grants = _.get(response, "data.acl");
        setGrants(grants);

        setPermission("PRIVATE");
        if (everyonePermission) {
          if (everyonePermission.includes("PUBLIC")) {
            setPermission("PUBLIC");
            setDefaultValue("PUBLIC");
            return;
          }
        }
      } catch (error) {
        const id = uuid();
        dispatch(
          addToast({
            id,
            status: "error",
            title: "Load Permissions Failed",
            description:
              "There was an error while loading permissions. Please try again",
          })
        );

        const timer = setTimeout(() => {
          dispatch(removeToast(id));
          clearTimeout(timer);
        }, 5000);
      }
    };

    if (isPermissionModalShowing) {
      dispatch(setPopoverIndex(-1));
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPermissionModalShowing]);

  return (
    <Modal
      title={"Manage Permissions"}
      isOpen={isPermissionModalShowing}
      onClose={onCloseModal}
    >
      <ModalManagePermissionsContainer>
        {Boolean(!isPermissionModalShowing || !permission) ? (
          <LoadingPlaceholder width={"100%"} height={112} />
        ) : (
          <>
            <PermissionSettings>
              <RowWrapper>
                <PermissionText>
                  <PermissionTitle>Who can access this file</PermissionTitle>
                  <PermissionDescription>
                    This permission setting will be applied to a selected file.
                  </PermissionDescription>
                </PermissionText>
                <PermissionDropdown>
                  {permission.length && (
                    <Select
                      defaultValue={permission}
                      options={options}
                      onSelect={onPermissionSelect}
                    />
                  )}
                </PermissionDropdown>
              </RowWrapper>
              <PermissionWarning hide={permission === "PRIVATE"}>
                <FontAwesomeIcon
                  color={"#EB5757"}
                  icon={["fas", "exclamation-circle"]}
                />
                <strong>Publicly accessible:</strong>
                Anyone on the internet with a link of this file can access.
              </PermissionWarning>
            </PermissionSettings>
            {false && Boolean(grants.length) && (
              <SectionInvited>
                <Title>Invited people and groups</Title>
                <Description>
                  People and groups below will also be able to access this file.
                </Description>
              </SectionInvited>
            )}
            {false &&
              Boolean(grants.length) &&
              grants.map((grant, index) => {
                const { grantee, granteeType, permission } = grant;
                const options: Array<OptionProps> = [
                  {
                    label: "Can read and write",
                    value: "FULL_CONTROL",
                  },
                  {
                    label: "Can read",
                    value: "READ",
                  },
                  {
                    label: "Can write",
                    value: "WRITE",
                  },
                ];

                const onGrantSelect = (value: string) => {
                  const original = _.cloneDeep(grants);
                  original[index].permission = value as
                    | "FULL_CONTROL"
                    | "READ"
                    | "WRITE";
                  setGrants(original);
                };

                return (
                  <PermissionItem key={`modal-permission-item-${index}`}>
                    <TextWithIcon
                      icon={
                        <PermissionIcon>
                          {granteeType === "Individual" ? (
                            <FontAwesomeIcon icon={["fas", "user"]} />
                          ) : (
                            <FontAwesomeIcon icon={["fas", "users"]} />
                          )}
                        </PermissionIcon>
                      }
                      text={grantee.displayName}
                    />
                    <PermissionDropdown>
                      <Select
                        defaultValue={permission}
                        options={options}
                        onSelect={onGrantSelect}
                      />
                      <DeleteButton>
                        <FontAwesomeIcon icon={["fas", "trash-alt"]} />
                      </DeleteButton>
                    </PermissionDropdown>
                  </PermissionItem>
                );
              })}
            <ActionButtonContainer>
              <Button
                disable={isClickedSave || defaultValue === permission}
                onClick={onSave}
                buttonStyle={"primary"}
              >
                Save
              </Button>
            </ActionButtonContainer>
          </>
        )}
      </ModalManagePermissionsContainer>
    </Modal>
  );
};
