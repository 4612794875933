import { TableContainer } from "./BaseTable.style";
import { TableBody, TableRowObject } from "../../atoms/tableBody/TableBody";
import { ColumnProps, TableHeader } from "../../atoms/tableHeader/TableHeader";

interface BaseTableProps {
  /** An array of columns' title */
  columns: Array<ColumnProps>;
  /** An array of of table's rows */
  rows: Array<TableRowObject>;
  isAlwaysShowHeader?: boolean;
}

export const BaseTable = (props: BaseTableProps) => {
  const { columns, rows, isAlwaysShowHeader = false } = props;

  if ((columns.length === 0 || rows.length === 0) && !isAlwaysShowHeader) {
    return <></>;
  }

  return (
    <TableContainer>
      <TableHeader columns={columns} roundedBorder={false} />
      {Boolean(rows.length) && <TableBody columns={columns} rows={rows} />}
    </TableContainer>
  );
};
